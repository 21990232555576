import { Box, Collapse } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { A, B, D } from "@mobily/ts-belt";
import PropTypes from "prop-types";
import * as React from "react";
import { Form, scrollbarStyles } from "../../theme";

const StyledHeader = styled("button")(({ theme }) => ({
  ...theme.typography.body2,
  alignItems: "center",
  background: "none",
  border: "none",
  cursor: "pointer",
  display: "flex",
  fontWeight: 500,
  gap: theme.spacing(1),
  margin: 0,
  marginBottom: theme.spacing(1),
  width: "100%",
  padding: "4px 8px 4px 0",
  borderRadius: 4,
  textAlign: "left",
  "&:hover": {
    background: `rgba(0, 0, 0, 0.04)`,
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  ...scrollbarStyles(theme),
}));

const ROW_HEIGHT = 40;

export default function Categories({
  expandedByDefault,
  matchesSmDown,
  maxHeight = 7.5,
  categories,
  selected,
  setSelected,
}) {
  const [isOpen, setIsOpen] = React.useState(
    matchesSmDown || expandedByDefault,
  );

  const options = Object.values(categories);

  return (
    <Box mt={matchesSmDown ? 2 : 3} mb={1}>
      <StyledHeader
        onClick={() => {
          setIsOpen(B.not);
        }}
      >
        {isOpen ? <ExpandLess /> : <ExpandMore />}
        Categories
      </StyledHeader>

      <Collapse in={isOpen}>
        <StyledBox
          mt={1}
          ml={-2}
          display="flex"
          flexDirection="column"
          minHeight={Math.min(
            options.length * ROW_HEIGHT,
            maxHeight * ROW_HEIGHT,
          )}
          maxHeight={maxHeight}
          overflow="auto"
        >
          {options.map((category) => {
            const { id, name, subcategories } = category;

            return (
              <Box key={id}>
                <Box display="flex" alignItems="center" px={2}>
                  <Form.SmallCheckbox
                    variant="subtitle2"
                    label={name}
                    checked={D.isNotEmpty(selected[id] ?? {})}
                    onChange={() => {
                      if (
                        selected[id] &&
                        Object.keys(selected[id]).length ===
                          subcategories.length
                      ) {
                        return setSelected(D.deleteKey(id));
                      }

                      setSelected(
                        D.merge({
                          [id]: subcategories.reduce((result, c) => {
                            result[c.id] = true;
                            return result;
                          }, {}),
                        }),
                      );
                    }}
                  />
                </Box>

                {category.subcategories.map((subcategory) => {
                  const { id: subcategoryId, name } = subcategory;

                  return (
                    <Box
                      alignItems="center"
                      display="flex"
                      height={ROW_HEIGHT}
                      key={subcategoryId}
                      px={4}
                    >
                      <Form.SmallCheckbox
                        label={name}
                        checked={!!selected[id]?.[subcategoryId]}
                        onChange={() => {
                          if (!selected[id]?.[subcategoryId]) {
                            return setSelected(
                              D.merge({
                                [id]: D.merge(selected[id] ?? {}, {
                                  [subcategoryId]: true,
                                }),
                              }),
                            );
                          }

                          const selectedKeys = Object.keys(selected[id] ?? {});

                          if (
                            selectedKeys.length === 1 &&
                            Number(A.head(selectedKeys)) === subcategoryId
                          ) {
                            return setSelected(D.deleteKey(id));
                          }

                          setSelected(
                            D.merge({
                              [id]: D.deleteKey(
                                selected[id] ?? {},
                                subcategoryId,
                              ),
                            }),
                          );
                        }}
                      />
                    </Box>
                  );
                })}
              </Box>
            );
          })}
        </StyledBox>
      </Collapse>
    </Box>
  );
}

Categories.propTypes = {
  expandedByDefault: PropTypes.bool,
  matchesSmDown: PropTypes.bool.isRequired,
  maxHeight: PropTypes.number,
  categories: PropTypes.object.isRequired,
  selected: PropTypes.object.isRequired,
  setSelected: PropTypes.func.isRequired,
};
