import React, { useState, useRef, useEffect, useCallback } from "react";
import { Button, Box, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import axios from "axios";
import logger from "../../util/logger";

async function postNewsletterEmail(email) {
  try {
    await axios.post(`/api/newsletterEmail`, {
      email_address: email,
    });
  } catch (err) {
    logger.error(err);
    if (err.isAxiosError) {
      return err.response.data;
    }
    return `Unable to subscribe ${email}`;
  }
}

const NewsletterEmailEntry = ({ isHomePage = false }) => {
  const statusTextTimeout = useRef(null);
  const inputRef = useRef(null);

  const [email, setEmail] = useState("");
  const [statusText, setStatusText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(
    async (evt) => {
      evt.preventDefault();
      if (isLoading) return;

      setIsLoading(true);
      clearInterval(statusTextTimeout.current);
      setStatusText("");

      if (email.length < 1) {
        return setStatusText("Please provide an email address");
      }

      inputRef.current.blur();

      const err = await postNewsletterEmail(email);

      if (err) {
        setStatusText(err);
      } else {
        setEmail("");
        setStatusText("Thank you!");
      }
      setIsLoading(false);

      statusTextTimeout.current = setTimeout(() => {
        setStatusText("");
      }, 2000);
    },
    [isLoading, email],
  );

  const handleInput = (evt) => {
    setStatusText("");
    setEmail(evt.target.value);
  };

  useEffect(() => {
    const timeout = statusTextTimeout.current;
    return () => {
      clearTimeout(timeout);
    };
  }, [statusTextTimeout]);

  const className = isHomePage ? "dark" : "";

  return (
    <div>
      <Text variant="h6" className={className}>
        Sign Up For Our Newsletter
      </Text>

      <Text variant="body2" className={className}>
        Be the first in line for Limited Editions, Promotions and New Talent!
      </Text>

      <NewsletterForm onSubmit={handleSubmit}>
        <EmailInput
          className={className}
          id="newsletter-email-address-input"
          value={email}
          type="email"
          required
          placeholder="Email Address"
          onChange={handleInput}
          ref={inputRef}
          isHomePage={isHomePage}
        />

        <EmailSubmitButton
          className={email.length < 1 ? "disabled" : ""}
          disabled={isLoading}
          type="submit"
          isHomePage={isHomePage}
        >
          Submit
        </EmailSubmitButton>
      </NewsletterForm>

      {statusText && (
        <Text variant="caption" className={className}>
          {statusText}
        </Text>
      )}
    </div>
  );
};

NewsletterEmailEntry.propTypes = {
  isHomePage: PropTypes.bool,
};

export default NewsletterEmailEntry;

const Text = styled(Typography)(({ theme }) => ({
  "&.dark": {
    color: "white",
  },
}));

const NewsletterForm = styled("form")(({ theme }) => ({
  marginTop: theme.spacing(2),
  position: "relative",
}));

const EmailInput = styled("input")(({ theme }) => ({
  borderRadius: 24,
  height: 48,
  outline: "none",
  padding: theme.spacing(0, 4),
  width: "100%",
  border: `1px solid ${theme.palette.grey[300]}`,
  ...theme.typography.body1,
  "&:focus-visible": {
    boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
  },
  "&.dark": {
    border: `1px solid ${theme.palette.grey[700]}`,
    background: theme.palette.grey[900],
    color: "white",
    "&::placeholder": {
      color: theme.palette.grey[500],
    },
  },
}));

const EmailSubmitButton = styled("button")(({ theme }) => ({
  position: "absolute",
  right: 4,
  border: "none",
  background: theme.gradient.button,
  color: "white",
  borderRadius: 20,
  height: 40,
  top: 4,
  width: 120,
  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.15)",
  ...theme.typography.button,
  "&:hover": {
    cursor: "pointer",
    background: theme.gradient.buttonHover,
  },
  "&:disabled": {
    background: theme.palette.grey[500],
    color: theme.palette.grey[300],
  },
}));
