import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import PropTypes from "prop-types";
import * as React from "react";
import { useAutoScroll, useDebounce } from "../../../hooks";
import SearchResults from "./SearchResults";

export default function Search({ className, isScrolled }) {
  const searchBoxRef = React.useRef(null);
  const [searchValue, setSearchValue] = React.useState("");
  const [query, setQuery] = React.useState("");
  const { scroll } = useAutoScroll();
  const [isFocused, setIsFocused] = React.useState(false);

  const onChange = (evt) => {
    const searchValue = evt.target.value;

    if (searchValue.trim() === "") {
      return setSearchValue("");
    }

    return setSearchValue(searchValue);
  };

  const clear = React.useCallback(() => {
    setSearchValue("");
    setQuery("");
    setIsFocused(false);
  }, []);

  const onClick = React.useCallback(
    (noScroll = false) => {
      if (!noScroll) {
        scroll();
      }
      requestAnimationFrame(clear);
      setIsFocused(false);
    },
    [scroll, clear],
  );

  useDebounce(() => setQuery(searchValue), 200, [searchValue]);

  React.useEffect(() => {
    if (isScrolled) {
      setIsFocused(false);
      searchBoxRef.current?.blur?.();
    }
  }, [isScrolled]);

  return (
    <div className={clsx("search__container", className)}>
      <div className="search__input-container">
        <input
          className="search__input"
          type="text"
          placeholder="Search talents, characters, actors and more..."
          spellCheck="false"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          name="search"
          value={searchValue}
          onChange={onChange}
          onFocus={() => {
            setIsFocused(true);
          }}
          ref={searchBoxRef}
          onKeyDown={(e) => e.key === "Enter" && e.target.blur()}
        />
        <div className="search__input-button-container">
          {query.length > 0 ? (
            <IconButton
              onClick={() => {
                setSearchValue("");
                setQuery("");
                searchBoxRef.current?.focus?.();
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          ) : (
            <IconButton onClick={() => searchBoxRef.current.focus()}>
              <SearchIcon fontSize="small" />
            </IconButton>
          )}
        </div>
      </div>
      {isFocused || query.length > 0 ? (
        <SearchResults
          isScrolled={isScrolled}
          isFocused={isFocused}
          query={query}
          onClick={onClick}
        />
      ) : null}
    </div>
  );
}

Search.propTypes = {
  className: PropTypes.string,
  isScrolled: PropTypes.bool,
};
