import { Tooltip, Typography } from "@material-ui/core";
import { withStyles, styled } from "@material-ui/core/styles";

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.grey.dark,
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    boxShadow: theme.boxShadow.mediumLight,
    overflowX: "scroll",
  },
  arrow: {
    color: theme.palette.grey.dark,
  },
}))(Tooltip);

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: "white",
  fontSize: "1rem",
  marginBottom: theme.spacing(1),
  marginTop: 0,
  lineHeight: 1,
  fontWeight: theme.typography.semiBold,
  fontFamily: theme.typography.interFontStack,
}));

const StyledContent = styled(Typography)(({ theme }) => ({
  color: "white",
  fontSize: "0.8rem",
  lineHeight: theme.typography.body2.lineHeight,
  fontFamily: theme.typography.interFontStack,
  margin: 0,
}));

StyledTooltip.Title = StyledTitle;
StyledTooltip.Content = StyledContent;

export default StyledTooltip;
