import { Box, Collapse, IconButton } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { Add, ExpandLess, ExpandMore, Remove } from "@material-ui/icons";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useState } from "react";
import {
  doesNodeHaveSelectedChildren,
  getAllDescendantIds,
  isNodeSelected,
} from "./utils";

const StyledButton = styled("button")(({ theme }) => ({
  background: "none",
  padding: theme.spacing(0.5, 1),
  margin: 0,
  textAlign: "left",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: theme.spacing(1),
  border: "none",
  ...theme.typography.body2,

  "&:hover": {
    background: theme.palette.action.hover,
    cursor: "pointer",
  },

  "&.selected-children": {
    background: theme.palette.grey[100],
  },

  "&.selected": {
    background: theme.palette.action.selected,

    "&:hover": {
      background: theme.palette.action.selected,
    },
  },
}));

export default function Tree({
  parent = null,
  nodes,
  onToggle,
  selected,
  level = 0,
}) {
  return (
    <Box display="flex" flexDirection="column" gridGap="8px">
      {nodes.map((node) => (
        <CategoryNode
          parent={parent}
          key={node.categoryId}
          node={node}
          onToggle={onToggle}
          selected={selected}
          level={level}
        />
      ))}
    </Box>
  );
}

Tree.propTypes = {
  parent: PropTypes.shape({
    categoryId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    children: PropTypes.array,
  }),
  nodes: PropTypes.arrayOf(
    PropTypes.shape({
      categoryId: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      children: PropTypes.array,
    }),
  ),
  onToggle: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(PropTypes.number).isRequired,
  level: PropTypes.number,
};

function CategoryNode({ parent, level, node, onToggle, selected }) {
  const isSelected = isNodeSelected({ node, selected });
  const hasChildren = node.children && node.children.length > 0;
  const hasSelectedChildren = doesNodeHaveSelectedChildren({ node, selected });
  const [open, setOpen] = useState(hasSelectedChildren);

  const handleToggle = () => {
    if (!selected) {
      return;
    }

    const allDescendants = getAllDescendantIds(node);
    const newSelected = new Set(selected);
    const idsToToggle = [node.categoryId, ...allDescendants];

    if (isSelected) {
      idsToToggle.forEach((id) => newSelected.delete(id));

      const doesParentHaveSelectedChildren = parent?.children
        .filter((child) => child.categoryId !== node.categoryId)
        .some((child) => isNodeSelected({ node: child, selected }));

      if (parent && !doesParentHaveSelectedChildren) {
        newSelected.delete(parent.categoryId);
      }
    } else {
      idsToToggle.forEach((id) => newSelected.add(id));

      if (parent) {
        newSelected.add(parent.categoryId);
      }

      setOpen(true);
    }

    onToggle([...newSelected]);
  };

  return (
    <Box
      ml={level === 0 ? 0 : level * 2}
      display="flex"
      flexDirection="column"
      gridGap="8px"
    >
      <Box
        alignItems="center"
        display="grid"
        gridGap="8px"
        gridTemplateColumns="2rem 1fr"
        justifyContent="space-between"
      >
        {hasChildren ? (
          <Box
            width="2rem"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <IconButton
              size="small"
              onClick={() => setOpen((prev) => !prev)}
              aria-label="toggle"
            >
              {open ? (
                <ExpandLess fontSize="small" />
              ) : (
                <ExpandMore fontSize="small" />
              )}
            </IconButton>
          </Box>
        ) : (
          <Box width="2rem" />
        )}

        <StyledButton
          onClick={handleToggle}
          aria-label={isSelected ? "remove" : "add"}
          className={clsx(
            isSelected && "selected",
            hasSelectedChildren && "selected-children",
          )}
        >
          <span>{node.name}</span>

          {isSelected ? <Remove fontSize="small" /> : <Add fontSize="small" />}
        </StyledButton>
      </Box>

      {hasChildren && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Tree
            parent={node}
            nodes={node.children}
            onToggle={onToggle}
            selected={selected}
            level={level + 1}
          />
        </Collapse>
      )}
    </Box>
  );
}

CategoryNode.propTypes = {
  isSelected: PropTypes.bool,
  hasSelectedChildren: PropTypes.bool,
  parent: PropTypes.shape({
    categoryId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    children: PropTypes.array,
  }),
  node: PropTypes.shape({
    categoryId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    children: PropTypes.array,
  }),
  nodes: PropTypes.arrayOf(
    PropTypes.shape({
      categoryId: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      children: PropTypes.array,
    }),
  ),
  level: PropTypes.number,
  onToggle: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(PropTypes.number).isRequired,
};
