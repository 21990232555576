import { Box, DialogContent, Grid } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { Influencer, Product } from "../../../../domain";
import { useMatchesXsDown } from "../../../../hooks";
import ProductModalAddons from "./ProductModalAddons";
import ProductModalForm from "./ProductModalForm";
import ProductModalHeader from "./ProductModalHeader";
import ProductModalImage from "./ProductModalImage";

const ProductModalContent = React.memo(function ProductModalContent({
  product,
  influencer,
}) {
  const matchesXs = useMatchesXsDown();

  return (
    <StyledDialogContent>
      <Box py={matchesXs ? 1 : 2} px={matchesXs ? 0 : 2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm="auto">
            <ProductModalImage product={product} />
          </Grid>

          <Grid item xs>
            <ProductModalHeader product={product} influencer={influencer} />

            <ProductModalForm product={product} />
          </Grid>

          <Grid item container>
            <ProductModalAddons productId={product.productId} />
          </Grid>
        </Grid>
      </Box>
    </StyledDialogContent>
  );
});

ProductModalContent.propTypes = {
  product: PropTypes.instanceOf(Product),
  influencer: PropTypes.instanceOf(Influencer),
};

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  [theme.breakpoints.down("xs")]: {
    padding: theme.spacing(2),
  },
}));

export default ProductModalContent;
