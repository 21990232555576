import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import ContentPage from "../ContentPage";

export default function Privacy() {
  return (
    <ContentPage title="Privacy Policy">
      <Typography variant="caption" color="textSecondary">
        Last updated July 07, 2021
      </Typography>

      <p>
        Thank you for choosing to be part of our community at SmashNet, doing
        business as Streamily{" "}
        <strong>
          (&ldquo;Streamily&rdquo;, &ldquo;we&rdquo;, &ldquo;us&rdquo;,
          &ldquo;our&rdquo;)
        </strong>
        . We are committed to protecting your personal information and your
        right to privacy. If you have any questions or concerns about this
        privacy notice, or our practices with regards to your personal
        information, please contact us at{" "}
        <Link href="mailto:support@streamily.com">support@streamily.com</Link>.
      </p>

      <p>
        When you visit our website{" "}
        <Link href="http://www.streamily.com">http://www.streamily.com</Link>{" "}
        (the <strong>&ldquo;Website&rdquo;</strong>), and more generally, use
        any of our services (the <strong>&ldquo;Services&rdquo;</strong>, which
        include the Website), we appreciate that you are trusting us with your
        personal information. We take your privacy very seriously. In this
        privacy notice, we seek to explain to you in the clearest way possible
        what information we collect, how we use it and what rights you have in
        relation to it. We hope you take some time to read through it carefully,
        as it is important. If there are any terms in this privacy notice that
        you do not agree with, please discontinue use of our Services
        immediately.
      </p>

      <p>
        This privacy notice applies to all information collected through our
        Services (which, as described above, includes our Website), as well as,
        any related services, sales, marketing or events.{" "}
        <strong>
          Please read this privacy notice carefully as it will help you
          understand what we do with the information that we collect.
        </strong>
      </p>

      <h2>Table of Contents</h2>

      <ol>
        <li>
          <Link href="#section1">What Information Do We Collect?</Link>
        </li>
        <li>
          <Link href="#section2">How Do We Use Your Information?</Link>
        </li>
        <li>
          <Link href="#section3">
            Will Your Information Be Shared With Anyone?
          </Link>
        </li>
        <li>
          <Link href="#section4">
            Who Will Your Information Be Shared With?
          </Link>
        </li>
        <li>
          <Link href="#section5">
            Do We Use Cookies And Other Tracking Technologies?
          </Link>
        </li>
        <li>
          <Link href="#section6">
            What Is Our Stance On Third-Party Websites?
          </Link>
        </li>
        <li>
          <Link href="#section7">How Long Do We Keep Your Information?</Link>
        </li>
        <li>
          <Link href="#section8">How Do We Keep Your Information Safe?</Link>
        </li>
        <li>
          <Link href="#section9">Do We Collect Information From Minors?</Link>
        </li>
        <li>
          <Link href="#section10">What Are Your Privacy Rights?</Link>
        </li>
        <li>
          <Link href="#section11">Controls For Do-Not-Track Features</Link>
        </li>
        <li>
          <Link href="#section12">
            Do California Residents Have Specific Privacy Rights?
          </Link>
        </li>
        <li>
          <Link href="#section13">Do We Make Updates To This Notice?</Link>
        </li>
        <li>
          <Link href="#section14">
            How Can You Contact Us About This Notice?
          </Link>
        </li>
        <li>
          <Link href="#section15">
            How Can You Review, Update Or Delete The Data We Collect From You?
          </Link>
        </li>
      </ol>

      <h2 id="section1">1. What Information Do We Collect?</h2>
      <h3>Personal information you disclose to us</h3>
      <p>
        <em>
          <strong>In Short:</strong> We collect personal information that you
          provide to us.
        </em>
      </p>
      <p>
        We collect personal information that you voluntarily provide to us when
        you register on the Website, express an interest in obtaining
        information about us or our products and Services, when you participate
        in activities on the Website or otherwise when you contact us.
      </p>
      <p>
        The personal information that we collect depends on the context of your
        interactions with us and the Website, the choices you make and the
        products and features you use. The personal information we collect may
        include the following:
      </p>

      <ul>
        <li>
          <strong>Personal Information Provided by You.</strong> We collect
          names; phone numbers; email addresses; mailing addresses; usernames;
          passwords; billing addresses; debit/credit card numbers; contact
          preferences; and other similar information.
        </li>
        <li>
          <strong>Payment Data.</strong> We may collect data necessary to
          process your payment if you make purchases, such as your payment
          instrument number (such as a credit card number), and the security
          code associated with your payment instrument. All payment data is
          stored by Stripe. You may find their privacy notice link(s) here:
          <Link href="https://stripe.com/privacy">
            https://stripe.com/privacy
          </Link>
          .
        </li>
      </ul>

      <p>
        All personal information that you provide to us must be true, complete
        and accurate, and you must notify us of any changes to such personal
        information.
      </p>

      <h3>Information automatically collected</h3>

      <p>
        <em>
          <strong>In Short:</strong> Some information — such as your Internet
          Protocol (IP) address and/or browser and device characteristics — is
          collected automatically when you visit our Website.
        </em>
      </p>

      <p>
        We automatically collect certain information when you visit, use or
        navigate the Website. This information does not reveal your specific
        identity (like your name or contact information) but may include device
        and usage information, such as your IP address, browser and device
        characteristics, operating system, language preferences, referring URLs,
        device name, country, location, information about how and when you use
        our Website and other technical information. This information is
        primarily needed to maintain the security and operation of our Website,
        and for our internal analytics and reporting purposes.
      </p>

      <p>
        Like many businesses, we also collect information through cookies and
        similar technologies.
      </p>

      <p>The information we collect includes:</p>
      <ul>
        <li>
          <strong>Log and Usage Data.</strong> Log and usage data is
          service-related, diagnostic, usage and performance information our
          servers automatically collect when you access or use our Website and
          which we record in log files. Depending on how you interact with us,
          this log data may include your IP address, device information, browser
          type and settings and information about your activity in the Website
          (such as the date/time stamps associated with your usage, pages and
          files viewed, searches and other actions you take such as which
          features you use), device event information (such as system activity,
          error reports (sometimes called &ldquo;crash dumps&rdquo;) and
          hardware settings).
        </li>
        <li>
          <strong>Device Data.</strong> We collect device data such as
          information about your computer, phone, tablet or other device you use
          to access the Website. Depending on the device used, this device data
          may include information such as your IP address (or proxy server),
          device and application identification numbers, location, browser type,
          hardware model Internet service provider and/or mobile carrier,
          operating system and system configuration information.
        </li>
        <li>
          <strong>Location Data.</strong> We collect location data such as
          information about your device&apos;s location, which can be either
          precise or imprecise. How much information we collect depends on the
          type and settings of the device you use to access the Website. For
          example, we may use GPS and other technologies to collect geolocation
          data that tells us your current location (based on your IP address).
          You can opt out of allowing us to collect this information either by
          refusing access to the information or by disabling your Location
          setting on your device. Note however, if you choose to opt out, you
          may not be able to use certain aspects of the Services.
        </li>
      </ul>

      <h3>Information collected from other sources</h3>
      <p>
        <em>
          <strong>In Short:</strong> We may collect limited data from public
          databases, marketing partners, and other outside sources.
        </em>
      </p>
      <p>
        In order to enhance our ability to provide relevant marketing, offers
        and services to you and update our records, we may obtain information
        about you from other sources, such as public databases, joint marketing
        partners, affiliate programs, data providers, as well as from other
        third parties. This information includes mailing addresses, job titles,
        email addresses, phone numbers, intent data (or user behavior data),
        Internet Protocol (IP) addresses, social media profiles, social media
        URLs and custom profiles, for purposes of targeted advertising and event
        promotion.
      </p>

      <h2 id="section2">2. How Do We Use Your Information?</h2>
      <p>
        <em>
          <strong>In Short:</strong> We process your information for purposes
          based on legitimate business interests, the fulfillment of our
          contract with you, compliance with our legal obligations, and/or your
          consent.
        </em>
      </p>
      <p>
        We use personal information collected via our Website for a variety of
        business purposes described below. We process your personal information
        for these purposes in reliance on our legitimate business interests, in
        order to enter into or perform a contract with you, with your consent,
        and/or for compliance with our legal obligations. We indicate the
        specific processing grounds we rely on next to each purpose listed
        below.
      </p>

      <p>We use the information we collect or receive:</p>

      <ul>
        <li>
          <strong>To facilitate account creation and logon process.</strong> If
          you choose to link your account with us to a third-party account (such
          as your Google or Facebook account), we use the information you
          allowed us to collect from those third parties to facilitate account
          creation and logon process for the performance of the contract.
        </li>

        <li>
          <strong>To post testimonials.</strong> We post testimonials on our
          Website that may contain personal information. Prior to posting a
          testimonial, we will obtain your consent to use your name and the
          content of the testimonial. If you wish to update, or delete your
          testimonial, please contact us at{" "}
          <Link href="mailto:support@streamily.com">support@streamily.com</Link>{" "}
          and be sure to include your name, testimonial location, and contact
          information.
        </li>

        <li>
          <strong>Request feedback.</strong> We may use your information to
          request feedback and to contact you about your use of our Website.
        </li>

        <li>
          <strong>To enable user-to-user communications.</strong> We may use
          your information in order to enable user-to-user communications with
          each user&apos;s consent.
        </li>

        <li>
          <strong>To manage user accounts.</strong> We may use your information
          for the purposes of managing our account and keeping it in working
          order.
        </li>

        <li>
          <strong>To send administrative information to you.</strong> We may use
          your personal information to send you product, service and new feature
          information and/or information about changes to our terms, conditions,
          and policies.
        </li>

        <li>
          <strong>To protect our Services.</strong> We may use your information
          as part of our efforts to keep our Website safe and secure (for
          example, for fraud monitoring and prevention).
        </li>

        <li>
          <strong>
            To enforce our terms, conditions and policies for business purposes,
            to comply with legal and regulatory requirements or in connection
            with our contract.
          </strong>
        </li>

        <li>
          <strong>To respond to legal requests and prevent harm.</strong> If we
          receive a subpoena or other legal request, we may need to inspect the
          data we hold to determine how to respond.
        </li>

        <li>
          <strong>Fulfill and manage your orders.</strong> We may use your
          information to fulfill and manage your orders, payments, returns, and
          exchanges made through the Website.
        </li>

        <li>
          <strong>Administer prize draws and competitions.</strong> We may use
          your information to administer prize draws and competitions when you
          elect to participate in our competitions.
        </li>

        <li>
          <strong>
            To deliver and facilitate delivery of services to the user.
          </strong>{" "}
          We may use your information to provide you with the requested service.
        </li>

        <li>
          <strong>To respond to user inquiries/offer support to users.</strong>{" "}
          We may use your information to respond to your inquiries and solve any
          potential issues you might have with the use of our Services.
        </li>

        <li>
          <strong>To send you marketing and promotional communications.</strong>{" "}
          We and/or our third-party marketing partners may use the personal
          information you send to us for our marketing purposes, if this is in
          accordance with your marketing preferences. For example, when
          expressing an interest in obtaining information about us or our
          Website, subscribing to marketing or otherwise contacting us, we will
          collect personal information from you. You can opt-out of our
          marketing emails at any time (see the{" "}
          <Link href="#section10">
            &ldquo;What Are Your Privacy Rights?&rdquo;
          </Link>
          below).
        </li>

        <li>
          <strong>Deliver targeted advertising to you.</strong> We may use your
          information to develop and display personalized content and
          advertising (and work with third parties who do so) tailored to your
          interests and/or location and to measure its effectiveness.
        </li>

        <li>
          <strong>For other business purposes.</strong> We may use your
          information for other business purposes, such as data analysis,
          identifying usage trends, determining the effectiveness of our
          promotional campaigns and to evaluate and improve our Website,
          products, marketing and your experience. We may use and store this
          information in aggregated and anonymized form so that it is not
          associated with individual end users and does not include personal
          information. We will not use identifiable personal information without
          your consent.
        </li>
      </ul>

      <h2 id="section3">3. Will Your Information Be Shared With Anyone?</h2>
      <p>
        <em>
          <strong>In Short:</strong> We only share information with your
          consent, to comply with laws, to provide you with services, to protect
          your rights, or to fulfill business obligations.
        </em>
      </p>
      <p>
        We may process or share your data that we hold based on the following
        legal basis:
      </p>
      <ul>
        <li>
          <strong>Consent:</strong> We may process your data if you have given
          us specific consent to use your personal information for a specific
          purpose.
        </li>

        <li>
          <strong>Legitimate Interests:</strong> We may process your data when
          it is reasonably necessary to achieve our legitimate business
          interests.
        </li>

        <li>
          <strong>Performance of a Contract:</strong> Where we have entered into
          a contract with you, we may process your personal information to
          fulfill the terms of our contract.
        </li>

        <li>
          <strong>Legal Obligations:</strong> We may disclose your information
          where we are legally required to do so in order to comply with
          applicable law, governmental requests, a judicial proceeding, court
          order, or legal process, such as in response to a court order or a
          subpoena (including in response to public authorities to meet national
          security or law enforcement requirements).
        </li>

        <li>
          <strong>Vital Interests:</strong> We may disclose your information
          where we believe it is necessary to investigate, prevent, or take
          action regarding potential violations of our policies, suspected
          fraud, situations involving potential threats to the safety of any
          person and illegal activities, or as evidence in litigation in which
          we are involved.
        </li>
      </ul>

      <p>
        More specifically, we may need to process your data or share your
        personal information in the following situations:
      </p>

      <ul>
        <li>
          <strong>Business Transfers.</strong> We may share or transfer your
          information in connection with, or during negotiations of, any merger,
          sale of company assets, financing, or acquisition of all or a portion
          of our business to another company.
        </li>

        <li>
          <strong>
            Vendors, Consultants and Other Third-Party Service Providers.
          </strong>{" "}
          We may share your data with third-party vendors, service providers,
          contractors or agents who perform services for us or on our behalf and
          require access to such information to do that work. Examples include:
          payment processing, data analysis, email delivery, hosting services,
          customer service and marketing efforts. We may allow selected third
          parties to use tracking technology on the Website, which will enable
          them to collect data on our behalf about how you interact with our
          Website over time. This information may be used to, among other
          things, analyze and track data, determine the popularity of certain
          content, pages or features, and better understand online activity.
          Unless described in this notice, we do not share, sell, rent or trade
          any of your information with third parties for their promotional
          purposes. We have contracts in place with our data processors, which
          are designed to help safeguard your personal information. This means
          that they cannot do anything with your personal information unless we
          have instructed them to do it. They will also not share your personal
          information with any organization apart from us. They also commit to
          protect the data they hold on our behalf and to retain it for the
          period we instruct.
        </li>

        <li>
          <strong>Third-Party Advertisers.</strong> We may use third-party
          advertising companies to serve ads when you visit or use the Website.
          These companies may use information about your visits to our
          Website(s) and other websites that are contained in web cookies and
          other tracking technologies in order to provide advertisements about
          goods and services of interest to you.{" "}
        </li>

        <li>
          <strong>Affiliates.</strong> We may share your information with our
          affiliates, in which case we will require those affiliates to honor
          this privacy notice. Affiliates include our parent company and any
          subsidiaries, joint venture partners or other companies that we
          control or that are under common control with us.
        </li>

        <li>
          <strong>Business Partners.</strong> We may share your information with
          our business partners to offer you certain products, services or
          promotions.
        </li>
      </ul>

      <h2 id="section4">4. Who Will Your Information Be Shared With?</h2>
      <p>
        <em>
          <strong>In Short:</strong> We only share information with the
          following third parties.
        </em>
      </p>

      <p>
        We only share and disclose your information with the following third
        parties. If we have processed your data based on your consent and you
        wish to revoke your consent, please contact us using the contact details
        provided in the section below titled{" "}
        <Link href="#section14">
          &ldquo;How Can You Contact Us About This Notice?&rdquo;
        </Link>
        .
      </p>

      <ul>
        <li>
          <strong>Advertising, Direct Marketing, and Lead Generation.</strong>{" "}
          GoHighLevel, SendGrid and Amazon Web Services.
        </li>

        <li>
          <strong>Invoice and Billing.</strong> Stripe.
        </li>

        <li>
          <strong>Social Media Sharing and Advertising.</strong> Facebook
          advertising, Reddit plugins and Instagram advertising.
        </li>

        <li>
          <strong>Web and Mobile Analytics.</strong> Google Analytics.
        </li>
      </ul>

      <h2 id="section5">
        5. Do We Use Cookies And Other Tracking Technologies?
      </h2>
      <p>
        <em>
          <strong>In Short:</strong> We may use cookies and other tracking
          technologies to collect and store your information.
        </em>
      </p>

      <p>
        We may use cookies and similar tracking technologies (like web beacons
        and pixels) to access or store information. Specific information about
        how we use such technologies and how you can refuse certain cookies is
        set out in our Cookie Notice.
      </p>

      <h2 id="section6">6. What Is Our Stance On Third-Party Websites?</h2>
      <p>
        <em>
          <strong>In Short:</strong> We are not responsible for the safety of
          any information that you share with third-party providers who
          advertise, but are not affiliated with, our Website.
        </em>
      </p>

      <p>
        The Website may contain advertisements from third parties that are not
        affiliated with us and which may link to other websites, online services
        or mobile applications. We cannot guarantee the safety and privacy of
        data you provide to any third parties. Any data collected by third
        parties is not covered by this privacy notice. We are not responsible
        for the content or privacy and security practices and policies of any
        third parties, including other websites, services or applications that
        may be linked to or from the Website. You should review the policies of
        such third parties and contact them directly to respond to your
        questions.
      </p>

      <h2 id="section7">7. How Long Do We Keep Your Information?</h2>
      <p>
        <em>
          <strong>In Short:</strong> We keep your information for as long as
          necessary to fulfill the purposes outlined in this privacy notice
          unless otherwise required by law.
        </em>
      </p>

      <p>
        We will only keep your personal information for as long as it is
        necessary for the purposes set out in this privacy notice, unless a
        longer retention period is required or permitted by law (such as tax,
        accounting or other legal requirements). No purpose in this notice will
        require us keeping your personal information for longer than twelve (12)
        months past the termination of the user&apos;s account.
      </p>

      <p>
        When we have no ongoing legitimate business need to process your
        personal information, we will either delete or anonymize such
        information, or, if this is not possible (for example, because your
        personal information has been stored in backup archives), then we will
        securely store your personal information and isolate it from any further
        processing until deletion is possible.
      </p>

      <h2 id="section8">8. How Do We Keep Your Information Safe?</h2>
      <p>
        <em>
          <strong>In Short:</strong> We aim to protect your personal information
          through a system of organizational and technical security measures.
        </em>
      </p>

      <p>
        We have implemented appropriate technical and organizational security
        measures designed to protect the security of any personal information we
        process. However, despite our safeguards and efforts to secure your
        information, no electronic transmission over the Internet or information
        storage technology can be guaranteed to be 100% secure, so we cannot
        promise or guarantee that hackers, cybercriminals, or other unauthorized
        third parties will not be able to defeat our security, and improperly
        collect, access, steal, or modify your information. Although we will do
        our best to protect your personal information, transmission of personal
        information to and from our Website is at your own risk. You should only
        access the Website within a secure environment.
      </p>

      <h2 id="section9">9. Do We Collect Information From Minors?</h2>
      <p>
        <em>
          <strong>In Short:</strong> We do not knowingly collect data from or
          market to children under 18 years of age.
        </em>
      </p>

      <p>
        We do not knowingly solicit data from or market to children under 18
        years of age. By using the Website, you represent that you are at least
        18 or that you are the parent or guardian of such a minor and consent to
        such minor dependent’s use of the Website. If we learn that personal
        information from users less than 18 years of age has been collected, we
        will deactivate the account and take reasonable measures to promptly
        delete such data from our records. If you become aware of any data we
        may have collected from children under age 18, please contact us at{" "}
        <Link href="mailto:support@streamily.com">support@streamily.com</Link>.
      </p>

      <h2 id="section10">10. What Are Your Privacy Rights?</h2>
      <p>
        <em>
          <strong>In Short:</strong> In some regions, such as the European
          Economic Area (EEA) and United Kingdom (UK), you have rights that
          allow you greater access to and control over your personal
          information. You may review, change, or terminate your account at any
          time.
        </em>
      </p>

      <p>
        In some regions (like the EEA and UK), you have certain rights under
        applicable data protection laws. These may include the right (i) to
        request access and obtain a copy of your personal information, (ii) to
        request rectification or erasure; (iii) to restrict the processing of
        your personal information; and (iv) if applicable, to data portability.
        In certain circumstances, you may also have the right to object to the
        processing of your personal information. To make such a request, please
        use the <Link href="#contact">contact details </Link> provided below. We
        will consider and act upon any request in accordance with applicable
        data protection laws.
      </p>

      <p>
        If we are relying on your consent to process your personal information,
        you have the right to withdraw your consent at any time. Please note
        however that this will not affect the lawfulness of the processing
        before its withdrawal, nor will it affect the processing of your
        personal information conducted in reliance on lawful processing grounds
        other than consent.
      </p>

      <p>
        If you are a resident in the EEA or UK and you believe we are unlawfully
        processing your personal information, you also have the right to
        complain to your local data protection supervisory authority. You can
        find their contact details here:
        <Link href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
          http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
        </Link>
        .
      </p>

      <p>
        If you are a resident in Switzerland, the contact details for the data
        protection authorities are available here:{" "}
        <Link href="https://www.edoeb.admin.ch/edoeb/en/home.html">
          https://www.edoeb.admin.ch/edoeb/en/home.html
        </Link>
        .
      </p>

      <p>
        If you have questions or comments about your privacy rights, you may
        email us at{" "}
        <Link href="mailto:support@streamily.com">support@streamily.com</Link>.
      </p>

      <h3>Account Information</h3>

      <p>
        If you would at any time like to review or change the information in
        your account or terminate your account, contact us using the{" "}
        <Link href="#section14">contact information</Link> provided below.
      </p>

      <p>
        Upon your request to terminate your account, we will deactivate or
        delete your account and information from our active databases. However,
        we may retain some information in our files to prevent fraud,
        troubleshoot problems, assist with any investigations, enforce our Terms
        of Use and/or comply with applicable legal requirements.
      </p>

      <p>
        <strong>Cookies and similar technologies:</strong> Most Web browsers are
        set to accept cookies by default. If you prefer, you can usually choose
        to set your browser to remove cookies and to reject cookies. If you
        choose to remove cookies or reject cookies, this could affect certain
        features or services of our Website. To opt-out of interest-based
        advertising by advertisers on our Website visit{" "}
        <Link href="http://www.aboutads.info/choices/">
          http://www.aboutads.info/choices/
        </Link>
        .
      </p>

      <p>
        <strong>Opting out of email marketing:</strong> You can unsubscribe from
        our marketing email list at any time by clicking on the unsubscribe link
        in the emails that we send or by contacting us using the details
        provided below. You will then be removed from the marketing email list —
        however, we may still communicate with you, for example to send you
        service-related emails that are necessary for the administration and use
        of your account, to respond to service requests, or for other
        non-marketing purposes. To otherwise opt-out, you may contact us using
        the <Link href="#section14">contact information</Link> provided below.
      </p>

      <h2 id="section11">11. Controls For Do-Not-Track Features</h2>
      <p>
        Most web browsers and some mobile operating systems and mobile
        applications include a Do-Not-Track (&ldquo;DNT&rdquo;) feature or
        setting you can activate to signal your privacy preference not to have
        data about your online browsing activities monitored and collected. At
        this stage no uniform technology standard for recognizing and
        implementing DNT signals has been finalized. As such, we do not
        currently respond to DNT browser signals or any other mechanism that
        automatically communicates your choice not to be tracked online. If a
        standard for online tracking is adopted that we must follow in the
        future, we will inform you about that practice in a revised version of
        this privacy notice.{" "}
      </p>

      <h2 id="section12">
        12. Do California Residents Have Specific Privacy Rights?
      </h2>
      <p>
        <em>
          <strong>In Short:</strong> Yes, if you are a resident of California,
          you are granted specific rights regarding access to your personal
          information.
        </em>
      </p>
      <p>
        California Civil Code Section 1798.83, also known as the &ldquo;Shine
        The Light&rdquo; law, permits our users who are California residents to
        request and obtain from us, once a year and free of charge, information
        about categories of personal information (if any) we disclosed to third
        parties for direct marketing purposes and the names and addresses of all
        third parties with which we shared personal information in the
        immediately preceding calendar year. If you are a California resident
        and would like to make such a request, please submit your request in
        writing to us using the contact information provided below.
      </p>
      <p>
        If you are under 18 years of age, reside in California, and have a
        registered account with the Website, you have the right to request
        removal of unwanted data that you publicly post on the Website. To
        request removal of such data, please contact us using the contact
        information provided below, and include the email address associated
        with your account and a statement that you reside in California. We will
        make sure the data is not publicly displayed on the Website, but please
        be aware that the data may not be completely or comprehensively removed
        from all our systems (e.g. backups, etc.).
      </p>

      <h3>CCPA Privacy Notice</h3>

      <p>
        The California Code of Regulations defines a &ldquo;resident&rdquo; as:
      </p>
      <ol>
        <li>
          Every individual who is in the State of California for other than a
          temporary or transitory purpose and
        </li>
        <li>
          Every individual who is domiciled in the State of California who is
          outside the State of California for a temporary or transitory purpose
        </li>
      </ol>

      <p>
        All other individuals are defined as &ldquo;non-residents.&rdquo; If
        this definition of &ldquo;resident&rdquo; applies to you, we must adhere
        to certain rights and obligations regarding your personal information.
      </p>

      <h3>What categories of personal information do we collect?</h3>
      <p>
        We have collected the following categories of personal information in
        the past twelve (12) months:
      </p>

      <Table>
        <TableHead style={{ fontWeight: "bold" }}>
          <TableRow>
            <TableCell>Category</TableCell>
            <TableCell>Examples</TableCell>
            <TableCell>Collected</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>A. Identifiers</TableCell>
            <TableCell>
              Contact details, such as real name, alias, postal address,
              telephone or mobile contact number, unique personal identifier,
              online identifier, Internet Protocol address, email address and
              account name
            </TableCell>
            <TableCell>Yes</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              B. Personal information categories listed in the California
              Customer Records statute
            </TableCell>
            <TableCell>
              Name, contact information, education, employment, employment
              history and financial information
            </TableCell>
            <TableCell>Yes</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              C. Protected classification characteristics under California or
              federal law
            </TableCell>
            <TableCell>Gender and date of birth</TableCell>
            <TableCell>No</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>D. Commercial information</TableCell>
            <TableCell>
              Transaction information, purchase history, financial details and
              payment information
            </TableCell>
            <TableCell>Yes</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>E. Biometric information</TableCell>
            <TableCell>Fingerprints and voiceprints</TableCell>
            <TableCell>No</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>F. Internet or other similar network activity</TableCell>
            <TableCell>
              Browsing history, search history, online behavior, interest data,
              and interactions with our and other websites, applications,
              systems and advertisements
            </TableCell>
            <TableCell>No</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>G. Geolocation data</TableCell>
            <TableCell>Device location</TableCell>
            <TableCell>Yes</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              H. Audio, electronic, visual, thermal, olfactory, or similar
              information
            </TableCell>
            <TableCell>
              Images and audio, video or call recordings created in connection
              with our business activities
            </TableCell>
            <TableCell>No</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              I. Professional or employment-related information
            </TableCell>
            <TableCell>
              Business contact details in order to provide you our services at a
              business level, job title as well as work history and professional
              qualifications if you apply for a job with us
            </TableCell>
            <TableCell>No</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>J. Education Information</TableCell>
            <TableCell>Student records and directory information</TableCell>
            <TableCell>No</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              K. Inferences drawn from other personal information
            </TableCell>
            <TableCell>
              Inferences drawn from any of the collected personal information
              listed above to create a profile or summary about, for example, an
              individual’s preferences and characteristics
            </TableCell>
            <TableCell>No</TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <br />

      <p>
        We may also collect other personal information outside of these
        categories instances where you interact with us in-person, online, or by
        phone or mail in the context of:
      </p>

      <ul>
        <li>Receiving help through our customer support channels;</li>
        <li>Participation in customer surveys or contests; and</li>
        <li>
          Facilitation in the delivery of our Services and to respond to your
          inquiries.
        </li>
      </ul>

      <h3>How do we use and share your personal information?</h3>
      <p>SmashNet collects and shares your personal information through:</p>
      <ul>
        <li>Targeting cookies/Marketing cookies</li>
        <li>Beacons/Pixels/Tags</li>
      </ul>
      <p>
        More information about our data collection and sharing practices can be
        found in this privacy notice.
      </p>
      <p>
        You may contact us by email at{" "}
        <Link href="mailto:support@streamily.com">support@streamily.com</Link>,
        or by referring to the contact details at the bottom of this document.
      </p>
      <p>
        If you are using an authorized agent to exercise your right to opt-out
        we may deny a request if the authorized agent does not submit proof that
        they have been validly authorized to act on your behalf.
      </p>

      <h3>Will your information be shared with anyone else?</h3>
      <p>
        We may disclose your personal information with our service providers
        pursuant to a written contract between us and each service provider.
        Each service provider is a for-profit entity that processes the
        information on our behalf.
      </p>
      <p>
        We may use your personal information for our own business purposes, such
        as for undertaking internal research for technological development and
        demonstration. This is not considered to be &ldquo;selling&rdquo; of
        your personal data.
      </p>

      <p>
        SmashNet has disclosed the following categories of personal information
        to third parties for a business or commercial purpose in the preceding
        twelve (12) months:
      </p>

      <ul>
        <li>
          <strong>Category A.</strong> Identifiers, such as contact details,
          like your real name, alias, postal address, telephone or mobile
          contact number, unique personal identifier, online identifier,
          Internet Protocol address, email address and account name.
        </li>

        <li>
          <strong>Category B.</strong> Personal information, as defined in the
          California Customer Records law, such as your name, contact
          information, education, employment, employment history and financial
          information.
        </li>

        <li>
          <strong>Category D.</strong> Commercial information, such as
          transaction information, purchase history, financial details and
          payment information.
        </li>

        <li>
          <strong>Category G.</strong> Geolocation data, such as device
          location.
        </li>
      </ul>

      <p>
        The categories of third parties to whom we disclosed personal
        information for a business or commercial purpose can be found under{" "}
        <Link href="#section4">
          &ldquo;Who Will Your Information Be Shared With?&rdquo;
        </Link>
        .
      </p>

      <p>
        SmashNet has not sold any personal information to third parties for a
        business or commercial purpose in the preceding twelve (12) months.
        SmashNet will not sell personal information in the future belonging to
        website visitors, users and other consumers.
      </p>

      <h3>Your rights with respect to your personal data</h3>

      <h4>Right to request deletion of the data - Request to delete</h4>

      <p>
        You can ask for the deletion of your personal information. If you ask us
        to delete your personal information, we will respect your request and
        delete your personal information, subject to certain exceptions provided
        by law, such as (but not limited to) the exercise by another consumer of
        his or her right to free speech, our compliance requirements resulting
        from a legal obligation or any processing that may be required to
        protect against illegal activities.
      </p>

      <h4>Right to be informed - Request to know</h4>

      <p>Depending on the circumstances, you have a right to know:</p>

      <ul>
        <li>whether we collect and use your personal information;</li>
        <li>the categories of personal information that we collect;</li>
        <li>
          the purposes for which the collected personal information is used;
        </li>
        <li>whether we sell your personal information to third parties;</li>
        <li>
          the categories of personal information that we sold or disclosed for a
          business purpose;
        </li>
        <li>
          the categories of third parties to whom the personal information was
          sold or disclosed for a business purpose; and
        </li>
        <li>
          the business or commercial purpose for collecting or selling personal
          information.
        </li>
      </ul>

      <p>
        In accordance with applicable law, we are not obligated to provide or
        delete consumer information that is de-identified in response to a
        consumer request or to re-identify individual data to verify a consumer
        request.
      </p>

      <h4>
        Right to Non-Discrimination for the Exercise of a Consumer’s Privacy
        Rights
      </h4>
      <p>
        We will not discriminate against you if you exercise your privacy
        rights.
      </p>

      <h4>Verification process</h4>
      <p>
        Upon receiving your request, we will need to verify your identity to
        determine you are the same person about whom we have the information in
        our system. These verification efforts require us to ask you to provide
        information so that we can match it with information you have previously
        provided us. For instance, depending on the type of request you submit,
        we may ask you to provide certain information so that we can match the
        information you provide with the information we already have on file, or
        we may contact you through a communication method (e.g. phone or email)
        that you have previously provided to us. We may also use other
        verification methods as the circumstances dictate.
      </p>
      <p>
        We will only use personal information provided in your request to verify
        your identity or authority to make the request. To the extent possible,
        we will avoid requesting additional information from you for the
        purposes of verification. If, however, we cannot verify your identity
        from the information already maintained by us, we may request that you
        provide additional information for the purposes of verifying your
        identity, and for security or fraud-prevention purposes. We will delete
        such additionally provided information as soon as we finish verifying
        you.
      </p>

      <h4>Other privacy rights</h4>
      <ul>
        <li>you may object to the processing of your personal data</li>

        <li>
          you may request correction of your personal data if it is incorrect or
          no longer relevant, or ask to restrict the processing of the data
        </li>

        <li>
          you can designate an authorized agent to make a request under the CCPA
          on your behalf. We may deny a request from an authorized agent that
          does not submit proof that they have been validly authorized to act on
          your behalf in accordance with the CCPA.
        </li>

        <li>
          you may request to opt-out from future selling of your personal
          information to third parties. Upon receiving a request to opt-out, we
          will act upon the request as soon as feasibly possible, but no later
          than 15 days from the date of the request submission.
        </li>
      </ul>

      <p>
        To exercise these rights, you can contact us by email at{" "}
        <Link href="mailto:support@streamily.com">support@streamily.com</Link>,
        or by referring to the contact details at the bottom of this document.
        If you have a complaint about how we handle your data, we would like to
        hear from you.
      </p>

      <h2 id="section13">13. Do We Make Updates To This Notice?</h2>

      <p>
        <em>
          <strong>In Short:</strong> Yes, we will update this notice as
          necessary to stay compliant with relevant laws.
        </em>
      </p>

      <p>
        We may update this privacy notice from time to time. The updated version
        will be indicated by an updated &ldquo;Revised&rdquo; date and the
        updated version will be effective as soon as it is accessible. If we
        make material changes to this privacy notice, we may notify you either
        by prominently posting a notice of such changes or by directly sending
        you a notification. We encourage you to review this privacy notice
        frequently to be informed of how we are protecting your information.
      </p>

      <h2 id="section14">14. How Can You Contact Us About This Notice?</h2>

      <p>
        If you have questions or comments about this notice, you may email us at{" "}
        <Link href="mailto:support@streamily.com">support@streamily.com</Link>{" "}
        or by post to:
      </p>

      <address>
        SmashNet
        <br />
        21200 W Oxnard St #348
        <br />
        Woodland Hills, CA 91367
        <br />
        United States
        <br />
      </address>

      <h2 id="section15">
        15. How Can You Review, Update, Or Delete The Data We Collect From You?
      </h2>
      <p>
        Based on the applicable laws of your country, you may have the right to
        request access to the personal information we collect from you, change
        that information, or delete it in some circumstances. To request to
        review, update, or delete your personal information, please email{" "}
        <Link href="mailto:support@streamily.com">support@streamily.com</Link>.
      </p>
    </ContentPage>
  );
}
