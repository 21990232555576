export function getAllDescendantIds(node) {
  if (!node.children || node.children.length === 0) return [];
  return node.children.flatMap((child) => [
    child.categoryId,
    ...getAllDescendantIds(child),
  ]);
}

export function doesNodeHaveSelectedChildren({ node, selected }) {
  return node.children?.some(
    (child) =>
      selected?.includes(child.categoryId) ||
      getAllDescendantIds(child).some((id) => selected?.includes(id)),
  );
}

export function isNodeSelected({ node, selected }) {
  return (
    selected?.includes(node.categoryId) ||
    doesNodeHaveSelectedChildren({ node, selected })
  );
}
