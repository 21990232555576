import { IconButton, Menu } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import * as React from "react";
import AccountActions from "../AccountActions";
import UserDetails from "../UserDetails";

const AccountDashboardMenu = React.memo(function AccountDashboardMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const buttonRef = React.useRef();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = () => {
    setAnchorEl(buttonRef.current);
  };

  const isOpen = Boolean(anchorEl);

  return (
    <>
      <IconButton
        ref={buttonRef}
        onClick={handleOpen}
        aria-haspopup="true"
        aria-controls="account-menu"
      >
        <PersonIcon fontSize="small" />
      </IconButton>

      <Menu
        open={isOpen}
        onClose={handleClose}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <UserDetails fullName email />
        <AccountActions p={0} onClick={handleClose} />
      </Menu>
    </>
  );
});

export default AccountDashboardMenu;
