import * as React from "react";
import { useCurrentUser } from "../globalState";
import { useLocation } from "react-router-dom";
import { useMatchesXsDown } from "./useBreakpoint";

export default function useShrinkBuffer() {
  const currentUser = useCurrentUser();
  const location = useLocation();
  const matchesXsDown = useMatchesXsDown();

  const shrinkBuffer = React.useMemo(() => {
    return (
      matchesXsDown &&
      currentUser &&
      (currentUser.roles.isAdmin ||
        currentUser.roles.isSuperadmin ||
        currentUser.roles.isTalent ||
        currentUser.roles.isArtist ||
        currentUser.roles.isAgency ||
        currentUser.roles.isTalentCoordinator)
    );
  }, [matchesXsDown, currentUser]);

  const isDashboard = React.useMemo(
    () => location.pathname.toLowerCase().startsWith("/dashboard"),
    [location.pathname],
  );

  React.useEffect(() => {
    const handler = () => {
      if (shrinkBuffer && isDashboard) {
        const root = document.getElementById("root");
        root.classList.toggle("shrink-buffer", shrinkBuffer);
      }
    };

    document.addEventListener("resize", handler);

    handler();

    return () => {
      document.removeEventListener("resize", handler);
    };
  }, [shrinkBuffer, isDashboard]);

  return React.useMemo(
    () => ({
      shrinkBuffer,
      isDashboard,
      matchesXsDown,
      shouldShrink: shrinkBuffer && isDashboard,
    }),
    [shrinkBuffer, matchesXsDown, isDashboard],
  );
}
