import { Avatar } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { SmallAvatar } from "../../../theme";

const StyledAnchorLink = styled("a")(({ theme }) => ({
  alignItems: "center",
  background: theme.palette.grey[200],
  borderRadius: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  gap: 0,
  justifyContent: "center",
  padding: theme.spacing(0.5, 2, 0.25, 2),
  flex: 1,
  minWidth: "45%",
  maxWidth: "50%",
  border: `1px solid ${theme.palette.grey[300]}`,
  textDecoration: "none",

  [theme.breakpoints.up("md")]: {
    aspectRatio: "2/1",
    minWidth: "30%",
    maxWidth: "33%",
    padding: theme.spacing(0.5, 1, 0.25, 1),
  },

  [theme.breakpoints.up("lg")]: {
    minWidth: "22%",
    maxWidth: "25%",
    aspectRatio: "auto",
  },

  "& .category-title": {
    ...theme.typography.subtitle1,
    margin: 0,
  },

  "&:hover": {
    background: theme.palette.grey[300],
  },
}));

const StyledRouterLink = styled(Link)(({ theme }) => ({
  alignItems: "center",
  background: theme.palette.grey[200],
  borderRadius: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  gap: 0,
  justifyContent: "center",
  padding: theme.spacing(0.5, 2, 0.25, 2),
  flex: 1,
  minWidth: "45%",
  maxWidth: "50%",
  border: `1px solid ${theme.palette.grey[300]}`,
  textDecoration: "none",

  [theme.breakpoints.up("md")]: {
    aspectRatio: "2/1",
    minWidth: "30%",
    maxWidth: "33%",
    padding: theme.spacing(0.5, 1, 0.25, 1),
  },

  [theme.breakpoints.up("lg")]: {
    minWidth: "22%",
    maxWidth: "25%",
    aspectRatio: "auto",
  },

  "& .category-title": {
    ...theme.typography.subtitle1,
    margin: 0,
  },

  "&:hover": {
    background: theme.palette.grey[300],
  },
}));

const StyledGroup = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",

  "& .MuiAvatar-root": {
    marginRight: theme.spacing(-1),
    border: `2px solid ${theme.palette.background.paper}`,
  },
}));

export default function Category({ name, id, shops, onClick }) {
  const location = useLocation();

  if (location?.pathname === "/shops") {
    return (
      <StyledAnchorLink href={`/shops?g=${id}`} onClick={onClick}>
        <StyledGroup>
          {shops.map((shop, idx) =>
            idx === 1 || shops.length < 3 ? (
              <Avatar src={shop.avatarUrl} key={`${id}-${shop.influencerId}`} />
            ) : (
              <SmallAvatar
                src={shop.avatarUrl}
                key={`${id}-${shop.influencerId}`}
              />
            ),
          )}
        </StyledGroup>

        <span className="category-title">{name}</span>
      </StyledAnchorLink>
    );
  }

  return (
    <StyledRouterLink to={`/shops?g=${id}`} onClick={onClick}>
      <StyledGroup>
        {shops.map((shop, idx) =>
          idx === 1 || shops.length < 3 ? (
            <Avatar src={shop.avatarUrl} key={`${id}-${shop.influencerId}`} />
          ) : (
            <SmallAvatar
              src={shop.avatarUrl}
              key={`${id}-${shop.influencerId}`}
            />
          ),
        )}
      </StyledGroup>

      <span className="category-title">{name}</span>
    </StyledRouterLink>
  );
}

Category.propTypes = {
  name: PropTypes.string.isRequired,
  shops: PropTypes.arrayOf(
    PropTypes.shape({
      avatarUrl: PropTypes.string,
      influencerId: PropTypes.number,
      route: PropTypes.string,
    }),
  ).isRequired,
  id: PropTypes.number.isRequired,
  onClick: PropTypes.func,
};
