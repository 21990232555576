import { Dialog } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import scrollbarStyles from "../../theme/scrollbarStyles";

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-scrollPaper": {
    alignItems: "flex-start",
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
  "& .MuiPaper-root": {
    border: "0.65px solid #CCC",
    overflowX: "hidden",
    backgroundColor: "linear-gradient(0deg, #FFF 0%, #FFF 100%), #F9F9F9",
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down("sm")]: { width: "100vw" },
    [theme.breakpoints.up("md")]: { width: 800, minWidth: "632px" },
  },
  "& .MuiDialog-paperScrollPaper": {
    ...scrollbarStyles(theme),
  },
  "& .MuiDialog-paperScrollPaper::-webkit-scrollbar-track": {
    borderRadius: theme.spacing(0.5),
  },
}));

export const Button = styled("button")(({ theme }) => ({
  background:
    "linear-gradient(0deg, #1096E7 0%, #1096E7 100%), linear-gradient(0deg, #FAFCFF 0%, #FAFCFF 100%), #F9F9F9;",
  border: "none",
  borderRadius: theme.spacing(1),
  color: theme.palette.getContrastText("#1096E7"),
  cursor: "pointer",
  fontFamily: theme.typography.interFontStack,
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 600,
  minHeight: 42,
  minWidth: 88,
  padding: theme.spacing(1, 3),
  boxShadow: theme.boxShadow.buttonDefault,
  transition: "box-shadow 0.1s ease-in-out, filter 0.1s ease-in-out",
  "&:hover": {
    boxShadow: theme.boxShadow.buttonHover,
    filter: "brightness(1.05)",
  },
  "&:disabled, &:hover:disabled": {
    background: theme.palette.grey[300],
    color: theme.palette.text.disabled,
    boxShadow: "none",
    filter: "none",
    cursor: "initial",
  },
  "&.small": {
    minHeight: 32,
    minWidth: 64,
  },
}));
