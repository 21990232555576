import { useContext } from "react";
import { createContext } from "react";
import { Money } from "../../../../domain";

const UPDATE_NOTES = "update_notes";
const UPDATE_PERSONALIZATION_TO = "update_personalization_to";
const UPDATE_ADDONS = "update_addons";

export function createInitialState(product) {
  const price = product ? product.subtotal : Money.ZERO_USD;

  return {
    price,
    notes: "",
    personalizationTo: "",
    addons: {},
  };
}

export function productModalReducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_NOTES:
      return { ...state, notes: payload };
    case UPDATE_PERSONALIZATION_TO:
      return { ...state, personalizationTo: payload };
    case UPDATE_ADDONS:
      return { ...state, addons: payload };

    default:
      return state;
  }
}

export function updateNotes(payload) {
  return { type: UPDATE_NOTES, payload };
}

export function updatePersonalizationTo(payload) {
  return { type: UPDATE_PERSONALIZATION_TO, payload };
}

export function updateAddons(payload) {
  return { type: UPDATE_ADDONS, payload };
}

export const productModalContext = createContext(null);

export function useProductModal() {
  return useContext(productModalContext);
}
