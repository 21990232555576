import { Box, Typography } from "@material-ui/core";
import { A, D, F, flow, S } from "@mobily/ts-belt";
import { useAtom, useAtomValue } from "jotai";
import PropTypes from "prop-types";
import * as styles from "../styles";
import TagSearch from "./TagSearch";

const formatTag = flow(
  S.trim,
  S.toLowerCase,
  S.replaceByRe(/#/g, ""),
  S.replaceByRe(/\s/g, "-"),
  S.replaceByRe(/-+/g, "-"),
);

async function updateProductTags({ productId, tags }) {
  try {
    const response = await window.fetch(`/api/productTag/${productId}`, {
      method: "PUT",
      body: JSON.stringify({ tags }),
      headers: { "content-type": "application/json" },
    });

    if (!response.ok) {
      throw new Error("Failed to update product tags");
    }

    const json = await response.json();

    console.log(json);
  } catch (err) {
    console.error(err);
  }
}

function IncreaseExposureStep({ stateAtom, productAtom, productTagsAtom }) {
  const state = useAtomValue(stateAtom);
  const [product, setProduct] = useAtom(productAtom);
  const [productTags, setProductTags] = useAtom(productTagsAtom);

  const handleAddTag = (tagString) => {
    if (!tagString) {
      return;
    }

    const newTags = Array.from(
      new Set(tagString.split(",").map(formatTag)),
      (tag) => ({ tagString: tag, active: true }),
    );

    if (!newTags.length) {
      return;
    }

    const uniqueTags = new Set(A.map(productTags, D.get("tagString")));

    // Add character and franchise tags to unique tags
    if (product.characterTagString) {
      uniqueTags.add(formatTag(product.characterTagString));
    }

    if (product.franchiseTagString) {
      uniqueTags.add(formatTag(product.franchiseTagString));
    }

    const filteredTags = newTags.filter(
      ({ tagString }) => !uniqueTags.has(tagString),
    );

    const updatedProductTags = A.concat(productTags, filteredTags);
    setProductTags(updatedProductTags);

    // Update the tags on the backend here because users keep forgetting
    // to save their changes. This is to ensure that the tags are updated
    // so we don't get a tech request saying "the tags aren't saving".
    if (state.isEditing) {
      updateProductTags({
        productId: product.productId,
        tags: updatedProductTags,
      });
    }
  };

  const handleDeleteTag = (tagString) => {
    const updatedProductTags = A.filter(
      productTags,
      (tag) => tag.tagString !== tagString,
    );

    setProductTags(updatedProductTags);

    // Update the tags on the backend here because users keep forgetting
    // to save their changes. This is to ensure that the tags are updated
    // so we don't get a tech request saying "I can't delete product tags".
    if (state.isEditing) {
      updateProductTags({
        productId: product.productId,
        tags: updatedProductTags,
      });
    }
  };

  return (
    <Box display="flex" flexDirection="column" gridGap="8px">
      <Box>
        <styles.Label>Add Tags</styles.Label>

        <Typography variant="body2" color="textSecondary">
          Tag your product to get more exposure to different Franchises, Talent,
          and Fans
        </Typography>
      </Box>

      <Box
        display="grid"
        gridTemplateColumns="repeat(2, minmax(1px, 1fr))"
        gridGap="16px"
      >
        <TagSearch
          clearOnBlur
          onAddTag={handleAddTag}
          onRemoveTag={F.identity}
        />
      </Box>

      <Box>
        {A.isEmpty(productTags) && (
          <Typography variant="body2" color="textSecondary">
            No tags.
          </Typography>
        )}

        {A.isNotEmpty(productTags) && (
          <Box display="flex" gridGap="8px" flexWrap="wrap">
            {productTags.map((tag) => (
              <styles.Chip
                size="small"
                label={tag.tagString}
                key={tag.tagString}
                onDelete={() => handleDeleteTag(tag.tagString)}
              />
            ))}
          </Box>
        )}
      </Box>

      <Box mt={1}>
        <styles.Label>Does this Product Feature A Character?</styles.Label>

        <Typography variant="body2" color="textSecondary">
          Give this item more exposure by assigning a character and franchise
        </Typography>
      </Box>

      <Box
        width="100%"
        display="grid"
        gridTemplateColumns="repeat(2, minmax(1px, 1fr))"
        gridGap="16px"
      >
        <TagSearch
          onAddTag={(newTag) => {
            const trimmedTag = S.trim(newTag);
            if (!S.isEmpty(trimmedTag)) {
              setProduct(D.set("characterTagString", formatTag(trimmedTag)));
            }
          }}
          onRemoveTag={() => {
            handleDeleteTag(formatTag(product.characterTagString));
            setProduct(D.set("characterTagString", null));
          }}
          isCharacter
          tag={product.characterTagString ?? null}
        />

        <TagSearch
          onAddTag={(newTag) => {
            const trimmedTag = S.trim(newTag);
            if (!S.isEmpty(trimmedTag)) {
              setProduct(D.set("franchiseTagString", formatTag(trimmedTag)));
            }
          }}
          onRemoveTag={() => {
            handleDeleteTag(formatTag(product.franchiseTagString));
            setProduct(D.set("franchiseTagString", null));
          }}
          isFranchise
          tag={product.franchiseTagString ?? null}
        />
      </Box>
    </Box>
  );
}

IncreaseExposureStep.propTypes = {
  productAtom: PropTypes.object.isRequired,
  productTagsAtom: PropTypes.object.isRequired,
  stateAtom: PropTypes.object.isRequired,
};

export default {
  Component: IncreaseExposureStep,
  title: "Increase Exposure",
};
