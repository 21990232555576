import { Typography } from "@material-ui/core";
import { ChevronLeftRounded, ChevronRightRounded } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import * as React from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "../../../hooks";
import { PreloadLink } from "../../../theme";
import UpcomingEvent from "../../Home/Section/UpcomingEvent";
import { useHeroShops } from "./hooks";
import "./styles.css";

function isHidden({ idx, animatedShops }) {
  return (
    (idx !== animatedShops.current &&
      idx !== animatedShops.next &&
      idx !== animatedShops.prev) ||
    (animatedShops.direction === "init" && idx !== animatedShops.current)
  );
}

function isAnimatingIn({ idx, animatedShops }) {
  return idx === animatedShops.current;
}

function isAnimatingOut({ idx, animatedShops }) {
  return (
    (idx === animatedShops.next && animatedShops.direction === "left") ||
    (idx === animatedShops.prev && animatedShops.direction === "right")
  );
}

export default function HeroShops() {
  const { shops } = useHeroShops();

  const [animatedShops, setAnimatedShops] = React.useState({
    direction: "init",
    prev: 0,
    next: 0,
    current: 0,
  });

  React.useEffect(() => {
    setAnimatedShops({
      direction: "init",
      current: 0,
      next: shops.length > 1 ? 1 : 0,
      prev: shops.length - (1 % shops.length),
    });
  }, [shops]);

  const nextShop = React.useCallback(() => {
    setAnimatedShops((animatedShops) => ({
      direction: "left",
      prev: animatedShops.current,
      next: (animatedShops.next + 1) % shops.length,
      current: animatedShops.next,
    }));
  }, [shops]);

  const prevShop = React.useCallback(() => {
    setAnimatedShops((animatedShops) => ({
      direction: "right",
      prev: animatedShops.current,
      next: (animatedShops.next - 1 + shops.length) % shops.length,
      current: animatedShops.prev,
    }));
  }, [shops]);

  const matchesSmDown = useMediaQuery("(max-width: 800px)");

  if (!shops.length) {
    return (
      <div className="hero-shops__root">
        <div className="hero-shops__shop">
          <div
            className="hero-shops__image"
            variant="rect"
            style={{ background: `#dddddd` }}
          />

          <div className="hero-shops__content">
            <div className="hero-shops__cta">
              <Skeleton
                variant="rect"
                width={matchesSmDown ? 300 : 400}
                height={60}
              />
              <br />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <br />

              <div
                style={{
                  display: "flex",
                  justifyContent: matchesSmDown ? "flex-start" : "flex-end",
                }}
              >
                <Skeleton
                  variant="rect"
                  width={matchesSmDown ? "90vw" : "16rem"}
                  height={matchesSmDown ? 32 : 64}
                />
              </div>
            </div>

            <div className="hero-shops__talents">
              {Array.from({ length: 5 }).map((_, idx) => (
                <div className="hero-shops__talent" key={idx}>
                  <Skeleton variant="rect" width={162} height={216} />
                  <Skeleton variant="text" width={162} height={32} />
                  <Skeleton variant="text" width={162} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="hero-shops__root">
      <div className="hero-shops__buttons">
        <button onClick={prevShop} className="hero-shops__buttons__icon-button">
          <ChevronLeftRounded fontSize="large" />
        </button>

        <button onClick={nextShop} className="hero-shops__buttons__icon-button">
          <ChevronRightRounded fontSize="large" />
        </button>
      </div>

      {shops.map((currentShop, idx) => (
        <div
          className={clsx(
            "hero-shops__shop",
            isHidden({ idx, animatedShops }) && "visually-hidden",
          )}
          transition-style={
            isAnimatingIn({ idx, animatedShops })
              ? `in:wipe:${animatedShops.direction}`
              : isAnimatingOut({
                  idx,
                  animatedShops,
                })
              ? `out:wipe:${animatedShops.direction}`
              : ""
          }
          key={currentShop.influencerId}
        >
          <div
            className="hero-shops__image"
            style={{
              backgroundImage: `url(${currentShop.heroImageUrl})`,
            }}
          />

          <div className="hero-shops__content">
            <div className="hero-shops__cta">
              <Typography gutterBottom variant={matchesSmDown ? "h5" : "h4"}>
                {currentShop.name}
              </Typography>
              <Typography
                component="p"
                variant={matchesSmDown ? "caption" : "body2"}
              >
                {currentShop.caption}
              </Typography>

              <br />

              <div className="hero-shops__cta-button-container">
                <Link
                  className="hero-shops__cta-button__button"
                  to={`/${currentShop.route}`}
                >
                  Visit the {currentShop.name} Shop
                </Link>
              </div>
            </div>{" "}
            <div className="hero-shops__talents">
              {currentShop.talent.map((talent) => (
                <div className="hero-shops__talent" key={talent.influencerId}>
                  {talent.nextEventTime ? (
                    <UpcomingEvent influencer={talent} />
                  ) : null}

                  <PreloadLink to={`/${talent.route}`}>
                    <div className="hero-shops__talent-image-container">
                      <img
                        height="216"
                        width="162"
                        className="hero-shops__talent-image"
                        src={talent.avatarUrl}
                      />
                    </div>

                    <Typography variant="h6">{talent.name}</Typography>
                    <Typography variant="caption">
                      {talent.famousFor || currentShop.name} /{" "}
                      {talent.famousCharacter || talent.name}
                    </Typography>
                  </PreloadLink>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
