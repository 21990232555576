import { Avatar, Box, Divider, Hidden, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { D, G } from "@mobily/ts-belt";
import PropTypes from "prop-types";
import { useRecentShops, useShopsSearch } from "../../../hooks";
import { PreloadLink } from "../../../theme";
import { Influencer } from "../../../domain";
import useCategories from "../../../hooks/useCategories";
import Category from "./Category";
import SearchResult from "./SearchResult";

const StyledShop = styled(PreloadLink)(({ theme }) => ({
  alignItems: "center",
  background: "none",
  border: 0,
  borderRadius: theme.shape.borderRadius,
  color: "inherit",
  cursor: "pointer",
  display: "flex",
  flexBasis: "4rem",
  flexDirection: "column",
  font: "inherit",
  gap: theme.spacing(0.5),
  lineHeight: 1,
  margin: 0,
  padding: theme.spacing(1),
  textAlign: "center",
  textDecoration: "none",
  "&:hover": {
    background: theme.palette.action.hover,
  },
}));

const StyledIcon = styled("div")(({ theme }) => ({
  background: theme.palette.grey[300],
  borderRadius: "50%",
  height: theme.spacing(5),
  width: theme.spacing(5),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledButton = styled(PreloadLink)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flex: 1,
  padding: theme.spacing(2, 1),
  textDecoration: "none",
  justifyContent: "center",
  background: theme.palette.grey[200],
  borderRadius: theme.spacing(2),
  border: `1px solid ${theme.palette.grey[300]}`,
  ...theme.typography.caption,

  "&:hover": {
    background: theme.palette.grey[300],
  },
}));

export default function SearchResultsContainer({
  query,
  onClick,
  isFocused,
  isScrolled,
}) {
  const { shops, isError } = useShopsSearch(query);
  const { recentShops } = useRecentShops();
  const { categories } = useCategories();

  if (isScrolled) {
    return null;
  }

  const hasNoResults = shops.length === 0 && !isError && !isFocused;

  if (hasNoResults) {
    return null;
  }

  const hasRecentShops = recentShops.length > 0;
  const hasCategories = G.isObject(categories) && D.isNotEmpty(categories);

  if (!hasRecentShops && !hasCategories) {
    return null;
  }

  const hasResults = shops.length > 0 && query.length > 0 && !isError;

  return (
    <>
      <div
        onClick={() => {
          onClick(true);
        }}
        style={{
          position: "fixed",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: -1,
        }}
      />

      <div className="search__results-container">
        {!hasResults && hasRecentShops ? (
          <Box px={2}>
            <Typography gutterBottom variant="subtitle2">
              Recently Viewed
            </Typography>

            <Box display="flex" flexWrap="wrap">
              {recentShops.map((shop) => (
                <StyledShop
                  to={`/${shop.route}`}
                  endpoints={Influencer.getPreloadEndpoints(shop)}
                  onClick={onClick}
                  key={shop.influencerId}
                >
                  <Avatar src={shop.avatarUrl} alt="" />
                  <small>{shop.name}</small>
                </StyledShop>
              ))}

              <Hidden xsDown>
                <Box px={2}>
                  <Divider orientation="vertical" />
                </Box>
              </Hidden>

              <StyledShop onClick={onClick} to="/products">
                <StyledIcon>
                  <ChevronRightIcon />
                </StyledIcon>
                <small>Browse Products</small>
              </StyledShop>

              <StyledShop onClick={onClick} to="/shops">
                <StyledIcon>
                  <ChevronRightIcon />
                </StyledIcon>
                <small>Browse Shops</small>
              </StyledShop>
            </Box>
          </Box>
        ) : null}

        {!hasResults && G.isObject(categories) && D.isNotEmpty(categories) ? (
          <div style={{ maxHeight: "55vh", overflowY: "auto" }}>
            {!hasRecentShops && (
              <Box display="flex" gridGap="8px" px={2} pb={2}>
                <StyledButton to="/products" onClick={onClick}>
                  <span>Browse All Products</span>
                  <ChevronRightIcon />
                </StyledButton>

                <StyledButton to="/shops" onClick={onClick}>
                  <span>Browse All Shops</span>
                  <ChevronRightIcon />
                </StyledButton>
              </Box>
            )}

            <Box px={2}>
              <Typography gutterBottom variant="subtitle2">
                Popular Categories
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              gridGap="8px"
              flexWrap="wrap"
              px={2}
              pb={2}
            >
              {Object.entries(categories).map(([, { name, id, shops }]) => (
                <Category
                  key={id}
                  shops={shops}
                  id={id}
                  name={name}
                  onClick={onClick}
                />
              ))}
            </Box>
          </div>
        ) : null}

        {hasResults ? (
          <div>
            <Box px={2}>
              <Typography variant="subtitle2" gutterBottom>
                Results ({shops.length})
              </Typography>
            </Box>
            <div className="search__results-list">
              {shops.map((item) => (
                <SearchResult
                  item={item}
                  key={item.influencer_id}
                  onClick={onClick}
                />
              ))}
            </div>
          </div>
        ) : query.length > 0 && isError ? (
          <Box px={2} mt={recentShops.length ? 1 : 0}>
            <Typography variant="body2">
              No results for {JSON.stringify(query)}
            </Typography>
          </Box>
        ) : null}
      </div>
    </>
  );
}

SearchResultsContainer.propTypes = {
  query: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  isFocused: PropTypes.bool,
  isScrolled: PropTypes.bool,
};
