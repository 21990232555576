import React from "react";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider as MUIThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";

export default function ThemeProvider({ children }) {
  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline>{children}</CssBaseline>
    </MUIThemeProvider>
  );
}
