export default class Roles {
  #rawUser;
  #list;

  static get list() {
    return [
      { title: "Superadmin", key: "superadmin" },
      { title: "Admin", key: "admin" },
      { title: "Talent", key: "talent" },
      { title: "Talent Coordinator", key: "is_talent_coordinator" },
      { title: "Agent", key: "is_agent" },
      { title: "Artist", key: "is_artist" },
      { title: "Vendor", key: "is_vendor" },
      { title: "Charity", key: "is_charity" },
      { title: "Agency", key: "is_agency" },
      { title: "Convention", key: "is_convention" },
      // { title: "Customer", key: "is_customer" },
      { title: "Legacy", key: "is_legacy" },
      { title: "Salesperson", key: "is_salesperson" },
      { title: "Affiliate", key: "is_affiliate" },
    ];
  }

  static createRoles(rawUser) {
    return Roles.list.map((role) => ({
      ...role,
      active: Boolean(rawUser[role.key]),
    }));
  }

  constructor(rawUser) {
    this.#rawUser = rawUser;
    this.#list = Roles.createRoles(rawUser);
  }

  get isAdmin() {
    return Boolean(this.#rawUser.admin);
  }

  get isSuperadmin() {
    return Boolean(this.#rawUser.superadmin);
  }

  get isTalent() {
    return Boolean(this.#rawUser.talent);
  }

  get isTalentCoordinator() {
    return Boolean(this.#rawUser.is_talent_coordinator);
  }

  get isAgent() {
    return Boolean(this.#rawUser.is_agent);
  }

  get isArtist() {
    return Boolean(this.#rawUser.is_artist);
  }

  get isAffiliate() {
    return Boolean(this.#rawUser.is_affiliate);
  }

  get isVendor() {
    return Boolean(this.#rawUser.is_vendor);
  }

  get isCharity() {
    return Boolean(this.#rawUser.is_charity);
  }

  get isAgency() {
    return Boolean(this.#rawUser.is_agency);
  }

  get isConvention() {
    return Boolean(this.#rawUser.is_convention);
  }

  get isCustomer() {
    return Boolean(this.#rawUser.is_customer);
  }

  get isLegacy() {
    return Boolean(this.#rawUser.is_legacy);
  }

  get isSalesperson() {
    return Boolean(this.#rawUser.is_salesperson);
  }

  get all() {
    return this.#list;
  }

  get active() {
    return this.#list.filter((role) => role.active);
  }

  [Symbol.iterator]() {
    return this.#list[Symbol.iterator];
  }

  map(mapper) {
    return this.#list.map(mapper);
  }

  reduce(reducer, initialValue) {
    return this.#list.reduce(reducer, initialValue);
  }

  reduceRight(reducer, initialValue) {
    return this.#list.reduceRight(reducer, initialValue);
  }

  filter(predicate) {
    return this.#list.filter(predicate);
  }

  forEach(fn) {
    return this.#list.forEach(fn);
  }

  flatMap(mapper) {
    return this.#list.flatMap(mapper);
  }

  find(fn) {
    return this.#list.find(fn);
  }

  some(fn) {
    return this.#list.some(fn);
  }

  every(fn) {
    return this.#list.every(fn);
  }

  toJSON() {
    return {
      isAdmin: this.isAdmin,
      isSuperadmin: this.isSuperadmin,
      isTalent: this.isTalent,
      isTalentCoordinator: this.isTalentCoordinator,
      isAgent: this.isAgent,
      isArtist: this.isArtist,
      isVendor: this.isVendor,
      isCharity: this.isCharity,
      isAgency: this.isAgency,
      isConvention: this.isConvention,
      isCustomer: this.isCustomer,
      isLegacy: this.isLegacy,
      isSalesperson: this.isSalesperson,
      isAffiliate: this.isAffiliate,
    };
  }
}
