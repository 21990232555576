import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@material-ui/core/styles";
import { Influencer } from "../../../domain";
import { PreloadLink } from "../../../theme";

export default function SearchResultWrapper({ influencer, onClick, children }) {
  return (
    <StyledPreloadLink
      onClick={onClick}
      to={`/${influencer.route}`}
      endpoints={[
        influencer.endpoints.influencerByRoute,
        influencer.endpoints.influencerEvents,
      ]}
    >
      {children}
    </StyledPreloadLink>
  );
}

SearchResultWrapper.propTypes = {
  influencer: PropTypes.instanceOf(Influencer),
  onClick: PropTypes.func,
  children: PropTypes.node,
};

const StyledPreloadLink = styled(PreloadLink)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
  alignItems: "center",
  textDecoration: "none",
  "&:hover": {
    background: theme.palette.action.hover,
  },
  "&:active": {
    background: theme.palette.action.hover,
  },
}));
