import axios from "axios";
import { S, G } from "@mobily/ts-belt";
import moment from "moment-timezone";
import logger from "../util/logger";
import Endpoints from "./endpoints";
import InfluencerEvent from "./influencerEvent";
import Money from "./money";
import SocialHandles from "./socialHandles";
import SocialHandle from "./socialHandles/socialHandle";
import Tag from "./tag";

const endpoint = "/api/influencer";
const favouriteEndpoint = "/api/favourites/status";
const newExperienceGroup = "b";
const newsletterApi = "/api/newsletterEmail";

export default class Influencer {
  #rawInfluencer;
  #handles;
  #groupHandles;
  #products;
  #tags;
  #endpoints;
  #productTags;
  #nextNonLiveEvent;

  static INVITE_STATUS = Object.freeze(
    Object.seal({
      ACCEPTED: "accepted",
      AVAILABLE: "available",
      UNAVAILABLE: "unavailable",
      DECLINED: "declined",
      PENDING: "pending",
      ERROR: "error",
    }),
  );

  static getPreloadEndpoints(influencerLike) {
    const endpoints = [];

    if (
      G.isString(influencerLike?.route) &&
      S.isNotEmpty(influencerLike.route)
    ) {
      endpoints.push(`/api/influencer/byRoute/${influencerLike.route}`);
    }

    if (G.isNumber(influencerLike?.influencer_id)) {
      endpoints.push(`/api/influencerEvents/${influencerLike.influencer_id}`);
    }

    if (G.isNumber(influencerLike?.influencerId)) {
      endpoints.push(`/api/influencerEvents/${influencerLike.influencerId}`);
    }

    return endpoints.length ? endpoints : null;
  }

  /*
   * from creates a new Influencer from a rawInfluencer
   *
   * @param {object} rawInfluencer
   * @returns {Influencer}
   */
  static from(rawInfluencer) {
    return new Influencer(rawInfluencer);
  }

  /*
   * update updates an Influencer with an updates object
   *
   * @param {object} initialization object { influencer, updates }
   * @returns {object} result
   */
  static async update({ influencer, updates }) {
    try {
      const { data } = await axios.patch(
        `${endpoint}/${influencer.influencerId}`,
        updates,
      );

      return data;
    } catch (err) {
      logger.error(err);

      if (err.isAxiosError) {
        return err.response.data;
      }

      return { errors: [{ message: "Unable to update influencer" }] };
    }
  }

  /*
   * updateFavouriteStatus updates an Influencer's favourite status for the current
   * user
   *
   * @param {object} initialization object { influencer, isFavourite }
   * @returns {object | null} result
   */
  static async updateFavouriteStatus({ influencer, isFavourite }) {
    try {
      const { data } = await axios.post(favouriteEndpoint, {
        influencer_id: influencer.influencerId,
        is_favourite: isFavourite,
      });

      return data;
    } catch (err) {
      logger.error(err);
      return null;
    }
  }

  /*
   * subscribe Newsletter subcribes  an Influencer's newletter for the particular email
   * user
   *
   * @param {object} initialization object { influencerId, email }
   * @returns {object | null} result
   */
  static async subscribeNewsletter({ influencerId, email }) {
    try {
      const { data } = await axios.post(newsletterApi, {
        influencer_id: influencerId,
        email_address: email,
      });

      return data;
    } catch (err) {
      logger.error(err);
      return null;
    }
  }

  static async favourite({ influencer }) {
    return Influencer.updateFavouriteStatus({ influencer, isFavourite: true });
  }

  static async unfavourite({ influencer }) {
    return Influencer.updateFavouriteStatus({ influencer, isFavourite: false });
  }

  static async subscribeNewsLetter({ influencerId, email }) {
    return Influencer.subscribeNewsletter({ influencerId, email });
  }

  /*
   * creates a new Influencer from a rawInfluencer
   *
   * @param {object} rawInfluencer
   * @returns {Influencer}
   */
  constructor(rawInfluencer) {
    const rawInfluencerCopy = { ...rawInfluencer };

    this.#rawInfluencer = rawInfluencerCopy;
    this.#handles = SocialHandles.for(rawInfluencerCopy);
    this.#groupHandles = (rawInfluencerCopy.social_handles ?? []).map(
      SocialHandle.for,
    );
    this.#tags = (rawInfluencerCopy.tags ?? []).map(Tag.from);
    this.#productTags = rawInfluencerCopy.productTags ?? [];
    this.#endpoints = Endpoints.for(this);

    if (rawInfluencerCopy.nextNonLiveEvent) {
      this.#nextNonLiveEvent = InfluencerEvent.from(
        rawInfluencerCopy.nextNonLiveEvent,
      );
    }
  }

  get endpoints() {
    return this.#endpoints;
  }

  get influencerId() {
    return this.#rawInfluencer.influencer_id;
  }

  get userId() {
    return this.#rawInfluencer.user_id;
  }

  get name() {
    return this.#rawInfluencer.name;
  }

  get caption() {
    return this.#rawInfluencer.caption;
  }

  get completionDeadline() {
    return this.#rawInfluencer.completion_deadline;
  }

  get fulfillmentSpeed() {
    return this.#rawInfluencer.fulfillment_speed;
  }

  get route() {
    return this.#rawInfluencer.route;
  }

  get fullRoute() {
    return `${window.origin}/${this.route}`;
  }

  get handles() {
    return this.#handles;
  }

  get groupHandles() {
    return this.#groupHandles;
  }

  get tags() {
    return this.#tags;
  }

  get seo() {
    return this.#rawInfluencer.seo;
  }

  get metaTag() {
    return this.#rawInfluencer.meta_tag;
  }

  get events() {
    return this.#rawInfluencer.events;
  }

  get productTags() {
    return this.#productTags;
  }

  get eventsEventTime() {
    if (this.#rawInfluencer.events?.length) {
      return new Date(this.#rawInfluencer.events[0].next_event_time);
    }

    return void 0;
  }

  get eventTime() {
    if (this.#rawInfluencer.event_time) {
      return new Date(this.#rawInfluencer.event_time);
    }

    return void 0;
  }

  get eventTimeOverride() {
    if (this.#rawInfluencer.event_time_override) {
      return new Date(this.#rawInfluencer.event_time_override);
    }

    return void 0;
  }

  get position() {
    return this.#rawInfluencer.position;
  }

  get famousFor() {
    return this.#rawInfluencer.famous_for;
  }

  get famousCharacter() {
    return this.#rawInfluencer.famous_character;
  }

  get marketingPhrase() {
    return this.#rawInfluencer.marketing_phrase;
  }

  get showChatLink() {
    return Boolean(this.#rawInfluencer.show_chat_link);
  }

  get showEvents() {
    return Boolean(this.#rawInfluencer.show_events);
  }

  get showMarketingImage() {
    return Boolean(this.#rawInfluencer.show_marketing_image);
  }

  get showArtists() {
    return Boolean(this.#rawInfluencer.show_artists);
  }

  get showInPersonProducts() {
    return Boolean(this.#rawInfluencer.show_in_person_products);
  }

  get productsSubtext() {
    return this.#rawInfluencer.products_subtext;
  }

  get liveEmbedEnabled() {
    return this.#rawInfluencer.live_embed_enabled;
  }

  get avatarUrl() {
    return this.#rawInfluencer.avatar_url;
  }

  get videoAvatarUrl() {
    if (!this.hasVideoAvatar) {
      return null;
    }

    if (this.#rawInfluencer.video_avatar_url) {
      return this.#rawInfluencer.video_avatar_url;
    }

    return `https://d3qdxs9s8vjwda.cloudfront.net/influencerVideo/${this.influencerId}.mov`;
  }

  get avatarSourceUrl() {
    return this.#rawInfluencer.avatar_source_url;
  }

  get referralCode() {
    return this.#rawInfluencer.referral_code;
  }

  get referralCodeId() {
    return this.#rawInfluencer.referral_code_id;
  }

  get openedAt() {
    if (this.#rawInfluencer.opened_at) {
      return new Date(this.#rawInfluencer.opened_at);
    }

    return void 0;
  }

  get closedAt() {
    if (this.#rawInfluencer.closed_at) {
      return new Date(this.#rawInfluencer.closed_at);
    }

    return void 0;
  }

  get hasVideoAvatar() {
    return Boolean(this.#rawInfluencer.has_video_avatar);
  }

  get productTagString() {
    return this.#rawInfluencer.product_tag_string;
  }

  /*
   * serviceFeeBump returns the Money for an influencer's serviceFeeBump
   *
   * @returns {Money}
   */
  get serviceFeeBump() {
    return Money.for({
      value: this.#rawInfluencer.service_fee_bump / 100,
      currency: "usd",
    });
  }

  get platformFeeBump() {
    return Money.for({
      value: this.#rawInfluencer.platform_fee_bump / 100,
      currency: "usd",
    });
  }

  get created() {
    if (this.#rawInfluencer.created) {
      return new Date(this.#rawInfluencer.created);
    }

    return void 0;
  }

  get updated() {
    if (this.#rawInfluencer.updated) {
      return new Date(this.#rawInfluencer.updated);
    }

    return void 0;
  }

  get nextEventTime() {
    if (this.#rawInfluencer.next_event_time) {
      return new Date(this.#rawInfluencer.next_event_time);
    }

    return void 0;
  }

  get eventStartDate() {
    if (this.#rawInfluencer.event_start_date && this.#rawInfluencer.time_zone) {
      return moment(this.#rawInfluencer.event_start_date).tz(
        this.#rawInfluencer.time_zone,
      );
    }

    return void 0;
  }

  get eventEndDate() {
    if (this.#rawInfluencer.event_end_date && this.#rawInfluencer.time_zone) {
      return moment(this.#rawInfluencer.event_end_date).tz(
        this.#rawInfluencer.time_zone,
      );
    }

    return void 0;
  }

  get eventTimezone() {
    return this.#rawInfluencer.time_zone;
  }

  get dateType() {
    return this.#rawInfluencer.date_type;
  }

  get nextNonLiveEvent() {
    return this.#nextNonLiveEvent ?? null;
  }

  get liveStarted() {
    if (this.#rawInfluencer.live_started) {
      return new Date(this.#rawInfluencer.live_started);
    }

    return void 0;
  }

  get livePaused() {
    if (this.#rawInfluencer.live_paused) {
      return new Date(this.#rawInfluencer.live_paused);
    }

    return void 0;
  }

  get notLiveTimestamp() {
    if (this.#rawInfluencer.not_live_timestamp) {
      return new Date(this.#rawInfluencer.not_live_timestamp);
    }

    return void 0;
  }

  get allowVideoAddon() {
    return Boolean(this.#rawInfluencer.allow_video_recording_addon);
  }

  get allowFrameAddon() {
    return Boolean(this.#rawInfluencer.allow_frame_addon);
  }

  get allowVideoMessageAddon() {
    return Boolean(this.#rawInfluencer.allow_video_message_addon);
  }

  get linkedInfluencerRoute() {
    return this.#rawInfluencer.linked_influencer_route;
  }

  get linkedInfluencerName() {
    return this.#rawInfluencer.linked_influencer_name;
  }

  get ownerEmail() {
    return this.#rawInfluencer.owner_email;
  }

  get tcEmail() {
    return this.#rawInfluencer.tcEmail;
  }

  get tcFirstName() {
    return this.#rawInfluencer.tcFirstName;
  }

  get tcLastName() {
    return this.#rawInfluencer.tcLastName;
  }

  get hasProducts() {
    return (
      Array.isArray(this.#rawInfluencer.products) &&
      this.#rawInfluencer.products.length > 0
    );
  }

  get products() {
    return this.#products;
  }

  get isActive() {
    return Boolean(this.#rawInfluencer.active);
  }

  get isOpen() {
    return !this.#rawInfluencer.sold_out;
  }

  get isClosed() {
    return Boolean(this.#rawInfluencer.sold_out);
  }

  get isHidden() {
    return Boolean(this.#rawInfluencer.hidden);
  }

  get isFeatured() {
    return Boolean(this.#rawInfluencer.featured);
  }

  get isLive() {
    return Boolean(this.#rawInfluencer.is_live);
  }

  get hasNoShopEditor() {
    return Boolean(this.#rawInfluencer.no_shop_editor);
  }

  get isNmt() {
    return Boolean(this.#rawInfluencer.is_nmt);
  }

  get isBlankShop() {
    return Boolean(this.#rawInfluencer.blank_shop);
  }

  get isSearchable() {
    return !this.#rawInfluencer.not_searchable;
  }

  get isOnHomepage() {
    return Boolean(this.#rawInfluencer.on_homepage);
  }

  get isShoutoutShop() {
    return Boolean(this.#rawInfluencer.shoutout_shop);
  }

  get isNonLiveShop() {
    return Boolean(this.#rawInfluencer.nonlive_shop);
  }

  get isGroupShop() {
    return Boolean(this.#rawInfluencer.group_shop);
  }

  get isAgentShop() {
    return Boolean(this.#rawInfluencer.is_agent);
  }

  get isTcShop() {
    return Boolean(this.#rawInfluencer.is_talent_coordinator);
  }

  get isStripePayout() {
    return Boolean(this.#rawInfluencer.is_stripe_payout);
  }

  get isStarted() {
    return Boolean(this.#rawInfluencer.is_started);
  }

  get isPaused() {
    return Boolean(this.#rawInfluencer.is_paused);
  }

  get isStopped() {
    return Boolean(this.#rawInfluencer.is_stopped);
  }

  get isFavourite() {
    return Boolean(this.#rawInfluencer.is_favourite);
  }

  get shouldHaveNewExperience() {
    return (
      !this.#rawInfluencer.testing_group ||
      (this.#rawInfluencer.testing_group &&
        this.#rawInfluencer.testing_group === newExperienceGroup)
    );
  }

  get hasShippingAddress() {
    return Boolean(this.#rawInfluencer.shippingAddress);
  }

  get shippingAddress() {
    return this.#rawInfluencer.shippingAddress;
  }

  get isDomestic() {
    return Boolean(this.#rawInfluencer.isDomestic);
  }

  get agents() {
    return this.#rawInfluencer.agents ?? [];
  }

  get hasAgents() {
    return this.agents.length > 0;
  }

  get showPriceDisclaimer() {
    return Boolean(this.#rawInfluencer.show_price_disclaimer);
  }

  /*
   * mutate returns a new Influencer with key set to value
   *
   * @param {string} key
   * @param {any} value
   *
   * @returns {Influencer}
   */
  mutate(key, value) {
    const updatedRawInfluencer = { ...this.#rawInfluencer, [key]: value };
    return new Influencer(updatedRawInfluencer);
  }

  /*
   * mutateRaw returns the rawInfluencer with key set to value
   *
   * @param {string} key
   * @param {any} value
   *
   * @returns {object} rawInfluencer
   */
  mutateRaw(key, value) {
    return { ...this.#rawInfluencer, [key]: value };
  }

  mutateRawValues(mapOfValues = {}) {
    return { ...this.#rawInfluencer, ...mapOfValues };
  }

  raw() {
    return { ...this.#rawInfluencer };
  }

  /*
   * setProducts sets an influencers products with the provided class constructor
   *
   * @param {Product}
   * @param {function} withIncludesProduct
   *
   * @returns {Influencer}
   */
  setProducts(Product, withIncludesProduct) {
    this.#products = this.#rawInfluencer.products.map((rawProduct) => {
      const product = Product.from(rawProduct);

      if (withIncludesProduct && typeof withIncludesProduct === "function") {
        return withIncludesProduct(product);
      }

      return product;
    });

    return this;
  }

  favourite() {
    return Influencer.favourite({ influencer: this });
  }

  unfavourite() {
    return Influencer.unfavourite({ influencer: this });
  }

  toggleFavouriteStatus() {
    return Influencer.updateFavouriteStatus({
      influencer: this,
      isFavourite: !this.isFavourite,
    });
  }

  toJSON() {
    return {
      influencerId: this.influencerId,
      products: this.products?.map((product) => product?.toJSON()),
      handles: this.handles?.toJSON(),
      name: this.name,
      caption: this.caption,
      route: this.route,
      eventTime: this.eventTime,
      eventTimeOverride: this.eventTimeOverride,
      position: this.position,
      famousFor: this.famousFor,
      famousCharacter: this.famousCharacter,
      marketingPhrase: this.marketingPhrase,
      showChatLink: this.showChatLink,
      showMarketingImage: this.showMarketingImage,
      showArtists: this.showArtists,
      showInPersonProducts: this.showInPersonProducts,
      showEvents: this.showEvents,
      productsSubtext: this.productsSubtext,
      liveEmbedEnabled: this.liveEmbedEnabled,
      avatarUrl: this.avatarUrl,
      avatarSourceUrl: this.avatarSourceUrl,
      referralCode: this.referralCode,
      referralCodeId: this.referralCodeId,
      openedAt: this.openedAt,
      closedAt: this.closedAt,
      hasVideoAvatar: this.hasVideoAvatar,
      productTagString: this.productTagString,
      serviceFeeBump: this.serviceFeeBump.toJSON(),
      created: this.created,
      updated: this.updated,
      nextEventTime: this.nextEventTime,
      eventStartDate: this.eventStartDate,
      eventEndDate: this.eventEndDate,
      eventTimezone: this.eventTimezone,
      nextNonLiveEvent: this.nextNonLiveEvent?.toJSON(),
      liveStarted: this.liveStarted,
      livePaused: this.livePaused,
      notLiveTimestamp: this.notLiveTimestamp,
      allowVideoAddon: this.allowVideoAddon,
      allowFrameAddon: this.allowFrameAddon,
      allowVideoMessageAddon: this.allowVideoMessageAddon,
      linkedInfluencerRouter: this.linkedInfluencerRoute,
      linkedInfluencerName: this.linkedInfluencerName,
      isActive: this.isActive,
      isOpen: this.isOpen,
      isClosed: this.isClosed,
      isHidden: this.isHidden,
      isFeatured: this.isFeatured,
      isBlankShop: this.isBlankShop,
      isSearchable: this.isSearchable,
      isOnHomepage: this.isOnHomepage,
      isShoutoutShop: this.isShoutoutShop,
      isStarted: this.isStarted,
      isPaused: this.isPaused,
      isStopped: this.isStopped,
      isStripePayout: this.isStripePayout,
      isNonLiveShop: this.isNonLiveShop,
      isGroupShop: this.isGroupShop,
      isLive: this.isLive,
      isFavourite: this.isFavourite,
      shouldHaveNewExperience: this.shouldHaveNewExperience,
      isDomestic: this.isDomestic,
      shippingAddress: this.shippingAddress,
    };
  }
}
