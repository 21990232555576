import { styled } from "@material-ui/core/styles";
import { F, D } from "@mobily/ts-belt";
import PropTypes from "prop-types";
import * as React from "react";
import { TinyAvatar } from "../../theme";

const StyledButton = styled("button")(({ theme }) => ({
  alignItems: "center",
  background: theme.palette.grey[200],
  border: "none",
  cursor: "pointer",
  display: "flex",
  margin: 0,
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
  textAlign: "left",
  gap: theme.spacing(1),
  transition: "background 0.2s ease-in-out",
  "& span": {
    ...theme.typography.body2,
    fontWeight: 600,
  },
  "&:hover": {
    background: theme.palette.grey[300],
  },
  "&.active": {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    "& span": {
      color: theme.palette.common.white,
      fontWeight: 600,
    },
  },
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1),
    minWidth: "fit-content",
  },
}));

const StyledBox = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: theme.spacing(2),
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    flexWrap: "nowrap",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1),
    overflowX: "auto",
  },
}));

export default function SignerSelect({ initialState, onChange, options }) {
  const [selectedSigners, setSelectedSigners] = React.useState(initialState);

  React.useEffect(() => {
    if (!F.equals(selectedSigners, initialState)) {
      onChange(selectedSigners);
    }
  }, [selectedSigners, onChange, initialState]);

  if (!options || Object.keys(options).length < 2) {
    return null;
  }

  return (
    <StyledBox>
      {Object.values(options).map((option) => (
        <StyledButton
          className={selectedSigners[option.influencerId] ? "active" : ""}
          onClick={() => {
            setSelectedSigners((signers) => {
              if (signers[option.influencerId]) {
                return D.deleteKey(signers, option.influencerId);
              }
              return D.merge(signers, { [option.influencerId]: true });
            });
          }}
          key={option.influencerId}
        >
          <TinyAvatar alt={option.name} src={option.avatarUrl} />
          <span>{option.name}</span>
        </StyledButton>
      ))}
    </StyledBox>
  );
}

SignerSelect.propTypes = {
  initialState: PropTypes.object,
  onChange: PropTypes.func,
  options: PropTypes.object,
};
