import { Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { Influencer, Product } from "../../../../domain";
import { InfoTooltip } from "../../../../theme";
import { useProductModal } from "./state";

const ProductModalHeaderPrice = React.memo(function ProductModalHeaderPrice({
  influencer,
}) {
  const [state] = useProductModal();

  return (
    <StyledProductName variant="h6">
      {state.price.format()}
      {Boolean(influencer) && Boolean(influencer.showPriceDisclaimer) && (
        <InfoTooltip content="Price includes platform fee" />
      )}
    </StyledProductName>
  );
});

ProductModalHeaderPrice.propTypes = {
  product: PropTypes.instanceOf(Product),
  influencer: PropTypes.instanceOf(Influencer),
};

const StyledProductName = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.interFontStack,
  fontWeight: theme.typography.semiBold,
  margin: 0,
  lineHeight: 1,
}));

export default ProductModalHeaderPrice;
