import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { N } from "@mobily/ts-belt";
import * as React from "react";
import { useLocation } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import { autoPlay, virtualize } from "react-swipeable-views-utils";
import useSWR from "swr";
import { fetcher } from "../hooks";

const AutoPlaySwipeableViews = autoPlay(virtualize(SwipeableViews));

function useSiteNotices() {
  const { data, error } = useSWR("/api/siteNotice", fetcher);

  const { notices, config } = React.useMemo(() => {
    if (!data || !data?.notices || !data?.config) {
      return {
        notices: [],
        config: {
          defaultInterval: 6 * 1000,
          longInterval: 60 * 1000,
          backgroundColor: "#e8e8e8",
          color: "#2b2b2b",
        },
      };
    }

    return data;
  }, [data]);

  return { notices, config, isLoading: !error && !data, isError: error };
}

export default function SiteNotice() {
  const location = useLocation();
  const { notices, config } = useSiteNotices();

  const noticesToDisplay = React.useMemo(() => {
    const pathname = location?.pathname?.toLowerCase();

    return notices.filter(({ excludedRoutes }) => {
      if (!excludedRoutes.length) {
        return true;
      }

      if (pathname === "/") {
        return excludedRoutes.every((route) => route !== "/");
      }

      return !excludedRoutes
        .concat("/checkout")
        .filter((route) => route !== "/")
        .some((route) => pathname.startsWith(route));
    });
  }, [notices, location.pathname]);

  const [activeStep, setActiveStep] = React.useState(0);
  const [interval, setInterval] = React.useState(8 * 1000);

  React.useEffect(() => {
    if (noticesToDisplay.length) {
      setActiveStep(0);
    }
  }, [location.pathname, noticesToDisplay]);

  if (!noticesToDisplay.length) {
    return null;
  }

  const maxSteps = noticesToDisplay.length;

  return (
    <div
      style={{ color: config.color, backgroundColor: config.backgroundColor }}
      className="site-notice"
      onPointerEnter={() => setInterval(config.longInterval)}
      onPointerLeave={() => setInterval(config.defaultInterval)}
    >
      <div
        className="site-notice__main"
        style={{ color: config.color, backgroundColor: config.backgroundColor }}
      >
        {maxSteps > 1 && (
          <button
            className="site-notice__button"
            title="Back"
            onClick={() => {
              setActiveStep(N.subtract(1));
            }}
          >
            <KeyboardArrowLeft fontSize="small" />
          </button>
        )}

        <AutoPlaySwipeableViews
          className="site-notice__swipe"
          interval={interval}
          axis="x"
          index={activeStep}
          onChangeIndex={(step) => {
            setActiveStep(step);
          }}
          enableMouseEvents
          slideRenderer={({ index, key }) => {
            const notice = noticesToDisplay[index % maxSteps];
            return (
              <div
                style={{
                  color: config.color,
                  backgroundColor: config.backgroundColor,
                }}
                className="site-notice__content"
                key={key}
                dangerouslySetInnerHTML={{ __html: notice?.content }}
              />
            );
          }}
        ></AutoPlaySwipeableViews>

        {maxSteps > 1 && (
          <button
            className="site-notice__button"
            title="Next"
            size="small"
            onClick={() => {
              setActiveStep(N.add(1));
            }}
          >
            <KeyboardArrowRight fontSize="small" />
          </button>
        )}
      </div>
    </div>
  );
}
