import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import { D } from "@mobily/ts-belt";
import PropTypes from "prop-types";
import { useShops } from "./utils";
import { PreloadLink } from "../../theme";
import { Influencer } from "../../domain";

function textFor({ famousFor, famousCharacter }) {
  if (famousFor && famousCharacter) {
    return `${famousCharacter} / ${famousFor}`;
  }

  if (famousFor) {
    return famousFor;
  }

  if (famousCharacter) {
    return famousCharacter;
  }

  return "";
}

function resultsTextFor({ filter, pagination }) {
  if (filter.name) {
    const resultText = pagination.totalResults === 1 ? "result" : "results";

    return pagination.hasQueryMatches
      ? `Showing ${pagination.totalResults} ${resultText} for "${filter.name}"`
      : `No matches for "${filter.name}". Showing all shops instead.`;
  }

  const shopText = pagination.totalResults === 1 ? "shop" : "shops";

  return `${pagination.totalResults} ${shopText}`;
}

export default function Shops({ filter, setFilter }) {
  const { shops, pagination, isValidating } = useShops(filter);

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-end"
        gridGap="8px"
        mb={4}
      >
        <Box display="flex" alignItems="center" gridGap="8px">
          <Typography variant="h6">Shops</Typography>

          {isValidating && <CircularProgress size={20} color="inherit" />}
        </Box>

        <Typography variant="caption" color="textSecondary">
          {resultsTextFor({ filter, pagination })}
        </Typography>
      </Box>

      <Grid container spacing={4} style={{ opacity: isValidating ? 0.7 : 1 }}>
        {shops.map((shop) => (
          <Grid xs={6} sm={4} md={4} lg={3} item key={shop.influencerId}>
            <PreloadLink
              style={{ position: "relative" }}
              className="preload-link"
              to={`/${shop.route}`}
              endpoints={Influencer.getPreloadEndpoints(shop)}
            >
              {shop?.isLive ? (
                <span className="shop-card__live-indicator">LIVE</span>
              ) : null}

              <div className="shop-card__image-container">
                <img
                  height="216"
                  width="162"
                  alt={shop.name}
                  className="shop-card__image"
                  src={shop.avatarUrl}
                  loading="lazy"
                />

                <p className="shop-card__name">{shop.name}</p>
              </div>

              <p className="shop-card__text">{textFor(shop)}</p>
            </PreloadLink>
          </Grid>
        ))}
      </Grid>

      {shops.length === 0 && (
        <Box mt={4}>
          <Typography variant="body2" color="textSecondary">
            No shops found
          </Typography>
        </Box>
      )}

      {shops.length > 0 && (
        <Box
          alignItems="center"
          display="flex"
          flex="1"
          gridGap="8px"
          justifyContent="center"
          mt={4}
        >
          <Button
            disabled={!pagination?.hasPrevious}
            onClick={() => {
              setFilter(D.merge({ rank: pagination.previousPageId }));
            }}
          >
            Previous Page
          </Button>

          <Button
            disabled={!pagination?.hasNext}
            onClick={() => {
              setFilter(D.merge({ rank: pagination.nextPageId }));
            }}
          >
            Next Page
          </Button>
        </Box>
      )}
    </Box>
  );
}

Shops.propTypes = {
  matchesSmDown: PropTypes.bool.isRequired,
  filter: PropTypes.shape({
    name: PropTypes.string,
    rank: PropTypes.number,
  }),
  setFilter: PropTypes.func.isRequired,
};
