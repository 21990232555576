import { Box, Button, Typography } from "@material-ui/core";
import { D, G } from "@mobily/ts-belt";
import PropTypes from "prop-types";
import * as React from "react";
import { useNotification, useAgents } from "../../hooks";
import { StyledDialog } from "../../theme";
import Agents from "./Agents";
import AgentsSearch from "./AgentsSearch";
import { modalContext } from "./state";

function assertIsValid(amount) {
  if (
    !G.isNumber(amount) ||
    Number.isNaN(amount) ||
    amount <= 0 ||
    amount >= 100_000
  ) {
    throw new Error("Amount must be greater than $0 and less than $1,000");
  }
}

function ShopAgentsModal({ open, onClose, influencerId }) {
  const { isError, isLoading, refresh, agents } = useAgents(influencerId);
  const [isOpen, setIsOpen] = React.useState(open);
  const [user, setUser] = React.useState(null);
  const [changes, setChanges] = React.useState({});
  const [errorMessage, setErrorMessage] = React.useState("");
  const notify = useNotification();

  const addAgent = React.useCallback(
    async (newAgent) => {
      try {
        assertIsValid(newAgent.splitAmount);

        if (agents.find((agent) => agent.userId === newAgent.userId)) {
          throw new Error("Agent already exists");
        }

        const response = await window.fetch(
          `/api/influencerAgents/${influencerId}`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ agent: newAgent }),
          },
        );

        if (!response.ok) {
          const json = await response.json();
          const { message } = json;

          if (message) {
            setErrorMessage(message);
            return;
          }

          throw new Error("Failed to add agent");
        }

        refresh();
        return true;
      } catch (err) {
        setErrorMessage(err.message);
        return false;
      }
    },
    [agents, influencerId, refresh],
  );

  const updateAgent = React.useCallback(
    async (newAgent) => {
      try {
        assertIsValid(newAgent.splitAmount);

        const { userId: agentId } = newAgent;
        const response = await window.fetch(
          `/api/influencerAgents/${influencerId}/${agentId}`,
          {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ agent: newAgent }),
          },
        );

        if (!response.ok) {
          throw new Error("Failed to update agent");
        }

        refresh();

        return true;
      } catch (err) {
        setErrorMessage(err.message);
        return false;
      }
    },
    [influencerId, refresh],
  );

  const removeAgent = React.useCallback(
    async (agentId) => {
      try {
        const response = await window.fetch(
          `/api/influencerAgents/${influencerId}/${agentId}`,
          { method: "DELETE", headers: { "Content-Type": "application/json" } },
        );

        if (!response.ok) {
          throw new Error("Failed to remove agent");
        }

        refresh();

        return true;
      } catch (err) {
        setErrorMessage(err.message);
        return false;
      }
    },
    [influencerId, refresh],
  );

  const value = React.useMemo(
    () => [changes, setChanges],
    [changes, setChanges],
  );

  return (
    <modalContext.Provider value={value}>
      <StyledDialog
        open={isOpen}
        onClose={() => {
          if (D.isNotEmpty(changes)) {
            return notify({
              message:
                "You have unsaved changes. Please save your changes to close the modal",
            });
          }

          setIsOpen(false);
        }}
        TransitionProps={{
          onExited: () => {
            if (!isOpen) {
              onClose();
            }
          },
        }}
      >
        <Typography variant="h5" gutterBottom>
          Agents
        </Typography>

        <Box py={2} mb={8}>
          <Agents
            agents={agents}
            isError={isError}
            removeAgent={removeAgent}
            updateAgent={updateAgent}
          />
        </Box>

        <AgentsSearch
          addAgent={addAgent}
          agents={agents}
          errorMessage={errorMessage}
          isError={isError}
          isLoading={isLoading}
          setErrorMessage={setErrorMessage}
          setUser={setUser}
          user={user}
        />

        {D.isNotEmpty(changes) && <Typography>Unsaved Changes</Typography>}

        <Typography
          color="textSecondary"
          component="p"
          gutterBottom
          variant="caption"
        >
          Agents get added to the shop access list as users.
        </Typography>

        <Typography
          color="textSecondary"
          component="p"
          gutterBottom
          variant="caption"
        >
          If they are removed from here, they will also be removed from the
          access list and from any of the shop&apos;s product splits that have
          not be manually edited. Any existing payouts will not be removed.
        </Typography>

        <Typography
          color="textSecondary"
          component="p"
          gutterBottom
          variant="caption"
        >
          If they are removed from the access list, they will not be removed
          from any product splits or as agents unless they are removed from the
          agent list here as well.
        </Typography>
      </StyledDialog>
    </modalContext.Provider>
  );
}

ShopAgentsModal.propTypes = {
  influencerId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

export default function ShopAgents({ influencerId }) {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
        }}
        variant="contained"
        color="secondary"
      >
        Edit Agents
      </Button>

      {open && (
        <ShopAgentsModal
          influencerId={influencerId}
          open
          onClose={() => {
            setOpen(false);
          }}
        />
      )}
    </>
  );
}

ShopAgents.propTypes = {
  influencerId: PropTypes.number.isRequired,
};
