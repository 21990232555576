import { Box, Button, Collapse, Typography } from "@material-ui/core";
import { G, D, F } from "@mobily/ts-belt";
import PropTypes from "prop-types";
import * as React from "react";
import Filter from "./Filter";
import Categories from "./Categories";
import { defaultFilter, useFilters } from "./utils";

const getName = D.get("name");
const getLabel = D.get("label");
const getValue = D.get("value");
const getFranchiseTagString = D.get("franchiseTagString");
const getCharacterTagString = D.get("characterTagString");

export default function Filters({
  showFilters,
  matchesSmDown,
  filter,
  setFilter,
}) {
  const { filters } = useFilters();

  const setFilterAndRestoreRank = React.useCallback(
    (fn) => setFilter((filter) => D.merge(fn(filter), { rank: 0 })),
    [setFilter],
  );

  React.useEffect(() => {
    if (D.isEmpty(filter.category)) {
      return;
    }

    const entries = Object.entries(filter.category);

    if (entries.length !== 1) {
      return;
    }

    const [categoryId, value] = entries[0];

    if (!G.isObject(value) || D.isNotEmpty(value)) {
      return;
    }

    if (D.isEmpty(filters.category)) {
      return;
    }

    const category = filters.category[categoryId];

    if (
      !G.isObject(category) ||
      D.isEmpty(category) ||
      !Array.isArray(category.subcategories) ||
      !category.subcategories.length
    ) {
      return;
    }

    setFilterAndRestoreRank(
      D.merge({
        category: {
          [categoryId]: category.subcategories.reduce((acc, value) => {
            acc[value.id] = true;
            return acc;
          }, {}),
        },
      }),
    );
  }, [filter, filters.category, setFilterAndRestoreRank]);

  return (
    <div>
      <Collapse in={showFilters}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Typography variant="h6">Filters</Typography>

          {!F.equals(filter, defaultFilter) && (
            <Button
              size="small"
              onClick={() => {
                setFilter(defaultFilter);
              }}
            >
              Clear
            </Button>
          )}
        </Box>

        {D.isNotEmpty(filters.category) && (
          <Categories
            expandedByDefault
            matchesSmDown={matchesSmDown}
            categories={filters.category}
            selected={filter.category}
            setSelected={(setter) => {
              const newValue = setter(filter.category);
              if (newValue) {
                setFilterAndRestoreRank(D.set("category", newValue));
              } else {
                setFilterAndRestoreRank(D.set("category", {}));
              }
            }}
          />
        )}

        {Boolean(filters.franchise.length) && (
          <Filter
            expandedByDefault
            matchesSmDown={matchesSmDown}
            options={filters.franchise}
            label="Franchises"
            getOptionLabel={getName}
            getOptionValue={getFranchiseTagString}
            selected={filter.franchise}
            setSelected={({ value, key }) => {
              if (value) {
                setFilterAndRestoreRank(
                  D.update("franchise", D.set(key, value)),
                );
              } else {
                setFilterAndRestoreRank(
                  D.update("franchise", D.deleteKey(key)),
                );
              }
            }}
          />
        )}

        {Boolean(filters.character.length) && (
          <Filter
            expandedByDefault
            matchesSmDown={matchesSmDown}
            options={filters.character}
            label="Characters"
            getOptionLabel={getName}
            getOptionValue={getCharacterTagString}
            selected={filter.character}
            setSelected={({ value, key }) => {
              if (value) {
                setFilterAndRestoreRank(
                  D.update("character", D.set(key, value)),
                );
              } else {
                setFilterAndRestoreRank(
                  D.update("character", D.deleteKey(key)),
                );
              }
            }}
          />
        )}

        {Boolean(filters.type.length) && (
          <Filter
            search={false}
            matchesSmDown={matchesSmDown}
            options={filters.type}
            label="Shop Types"
            getOptionLabel={getLabel}
            getOptionValue={getValue}
            selected={filter.type}
            setSelected={({ value, key }) => {
              if (value) {
                setFilterAndRestoreRank(D.update("type", D.set(key, value)));
              } else {
                setFilterAndRestoreRank(D.update("type", D.deleteKey(key)));
              }
            }}
          />
        )}
      </Collapse>
    </div>
  );
}

Filters.propTypes = {
  matchesSmDown: PropTypes.bool,
  showFilters: PropTypes.bool,
  filter: PropTypes.shape({
    franchise: PropTypes.object,
    character: PropTypes.object,
    tags: PropTypes.object,
    talents: PropTypes.object,
    signedType: PropTypes.object,
    type: PropTypes.object,
    minPrice: PropTypes.number,
    maxPrice: PropTypes.number,
    category: PropTypes.object,
  }),
  setFilter: PropTypes.func,
};
