import { Link, Typography } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import ContentPage from "../ContentPage";

export default function Talent() {
  return (
    <ContentPage title="Talent Terms of Service">
      <Typography variant="caption" color="textSecondary">
        Last Updated: June 14, 2021
      </Typography>

      <p>
        This Talent Terms of Service Agreement (“Terms”) governs your use as a
        talent user of the STREAMILY™ marketplace platform offered by Smashnet,
        Inc., doing business as Streamily (“we”, “us”, or “Streamily”),
        including our website (Streamily.com), and services we provide
        (collectively, the website, and services referred to as our “Site”).
        “you” and “Talent User” refer to you as a talent user of the Site.
      </p>

      <p>
        These Terms apply solely to Talent Users of our Site. Use of our Site is
        also subject to our current Acceptable Use Policy. Any use by you of our
        Site other than as a Talent User is governed by the{" "}
        <Link component={RouterLink} to="/terms-and-conditions">
          Site Terms of Service
        </Link>{" "}
        (“Site Terms”).
      </p>

      <p>
        <strong>PLEASE READ THESE TERMS CAREFULLY.</strong> By using our Site as
        a Talent User or otherwise indicating your acceptance (for example, by
        agreeing when creating or logging into your account, clicking “I Agree,”
        etc.), you represent and warrant that you have read, understand, and
        agree to be bound by these Terms. If you do not agree, do not access or
        use our Site as a Talent User.
      </p>

      <p>
        <strong>ARBITRATION NOTICE:</strong> Section 19 of these Terms contains
        provisions governing how claims that you and Streamily have against each
        other are resolved, including any claims that arose or were asserted
        prior to the “Last Updated” date at the top of these Terms. It contains
        an arbitration agreement that will, with limited exceptions, require
        disputes between you and Streamily to be submitted to binding and final
        arbitration. Unless you opt out of the arbitration agreement as
        described in Section 19: (1) you may pursue claims and seek relief
        against us only on an individual basis, not as a plaintiff or class
        member in any class or representative action or proceeding; and (2) you
        waive your right to seek relief in a court of law and to have a jury
        trial on your claims.
      </p>

      <h2>Table of Contents</h2>

      <ol>
        <li>
          <Link href="#section1">
            Participation in the Streamily Marketplace
          </Link>
        </li>
        <li>
          <Link href="#section2">Fees and Payment</Link>
        </li>
        <li>
          <Link href="#section3">Talent Referral Program</Link>
        </li>
        <li>
          <Link href="#section4">Talent Content</Link>
        </li>
        <li>
          <Link href="#section5">Ownership</Link>
        </li>
        <li>
          <Link href="#section6">Additional Terms</Link>
        </li>
        <li>
          <Link href="#section7">Eligibility</Link>
        </li>
        <li>
          <Link href="#section8">
            Copyright and Intellectual Property Policy
          </Link>
        </li>
        <li>
          <Link href="#section9">Privacy</Link>
        </li>
        <li>
          <Link href="#section10">Third Party Content and Interactions</Link>
        </li>
        <li>
          <Link href="#section11">Business Relationship with Streamily</Link>
        </li>
        <li>
          <Link href="#section12">Links</Link>
        </li>
        <li>
          <Link href="#section13">Changes to our Site</Link>
        </li>
        <li>
          <Link href="#section14">Termination and Reservation of Rights</Link>
        </li>
        <li>
          <Link href="#section15">
            Disclaimers and Limitations on our Liability
          </Link>
        </li>
        <li>
          <Link href="#section16">Indemnification</Link>
        </li>
        <li>
          <Link href="#section17">
            Arbitration Agreement and Waiver of Certain Rights
          </Link>
        </li>
        <li>
          <Link href="#section18">Other Provisions</Link>
        </li>
        <li>
          <Link href="#section19">Changes to these Terms</Link>
        </li>
      </ol>

      <h2 id="section1">1. Participation in the Streamily Marketplace</h2>

      <ol style={{ listStyleType: "lower-alpha" }}>
        <li>
          <p>
            <strong>Registration:</strong> In order to participate on our Site,
            you must register. By registering, you agree to provide true,
            accurate, current, and complete information about yourself as
            prompted by our registration form, as well as any other information
            reasonably requested by us (collectively, “Registration Data”), and
            maintain and promptly update the Registration Data to keep it true,
            accurate, current, and complete. If you do not do so or we
            reasonably believe that you have not done so, we have the right to
            suspend or terminate your Site account and your use of our Site. You
            agree not to create a Site account using a false identity or
            providing false information, on behalf of another person (except as
            outlined below for a parent or legal guardian and Organizations), or
            if you (and your parent or legal guardian, or an Organization, if
            applicable) have previously been removed or banned from our Site.
            You are responsible for maintaining the confidentiality of your Site
            account information, including your username and password. You are
            responsible for all activities that occur on or in connection with
            your Site account and you agree to notify us immediately of any
            unauthorized access or use of your Site account. You acknowledge and
            agree that we are not responsible or liable for any damages, losses,
            costs, expenses, or liabilities related to any unauthorized access
            to or use of your Site account.
          </p>
        </li>

        <ol style={{ listStyleType: "lower-roman" }}>
          <li>
            <strong>Talent User age 16 or older:</strong> Talent Users who are
            at least 16 years old may register directly. By registering, you
            represent and warrant that: (A) you are at least 16 years old (and,
            if between 16 and 18 years old, are registering with the supervision
            and with the consent of your parent or legal guardian, who also
            agrees to these Terms); (B) you are (and, if you are between 16 and
            18 years old, your parent or legal guardian is) of legal age to form
            a binding contract; (C) you are (and if you are between 16 and 18
            years old, your parent or legal guardian is) not barred from using
            our Site under the laws of the United States, your place of
            residence (and if you are between 16 and 18 years old, the place of
            residence of your parent or legal guardian), or any other applicable
            jurisdiction; and (D) you are (and if you are between 16 and 18
            years old, your parent or legal guardian is) responsible for
            complying with all applicable laws and regulations relating to
            Talent User’s participation on our Site and will fully indemnify the
            Streamily Parties (defined below) for any failure to do so.
          </li>
          <li>
            <strong>
              Parent or Legal Guardian of Talent User, under age 16:
            </strong>{" "}
            Talent Users who are under 16 years old, may register only through a
            parent or legal guardian who, by registering, represents and
            warrants that: (A) he or she is the parent or legal guardian of the
            Talent User and agrees to these Terms; (B) neither the parent or
            legal guardian nor the Talent User is barred from using our Site
            under the laws of the United States, the place of residence of the
            parent, legal guardian, or Talent User, or any other applicable
            jurisdiction; and (C) he or she is responsible for complying with
            all applicable laws and regulations relating to Talent User’s
            participation on our Site and will fully indemnify the Streamily
            Parties for any failure to do so.
          </li>
          <li>
            <strong>Organizations:</strong> A management company, manager,
            agency, agent, publicist, or other individual or organization (each,
            an “Organization”) may register a Talent User (“Affiliated Talent”).
            By registering, the Organization represents and warrants for itself
            and each Affiliated Talent that: (A) Organization is the authorized
            representative of the Affiliated Talent (and, if the Affiliated
            Talent is below the age of 18, is registering with any consent
            required of the Affiliated Talent’s parent or legal guardian as set
            forth in Section 1. a(i) and 1. a(ii)) and agrees to these Terms;
            (B) neither the Organization nor the Affiliated Talent is barred
            from using our Site under the laws of the United States, the place
            of residence of the Organization or any Affiliated Talent, or any
            other applicable jurisdiction; and (C) Organization is responsible
            for complying with all applicable laws and regulations relating to
            Affiliated Talent’s participation on our Site under these Terms and
            will fully indemnify the Streamily Parties for any failure to do so.
            To register Affiliated Talent as an Organization, please contact{" "}
            <Link href="mailto:partners@streamily.com">
              partners@streamily.com
            </Link>
            .
          </li>
        </ol>

        <li>
          <p>
            <strong>Promotional Materials:</strong> At no cost to Streamily, you
            will provide to us the following promotional materials (“Promotional
            Materials”) within 72 hours of beginning the talent on-boarding
            process on our Site: (i) if you would like us to promote your
            participation on our Site, three high resolution images of yourself;
            (ii) your Site profile bio; and (iii) a promotional video of
            approximately :15 in length to let your fans know that they can
            purchase merchandise from you on our Site. Please note that you will
            not be able to receive requests from Users until we receive your
            promotional materials. From time to time we may request additional
            Promotional Materials from you for Streamily’s use to promote you on
            or in connection with our Site or on any social media platform or
            third party website. Any other materials or photos of or concerning
            you that you approve for Streamily’s use will also be Promotional
            Materials under these Terms. For so long Talent User is registered
            on our Site as talent, you hereby grant us the right and license to
            use your name, likeness and promotional materials to promote you to
            our fanbase and potential fanbase.{" "}
          </p>
        </li>

        <li>
          <p>
            <strong>Live Engagement:</strong> Unless otherwise agreed in writing
            by Streamily, you agree to sign all Streamily VIP purchases live on
            a publicly accessible livestream platform of your choice.
          </p>
        </li>
      </ol>

      <h2 id="section2">2. Fees and Payment</h2>

      <ol style={{ listStyleType: "lower-alpha" }}>
        <li>
          <p>
            <strong>Services and Fulfillment Fees:</strong> You set your own
            price for each Autograph and any other offering you choose to make
            available through the Streamily marketplace platform. Streamily to
            provide all logistics and fulfillment of merchandise and offerings
            sold through Site unless otherwise specified in writing by
            Streamily.
          </p>
        </li>

        <li>
          <p>
            <strong>Fees:</strong> Subject to these Terms, we will pay you 60%
            of the price listed and actually received by Streamily (the
            &ldquo;Talent Share&rdquo;) for each product that you deliver to
            fulfill a User’s request. For products requiring Streamily to pay a
            licensing fee to a third party including, but not limited to,
            payments made to an artist, studio, agent, or manager (a
            &ldquo;Third Party Licensing Fee&rdquo;), such Third Party Licensing
            Fee(s) shall be subtracted from the Talent Share. The Talent Share
            excludes fees or add-ons including, but not limited to, shipping and
            handling, recurring memberships, fees due to a third party,
            certificates of authenticity, frames, video captures, or upgrades.{" "}
          </p>
        </li>

        <li>
          <p>
            <strong>Fees and Payment Representations and Warranties:</strong>{" "}
            You represent and warrant that: (i) Streamily product offerings are
            not, and are not intended to be, covered by any guild, union,
            collective bargaining, management, agency, or similar agreement and
            there will be no residual or any other type of payment due from
            Streamily to you, to any third party, guild, union, management, or
            agent, or pursuant to any collective bargaining agreement, in
            connection with any Streamily offering, payment from us to you, or
            our Site; (ii) Streamily is not responsible for, and will not make
            any deduction to payments made to you for any fees, commissions,
            costs, expenses, or payments of any kind to or with respect to any
            third party, including any manager, agent, attorney, representative,
            or service provider, in connection with any revenue earned by or
            payments made to you in connection with our Site; and (iii)
            Streamily is not responsible for any contributions, payments, taxes,
            or deductions for Social Security, retirement benefits, unemployment
            insurance, annuities, or pension or welfare fund payments required
            by law or any labor union, or any withholding or income taxes;
            provided that if so required by any applicable laws, rules or
            regulations of any taxing authority (including the United States
            Internal Revenue Service, the California Franchise Tax Board or
            other federal, state or local authority), you authorize Streamily to
            withhold suchy taxes as may be required to comply with applicable
            laws, rules and regulations.
          </p>
        </li>

        <li>
          <p>
            <strong>Payment:</strong> You agree to register with the third party
            payment provider selected by Streamily, which Streamily may change
            in its sole discretion. You may not use a payment provider other
            than the one selected by Streamily and Streamily shall not be
            required to accept payments from a payment provider not selected by
            Streamily. You will provide the payment provider any information
            required in order to receive payments via the payment provider,
            e.g., information about the bank account that you own at a regulated
            financial institution. Any payments due to you from Streamily will
            be made via the payment provider. If available, you may transfer
            funds from your Streamily account to your bank account by submitting
            a payment request. Your request will be processed by the payment
            provider. In addition, Streamily may, in its sole discretion,
            transfer funds from your Streamily account to your bank account
            especially in the case of account inactivity.{" "}
            <strong>
              If you do not provide the payment provider with all required
              information, you may not be able to receive the payments due to
              you. Streamily will not be responsible for any damages, delays,
              losses, costs, expenses, or liabilities arising out of or in
              connection with your inability to receive payments as a result of
              your failure to timely provide such information.
            </strong>{" "}
            You acknowledge and agree that Streamily does not operate, own, or
            control the payment provider; and your use of any payment provider
            is subject to the terms and privacy policies of that payment
            provider. You agree that we are not responsible for any delay,
            failure, damage, or liability caused by a payment provider, any
            other third party, a force majeure, or your failure to timely or
            properly set up an account with the payment provider or otherwise
            provide requested information for payment. Other than with respect
            any payment to or deduction by the application platform (as set
            forth in Section 4.b). Streamily will be responsible for fees,
            costs, and expenses incurred in connection with the payment provider
            selected by Streamily. Unless otherwise agreed by Streamily in
            writing, you acknowledge and agree that you are solely responsible
            for any other fees, costs, and expenses, including with respect to
            your bank account and foreign exchange fees. Notwithstanding
            anything to the contrary contained in these terms, in default of
            payment request, unclaimed property and payments are forfeited after
            twelve months, and if Streamily, in its sole discretion, believes
            that any fraud, money laundering, or other violation of law or
            regulation is taking place on or in connection with our Site, you
            acknowledge and agree that we may withhold, delay, or seek repayment
            of any payments we believe, in our sole discretion, are related to
            the violation.
          </p>
        </li>

        <li>
          <p>
            <strong>Currency:</strong> Payments via the payment provider are in
            U.S. dollars unless the payment provider permits you to choose
            another currency and you do so.
          </p>
        </li>

        <li>
          <p>
            <strong>Fundraising:</strong> If you identify any entity on your
            Streamily shop or elsewhere on our Site as a charitable organization
            or a recipient of any funds that you are raising (each, a
            “Charity”), you: (i) represent and warrant that you will comply with
            all applicable laws and regulations relating to that identification
            or the Charity, including making disclosures, registering, or
            entering into any agreement, such as a commercial co-venturer
            agreement; (ii) acknowledge and agree that we have the right in our
            sole discretion to reject your identification or the Charity; (iii)
            represent and warrant that the Charity is and will remain in good
            standing at all times the identification is used, that within 48
            hours of your receipt of our request, you will provide us with
            written evidence of the Charity’s good standing and charitable
            status in all applicable jurisdictions, and that you will promptly
            remove the identification if the Charity ceases to be in good
            standing; (iv) represent and warrant that you will be responsible
            for making any payment to the Charity (unless we expressly agree in
            writing in advance to do so on your behalf and in satisfaction of
            our payment obligations to you under these Terms); and (v) represent
            and warrant that you have all rights necessary to authorize use of
            the Charity’s name and logo in connection with (and on) our Site, in
            the identification, and in any social and other media. You further
            acknowledge and agree that we may add a statement to your booking
            page disclaiming a connection between Streamily and the Charity, as
            we determine in our sole discretion.
          </p>
        </li>
      </ol>

      <h2 id="section3">3. Talent Referral Program</h2>

      <p>
        We offer Talent Users and others who register on our Site as a talent
        referral source (“Streamily Partner”) the opportunity to provide their
        friends, families, or other personal contacts the unique referral code
        we provide you (“Code”) that a prospective talent user may use to apply
        to register as a talent user on our Site (“Referral Program”).{" "}
        <strong>
          To participate in the Referral Program, you must agree to the
          following Talent Referral Program Terms (“Referral Terms”), as well as
          the rest of these Terms:
        </strong>
      </p>

      <ul style={{ listStyleType: "lower-alpha" }}>
        <li>
          <p>
            Streamily reserves the right to suspend or terminate the Referral
            Program or your participation in the Referral Program at any time
            for any reason. Without limiting the foregoing, we reserve the right
            to disqualify anyone from participating in the Referral Program at
            any time at our sole discretion. A violation of the Referral Terms
            may also result in the forfeiture of all Referral Fees (defined
            below) you earned through the Referral Program. We reserve the right
            to review and investigate activities undertaken in connection with
            the Referral Program and suspend your Site account, modify the
            Referral Fee, or take other action in our sole discretion.
          </p>
        </li>
        <li>
          <p>
            Eligibility to participate in this Referral Program is limited to
            individuals who have registered an account on our Site as a Talent
            User or Streamily Partner. The Referral Program cannot be used for
            affiliate lead generation or other commercial purposes. Employees,
            officers, directors, contractors, agents, and representatives of
            Streamily may not participate in the Referral Program.
          </p>
        </li>
        <li>
          <p>
            For every new user whose application to register as a Talent User on
            our Site using your Code is accepted in writing by Streamily
            (“Referred Talent”), Streamily will pay you, for one year, beginning
            as of the date that the Referred Talent first created an account as
            a Talent User on our Site using your Code, 2% of the listed Fees
            actually received by Streamily, Referred Talent creates and delivers
            to fulfill a User’s request accepted through our Site during that
            one-year period (excluding deliverables for which no revenue was
            earned, and, at Streamily’s discretion, any merchandise which a
            promotion applies or for which a promo code was used) (“Referral
            Fee”). The Referral Fee is based on the list price but is paid from
            Streamily’s share of the revenue (as described Section 4.b) and will
            be paid as described in Section 4.d. Notwithstanding anything to the
            contrary contained in these Terms, you acknowledge and agree that if
            a new user does not use your Code when applying to register, has
            previously registered on our Site (with another code, under another
            name, or otherwise), has previously begun the on-boarding process
            for our Site, or if anyone else has received or is receiving a
            Referral Fee from us in connection with that user, no Referral Fee
            will be due to you. You further acknowledge and agree that each
            Referred Talent may have only one referring person or entity, and
            that we are not obligated to pay a Referral Fee to more than one
            referring person or entity with respect to any Referred Talent.
          </p>
        </li>
        <li>
          <p>
            This Referral Program may be used only for personal purposes and you
            may share your Code only with your personal connections. You may not
            advertise your Code or share it with anyone other than your friends,
            family, or other personal contacts (for example, you may not share
            it on a social media channel). We reserve the right to revoke or
            refuse to issue any Referral Fees for Referred Talent that we
            suspect were generated through improper channels or otherwise in
            violation of these Referral Terms.
          </p>
        </li>
        <li>
          <p>
            You represent and warrant that any messages you use to share your
            Code with your personal connections will: (i) be created and
            distributed in a personal manner; (ii) not be distributed in a bulk
            manner; and (iii) not be an unsolicited commercial email or a “spam”
            message under any applicable law or regulation. Any messages that do
            not meet these requirements are expressly prohibited and constitute
            grounds for immediate termination of your Site account and
            participation in this Referral Program. Registrations on our Site by
            a Talent User using a Code that was distributed through an
            unauthorized channel will not be valid and any Referral Fees issued
            in connection with such transactions may be revoked.
          </p>
        </li>
        <li>
          <p>
            We reserve the right to deactivate your Site account and any
            Referred Talent’s Site account, cancel all related Referral Fees,
            and seek repayment, if we determine, in our sole discretion, that
            either you or the Referred Talent: (i) has tampered with this
            Referral Program; (ii) has used (or attempted to use) this Referral
            Program in a fraudulent, abusive, unethical, unsportsmanlike, or
            otherwise questionable or suspicious manner; (iii) has breached any
            of these Referral Terms; or (iv) has violated any law or regulation
            or infringed or violated the rights of any third party. We also
            reserve the right to cancel this Referral Program or change these
            Referral Terms at any time and for any reason in our sole
            discretion.
          </p>
        </li>
        <li>
          <p>
            As of the effective date of these Terms and notwithstanding anything
            to the contrary herein, a Talent User may become an Affiliated
            Talent to an Organization, subject to Streamily’s prior approval,
            by: (i) confirming in writing, in the form provided by Streamily,
            that the Organization is its representative; and (ii) the
            Organization registering the Talent User as its Affiliated Talent.
            The Organization will be deemed a Streamily Partner and the
            Affiliated Talent will be deemed a Referred Talent under these
            Referral Terms. The Organization (as a Streamily Partner) will be
            entitled to the Referral Fee set forth in Section 5.c as of the date
            the Talent User becomes an Affiliated Talent.
          </p>
        </li>
        <li>
          <p>
            By participating in the Referral Program, you agree to release and
            hold harmless Streamily Parties from and against any and all
            economic liabilities, claims, damages, loss, harm, costs, or
            expenses, including property damage, that arise from or relate in
            any way to the Referral Program. THIS SECTION 5.h APPLIES ONLY TO
            ECONOMIC DAMAGES AND DOES NOT APPLY TO CLAIMS OF FRAUD OR PERSONAL
            INJURY.
          </p>
        </li>
      </ul>

      <h2>3. Talent Content</h2>

      <ol style={{ listStyleType: "lower-alpha" }}>
        <li>
          <p>
            <strong>License Grant to Talent Content:</strong> Our Site allows
            you to upload, submit, store, send, transmit, approve, and receive
            content, offerings and data, including your STREAMILY offerings and
            Promotional Materials (collectively, “Talent Content”). When you
            upload, submit, store, send, transmit approve, or receive Talent
            Content to or through our Site, you grant to us a non-exclusive,
            royalty-free, fully paid, unlimited, universal, sublicensable
            (through multiple tiers of sublicenses), perpetual, and irrevocable
            license in any and all manner and media, whether now known or
            hereinafter invented or devised (including social media channels and
            third party websites and platforms), to reproduce, license,
            distribute, modify, adapt, publicly perform, publicly display,
            create derivative works of (for example, translations, adaptations,
            or other changes we make so that Talent Content works better with
            our Site or otherwise), and to use your Talent Content for the
            purposes of operating and providing our Site, to develop and improve
            our products and services, and to advertise, market, and promote our
            Site, products, and services, and you agree that such Talent Content
            may, in Streamily’s sole discretion, be used, including performed or
            displayed, in connection with any other elements, materials,
            copyrights, rights of publicity, or copyrighted materials. Please
            remember that third parties (including Users) may search for and see
            any Talent Content you submit to public areas of our Site. You agree
            that we may display advertising with or in connection with your
            Talent Content. You further acknowledge and agree that Streamily has
            no obligation to you in connection with any advertising displayed on
            or in connection with our Site (including no obligation to share any
            revenue received by Streamily as a result of any such advertising).
          </p>
        </li>
        <li>
          <p>
            <strong>Licenses:</strong> You may not sell, resell, commercialize,
            or encumber your rights in any Streamily licensed offering,
            including creating a non-fungible token (“NFT”) from any Streamily
            licensed offering except as agreed by Streamily in writing. Please
            note that the licenses granted in this Section 6 are fully-paid and
            royalty free, meaning we do not owe you anything in connection with
            the use of your Talent Content, by us, Users, or third parties other
            than the payment set forth in Section 4. We may exercise our rights
            under this license anywhere in the universe. We may sublicense our
            rights as needed to provide and promote our Site or otherwise in
            accordance with these Terms, and Users may sublicense their rights
            subject to the Site Terms. Finally, the licenses granted in this
            Section 6 are perpetual, meaning that the rights granted under these
            licenses continue even after you stop using our Site.
          </p>
        </li>

        <li>
          <p>
            <strong>Talent Content Representations and Warranties:</strong> You
            represent and warrant that:
          </p>

          <ol style={{ listStyleType: "lower-roman" }}>
            <li>
              you own all rights in and to your Talent Content and Feedback
              (defined below) and that you have the right to grant the rights
              described in these Terms;
            </li>
            <li>
              you have paid and will pay in full any fees, royalties, or other
              payments that are due or may become due in connection with any use
              of your Talent Content and Feedback by us, Users, or third parties
              (including Recipients) as described in these Terms;
            </li>
            <li>
              your agreement to, and provision of services under, these Terms
              does not violate any agreement that you may have with any third
              party;
            </li>
            <li>
              your Talent Content and Feedback does not infringe,
              misappropriate, or otherwise use without necessary authorization,
              any intellectual property, privacy, publicity, moral, or other
              rights of any third party, or violate any law, regulation, or
              court order;
            </li>
            <li>
              you will not contact, respond to, or communicate with any User
              that you meet on or through our Site, except as expressly
              permitted through our Site.
            </li>
          </ol>
        </li>

        <li>
          <p>
            <strong>Treatment of Talent Content:</strong> Any Talent Content is
            non-confidential, non-proprietary, and must not contain or include
            any information which you do not have the right to disclose or that
            you do not wish to be disclosed. We will not be responsible or
            liable for any use or disclosure of Talent Content, including any
            personal information included in that Talent Content. You
            acknowledge and agree that your relationship with us is not a
            confidential, fiduciary, joint-venture, employer/employee, agency,
            or other type of special relationship, and that your decision to
            participate in our Site or submit any Talent Content does not place
            us in a position that is any different from the position held by
            members of the general public, including with regard to your Talent
            Content. None of your Talent Content will be subject to any
            obligation of confidence by us, Users, or third parties (including
            Recipients), and we will not be liable or responsible for any use or
            disclosure of any Talent Content.
          </p>
        </li>
        <li>
          <p>
            <strong>Refusal and Removal of Talent Content:</strong> We may
            refuse to accept or transmit Talent Content for any reason without
            notice to you. We may remove Talent Content from our Site for any
            reason without notice to you.
          </p>
        </li>
        <li>
          <p>
            <strong>Cancellation of Site Account:</strong> If you cancel your
            Site account, you may, on at least three business days’ advance
            written notice to us, request that we no longer include your
            merchandise on our Site and that we not make any new public
            promotion of them.
          </p>
        </li>
      </ol>

      <h2 id="section5">5. Ownership</h2>

      <ol style={{ listStyleType: "lower-alpha" }}>
        <li>
          <p>
            Other than Talent Content, we or our licensors own all right, title,
            and interest in and to: (i) our Site and the “look and feel” of our
            Site, including all software, ideas, processes, data, text, media,
            and other content available on our Site (individually, and
            collectively, “Streamily Content”); and (ii) our trademarks, logos,
            and brand elements (“Marks”). Our Site, Streamily Content, and Marks
            are each protected under U.S. and international laws. You may not
            duplicate, copy, or reuse any portion of Streamily Content or use
            the Marks without our prior express written consent. You acknowledge
            and agree that each request or message from a User is a Submission
            (as defined in the Site Terms) owned by the User who created it.
          </p>
        </li>

        <li>
          <p>
            Streamily desires to avoid the possibility of misunderstandings if a
            project developed by us, our employees, or our contractors might
            seem similar to material submitted to us by you or a third party. To
            the extent you submit any ideas, suggestions, proposals, plans, or
            other materials related to our business (individually, and
            collectively, “Feedback”), you acknowledge and agree that you are
            submitting that Feedback at your own risk and that Streamily has no
            obligation (including of confidentiality or privacy) with respect to
            your Feedback, and you grant to Streamily a non-exclusive,
            royalty-free, fully paid, unlimited, universal, sublicensable
            (through multiple tiers of sublicenses), perpetual, and irrevocable
            license, in any and all manner and media, whether now known or
            hereinafter invented or devised, to reproduce, license, distribute,
            modify, adapt, publicly perform, publicly display, create derivative
            works of (for example, translations, adaptations, or other changes),
            and otherwise use and exploit in any manner (including
            commercially), any and all Feedback.
          </p>
        </li>

        <li>
          <p>
            You hereby waive any and all moral rights or “droit moral” that you
            may have in Talent Content or Feedback, and you represent and
            warrant that no third party has any moral, “droit moral” or other
            rights in the Talent Content or Feedback.
          </p>
        </li>
      </ol>

      <h2 id="section6">6. Additional Terms</h2>

      <p>
        Some products or services offered through the Site may have additional
        terms and conditions (“Additional Terms”). If Additional Terms apply, we
        will make them available for you to read in connection with that product
        or service. By using that product or service, you agree to the
        Additional Terms. To the extent that the Additional Terms conflict with
        any of these Terms, these Terms will govern unless the Additional Terms
        say that all or some of these Terms don’t apply.
      </p>

      <h2 id="section7">7. Eligibility</h2>

      <ol style={{ listStyleType: "lower-alpha" }}>
        <li>
          <p>
            <strong>Age:</strong> You must be at least 16 years old to use our
            Site. If you are a minor or under the age of majority in your state
            of residence, your parent or legal guardian must agree to these
            Terms on your behalf and you may access and use our Site only with
            permission from your parent or legal guardian.
          </p>
        </li>

        <li>
          <p>
            <strong>
              National College Athletic Association (“NCAA”) and Other Amateur
              Organizations:
            </strong>{" "}
            If you are or may become subject to NCAA rules and regulations or
            those of any other association, group, authority or organization,
            you represent and warrant that you have checked the rules and
            regulations to verify and confirm that your participation on our
            Site does not or will not affect your current or future eligibility
            with respect to any such association, group, authority or
            organization.
          </p>
        </li>

        <li>
          <p>
            <strong>Eligibility Representations and Warranties:</strong> You
            represent and warrant that:
          </p>
          <ol style={{ listStyleType: "lower-roman" }}>
            <li>
              you have not been prohibited from using or accessing any aspect of
              our Site by us or pursuant to any applicable law or regulation;
            </li>
            <li>
              you will comply with all applicable terms of any third party
              payment provider we select, and you are not on a prohibited list
              of that payment provider;
            </li>
            <li>
              you (and any Site account that you created or control) have not
              been previously banned or removed from our Site for any reason;
              and
            </li>
            <li>you are not a convicted sex offender.</li>
          </ol>
        </li>

        <li>
          <p>
            <strong>Export Control:</strong> You may not use, export, import, or
            transfer any part of our Site except as authorized by United States
            law, the laws of the jurisdiction in which you use or access our
            Site, or any other applicable laws. In particular, but without
            limitation, no part of our Site may be exported or re-exported: (i)
            into any country embargoed by the U.S.; or (ii) to anyone on the
            U.S. Treasury Department’s list of Specially Designated Nationals or
            the U.S. Department of Commerce’s Denied Persons List or Entity
            List. By using our Site, you represent and warrant that: (x) you are
            not located in a country that is subject to a U.S. Government
            embargo or that has been designated by the U.S. Government as a
            “terrorist supporting” country; and (y) you are not listed on any
            U.S. Government list of prohibited or restricted parties. You also
            will not use our Site for any purpose prohibited by law. You
            acknowledge and agree that products, services, and technology
            provided by Streamily are subject to the export control laws and
            regulations of the U.S. You will comply with those laws and
            regulations and will not, without prior U.S. government
            authorization, export, re-export, or transfer Streamily products,
            services, or technology, either directly or indirectly, to any
            country in violation of those laws and regulations.
          </p>
        </li>
      </ol>

      <h2 id="section8">8. Copyright and Intellectual Property Policy</h2>
      <ol style={{ listStyleType: "lower-alpha" }}>
        <li>
          <p>
            <strong>Digital Millennium Copyright Act Notice:</strong> We respond
            to notices of alleged copyright infringement and terminate access to
            our Site for repeat infringers. If you believe that your material
            has been copied in a way that constitutes copyright infringement,
            please forward the following information to the Copyright Agent
            named below:
          </p>

          <ol style={{ listStyleType: "lower-roman" }}>
            <li>your address, telephone number, and email address;</li>
            <li>
              a description of the work that you claim is being infringed;
            </li>
            <li>
              a description of the material that you claim is infringing and are
              requesting be removed along with information about where it is
              located;
            </li>
            <li>
              a statement that you have “a good faith belief that use of the
              material in the manner complained of is not authorized by the
              copyright owner, its agent, or the law”;
            </li>
            <li>
              an electronic or physical signature of the copyright owner (or a
              person authorized to act for the copyright owner); and
            </li>
            <li>
              a statement by you, made under penalty of perjury, that the
              information you are providing is accurate and that you are the
              copyright owner or authorized to act on behalf of the copyright
              owner.
            </li>
          </ol>

          <p>
            Copyright Agent:
            <br />
            <br />
            Streamily
            <br />
            c/o Legal Department
            <br />
            1801 Century Park East
            <br />
            Los Angeles, CA 90067
            <br />
            <Link href="mailto:agent@streamily.com">agent@streamily.com</Link>
          </p>

          <p>
            If you do not follow these requirements, your notice may not be
            valid. Please note, only notices of alleged copyright infringement
            should be sent to our Copyright Agent.
          </p>
        </li>

        <li>
          <p>
            <strong>Termination Policy:</strong> If we determine that you are a
            repeat infringer, we may terminate your access to our Site, remove
            or ban you (and any Site account you created or control), and take
            other appropriate action in our sole discretion.
          </p>
        </li>
      </ol>

      <h2 id="section9">9. Privacy</h2>

      <p>
        Your privacy is important to us. Our{" "}
        <Link component={RouterLink} to="/privacy-policy">
          Privacy Policy
        </Link>{" "}
        explains how we collect, use, and share personal information and other
        data. By using our Site, you agree to our{" "}
        <Link component={RouterLink} to="/privacy-policy">
          Privacy Policy
        </Link>
        .
      </p>

      <h2 id="section10">10. Third Party Content and Interactions</h2>
      <p>
        Our Site may contain features and functionalities that link to or
        provide you with access to third party content, that is completely
        independent of Streamily, including Streamily Videos, websites,
        platforms, directories, servers, networks, systems, information,
        databases, applications, software, programs, products or services, and
        the Internet in general. Your interactions with third parties, including
        Users, on or through our Site, are solely between you and the third
        party; however, Streamily may, in its sole discretion, intercede and you
        will reasonably cooperate with Streamily if it does so. You acknowledge
        and agree that Streamily will not be responsible for any damages,
        losses, costs, expenses, or liabilities incurred as the result of such
        interactions, including any requests or Submissions from Users. You
        agree to contact Users about matters relating to our Site only through
        the Site. You hereby release each Streamily Party (defined below) from
        claims, demands, and damages (actual and consequential) of every kind or
        nature, known and unknown, suspected and unsuspected, disclosed and
        undisclosed, arising out of or in any way related to such interactions
        or our Site. IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE
        CALIFORNIA CIVIL CODE SECTION 1542 (AND ANY OTHER SIMILAR APPLICABLE
        STATE STATUTE), WHICH PROVIDES: A GENERAL RELEASE DOES NOT EXTEND TO
        CLAIMS THAT THE CREDITOR OR RELEASING PARTY DOES NOT KNOW OR SUSPECT TO
        EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE AND THAT,
        IF KNOWN BY HIM OR HER WOULD HAVE MATERIALLY AFFECTED HIS OR HER
        SETTLEMENT WITH THE DEBTOR OR RELEASED PARTY.
      </p>

      <h2 id="section11">11. Business Relationship with Streamily</h2>

      <ol style={{ listStyleType: "lower-alpha" }}>
        <li>
          <p>
            You and Streamily agree and declare you and Streamily are in a
            direct business relationship and the relationship between the
            parties, including these Terms, is solely an independent contractor
            relationship. It is the parties’ express intent that their
            relationship be interpreted and held to be that of independent
            contractor for all purposes. You acknowledge and agree that you are
            not a joint venturer, franchisee, partner, agent, or employee of
            Streamily, and will not represent yourself as such. As an
            independent contractor using our platform to provide signatures,
            personal merchandise, marketing, artistic, writing, and photography
            services to Users, you are solely and exclusively responsible for
            determining the manner, method, details, and means of your
            performance under these Terms and you acknowledge and agree that we
            do not control those elements of your performance. You also retain
            the option to accept, decline, or ignore any User request. We have
            no right to, and will not, control the manner or determine the
            method of accomplishing your performance. You represent and warrant
            that you are customarily engaged in an independently established
            trade, occupation, or business.
          </p>
        </li>
        <li>
          <p>
            You represent and warrant that as between Streamily and you (whether
            a Talent User, parent or legal guardian of a Talent User, an
            Organization, Affiliated Talent, Streamily Partner, or otherwise),
            you assume sole liability for and will pay or cause to be paid all
            applicable contributions, payments, taxes, and deductions for Social
            Security, retirement or other benefits, healthcare insurance,
            unemployment insurance, annuities, pension and welfare fund payments
            required by law, regulation, or any labor union, and all withholding
            and income taxes, and make any reports required as a result of
            participation on our Site under these Terms.
          </p>
        </li>
        <li>
          <p>
            You will use your own equipment to perform your obligations under
            these Terms.
          </p>
        </li>
        <li>
          <p>
            You are solely responsible for making any disclosure required by any
            applicable law, regulation, court order or any agreement you may
            have with any third parties to any person or entity regarding your
            performance under these Terms.
          </p>
        </li>
        <li>
          <p>
            Your relationship with Streamily is non-exclusive, meaning that you
            may provide similar services to third parties, including Streamily’s
            competitors, and you may engage in other business or employment
            activities. Similarly, we can and do engage third parties to provide
            services similar to those that you may provide under these Terms.
          </p>
        </li>
      </ol>

      <h2 id="section12">12. Links</h2>
      <p>
        Our Site may contain links to social media platforms or third party
        websites. You acknowledge and agree that: (a) the link does not mean
        that we endorse or are affiliated with the platform or website; and (b)
        we are not responsible or liable for any damages, losses, costs,
        expenses, or liabilities related to your use of the platform or website.
        You should always read the terms and conditions and privacy policy of a
        platform or website before using it.
      </p>

      <h2 id="section13">13. Changes to our Site</h2>
      <p>
        You acknowledge and agree we may change or discontinue any aspect of our
        Site at any time, without notice to you.You acknowledge and agree we may
        change or discontinue any aspect of our Site at any time, without notice
        to you.
      </p>

      <h2 id="section14">14. Termination and Reservation of Rights</h2>
      <p>
        You may cancel your Site account at any time by contacting a member of
        the Streamily team at{" "}
        <Link href="mailto:partners@streamily.com">partners@streamily.com</Link>
        . We reserve the right to terminate access to our Site to any person,
        including you, at any time, for any reason, in our sole discretion. If
        you violate any of these Terms, your permission to use our Site
        automatically terminates.
      </p>

      <h2 id="section15">15. Disclaimers and Limitations on our Liability</h2>

      <ol style={{ listStyleType: "lower-alpha" }}>
        <li>
          <p>
            You acknowledge and agree that your use of our Site is at your own
            risk and that our Site is provided on an “as is” and “as available”
            basis. To the extent permitted by applicable law, the Streamily
            Parties disclaim all warranties, conditions, and representations of
            any kind, whether express, implied, statutory, or otherwise,
            including those related to merchantability, fitness for a particular
            purpose, non-infringement, and arising out of course of dealing or
            usage of trade.
          </p>
        </li>
        <li>
          <p>
            In particular, the Streamily Parties make no representations or
            warranties about the accuracy or completeness of content available
            on or through our Site or the content of any social media platform
            or third party website linked to or integrated with our Site. You
            acknowledge and agree that the Streamily Parties will have no
            liability for any: (i) errors, mistakes, or inaccuracies of content;
            (ii) personal injury, property damage, or other harm resulting from
            your access to or use of our Site; (iii) any unauthorized access to
            or use of our servers, any personal information, or user data; (iv)
            any interruption of transmission to or from our Site; (v) any bugs,
            viruses, trojan horses, or the like that may be transmitted on or
            through our Site; or (vi) any damages, losses, costs, expenses, or
            liabilities of any kind incurred as a result of any content or the
            use of any content posted or shared through our Site.
          </p>
        </li>
        <li>
          <p>
            You acknowledge and agree that any material or information
            downloaded or otherwise obtained through our Site, including a User
            request, is done at your own risk and that you will be solely
            responsible for any damages, losses, costs, expenses, or liabilities
            arising from or in connection with doing so. No advice or
            information, whether oral or written, obtained by you from us or
            through our Site, will create any warranty not expressly made by us.
          </p>
        </li>
        <li>
          <p>
            To the fullest extent permitted by applicable law, you acknowledge
            and agree that in no event will any Streamily Party be liable to you
            or to any third party for any indirect, special, incidental,
            punitive, or consequential damages (including for loss of profits,
            revenue, or data) or for the cost of obtaining substitute products,
            arising out of or in connection with these Terms, however caused,
            whether such liability arises from any claim based upon contract,
            warranty, tort (including negligence), strict liability or
            otherwise, and whether or not Streamily has been advised of the
            possibility of such damages.
          </p>
        </li>
        <li>
          <p>
            To the maximum extent permitted by applicable law, our total
            cumulative liability to you or any third party under these Terms,
            including from all causes of action and all theories of liability,
            will be limited to and will not exceed the fees actually received by
            Streamily from you during the 12 months preceding the claim giving
            rise to such liability.
          </p>
        </li>
        <li>
          <p>
            Certain jurisdictions do not allow the exclusion or limitation of
            certain damages. If those laws apply to you, some or all of the
            above exclusions or limitations may not apply to you, and you might
            have additional rights.
          </p>
        </li>
        <li>
          <p>
            You agree that the limitations of damages set forth above are
            fundamental elements of the basis of the bargain between Streamily
            and you.
          </p>
        </li>
      </ol>

      <h2 id="section16">16. Indemnification</h2>
      <p>
        You agree to indemnify, defend, and hold harmless Streamily and its
        parents, subsidiaries, affiliates, officers, employees, directors,
        shareholders, agents, partners, and licensors (each, a “Streamily Party,
        “ and collectively, “Streamily Parties”) from and against any and all
        claims, costs, proceedings, demands, losses, damages, and expenses
        (including reasonable attorneys’ fees and costs) of any kind or nature,
        arising from, out of, in connection with, or relating to: (a) these
        Terms; (b) use of our Site; (c) your negligence, misconduct, or fraud;
        (d) any action or inaction by you or anyone acting on your behalf; (e)
        any Organization or Affiliated Talent; (f) any Charity; (g) your status
        as a parent or legal guardian of a Talent User; (h) Talent Content; (i)
        Feedback; (j) your eligibility with the NCAA or any other association,
        group, authority, or organization referenced Section 9.b; or (k) your
        participation in the Referral Program. Streamily may select counsel and
        control the defense of any claim that you are indemnifying. You will
        reasonably cooperate with us in connection with any claim.
      </p>

      <h2 id="section17">
        17. Arbitration Agreement and Waiver of Certain Rights
      </h2>
      <ol style={{ listStyleType: "lower-alpha" }}>
        <li>
          <p>
            <strong>Arbitration:</strong> You and Streamily agree to resolve any
            disputes between you and Streamily through binding and final
            arbitration instead of through court proceedings. You and Streamily
            each hereby waive any right to a jury trial of any controversy,
            claim, counterclaim, or other dispute arising between you and
            Streamily relating to these Terms or our Site (each a “Claim,” and
            collectively, “Claims”). Any Claim will be submitted for binding
            arbitration in accordance with the Rules of the American Arbitration
            Association (“AAA Rules”). The arbitration will be heard and
            determined by a single arbitrator. The arbitrator’s decision will be
            in writing, will include the arbitrator’s reasons for the decision,
            will be final and binding upon the parties, and may be enforced in
            any court of competent jurisdiction. The parties agree that the
            arbitration will be kept confidential and that the existence of the
            proceeding and any element of it (including any pleadings, briefs or
            other documents submitted or exchanged, any testimony or other oral
            submissions, and awards) will not be disclosed beyond the
            arbitration proceedings, except as may lawfully be required in
            judicial proceedings relating to the arbitration or by applicable
            disclosure rules and regulations of securities regulatory
            authorities or other governmental agencies.
          </p>
        </li>
        <li>
          <p>
            <strong>Costs and Fees:</strong> If you demonstrate that the costs
            of arbitration will be prohibitive as compared to the costs of
            litigation, Streamily will pay as much of the administrative costs
            and arbitrator’s fees required for the arbitration as the arbitrator
            deems necessary to prevent the cost of the arbitration from being
            prohibitive. In the final award, the arbitrator may apportion the
            costs of arbitration and the compensation of the arbitrator among
            the parties in such amounts as the arbitrator deems appropriate.
          </p>
        </li>
        <li>
          <p>
            <strong>No Preclusions:</strong> This arbitration agreement does not
            preclude you or Streamily from seeking action by federal, state, or
            local government agencies. You and Streamily each also have the
            right to bring any qualifying Claim in small claims court. In
            addition, you and Streamily each retain the right to apply to any
            court of competent jurisdiction for provisional relief, including
            pre-arbitral attachments or preliminary injunctions, and any such
            request will not be deemed to be either incompatible with these
            Terms or a waiver of the right to have disputes submitted to
            arbitration as provided in these Terms.
          </p>
        </li>
        <li>
          <p>
            <strong>
              No Class Representative or Private Attorney General:
            </strong>{" "}
            Each of you and Streamily agree that with respect to any Claim,
            neither may: (i) act as a class representative or private attorney
            general; or (ii) participate as a member of a class of claimants.
            You agree that no Claim may be arbitrated on a class or
            representative basis. The arbitrator can decide only individual
            Claims (whether brought by you or Streamily). The arbitrator may not
            consolidate or join the claims of other persons or parties who may
            be similarly situated.
          </p>
        </li>
        <li>
          <p>
            <strong>Severability/No Waiver/Survival:</strong> If any provision
            of this Section 19 is found to be invalid or unenforceable, that
            provision will be deemed appropriately modified to give effect to
            the intent of the provision or, if modification is not possible,
            will be severed and the remainder of this Section 19 will continue
            in full force and effect. No waiver of any provision of this Section
            19 will be effective or enforceable unless recorded in a writing
            signed by the party waiving such a right or requirement. Such a
            waiver will not waive or affect any other provision of these Terms.
            This Section 19 will survive the termination of your relationship
            with Streamily.
          </p>
        </li>
        <li>
          <p>
            <strong>30-Day Opt-Out Right:</strong> You have the right to opt out
            of the provisions of this Arbitration Agreement by sending, within
            30 days after first becoming subject to this Arbitration Agreement,
            written notice of your decision to opt out to the following address:
            Streamily, c/o Legal Department, 1801 Century Park E #2400, Los
            Angeles, CA 90067. Your notice must include your name and address,
            any usernames, each email address you have used to set up an account
            on our Site, and an unequivocal statement that you want to opt out
            of this Arbitration Agreement. You agree that if you opt out of this
            Arbitration Agreement, all other parts of these Terms will continue
            to apply to you. Opting out of this Arbitration Agreement has no
            effect on any other arbitration agreements that you may currently
            have, or may enter in the future, with us.
          </p>
        </li>
        <li>
          <p>
            <strong>Limitations:</strong> This Section 19 limits certain rights,
            including the right to maintain certain court actions, the right to
            a jury trial, the right to participate in any form of class or
            representative claim, the right to engage in discovery except as
            provided in AAA rules, and the right to certain remedies and forms
            of relief. In addition, other rights that you or Streamily would
            have in court may not be available in arbitration.
          </p>
        </li>
      </ol>

      <h2 id="section18">18. Other Provisions</h2>
      <ol style={{ listStyleType: "lower-alpha" }}>
        <li>
          <p>
            <strong>Force Majeure:</strong> Under no circumstances will any
            Streamily Party be liable for any delay or failure in performance
            due in whole or in part to any acts of God (such as earthquakes,
            storms, floods, etc.), unavoidable accidents, laws, rules,
            regulations or orders of government authorities, acts of war
            (declared or not), terrorism, hostilities, blockades, civil
            disturbances, embargoes, strikes, or any other event or cause beyond
            the reasonable control of any Streamily Party.
          </p>
        </li>
        <li>
          <p>
            <strong>Choice of Law and Jurisdiction:</strong> These Terms will be
            governed by and construed in accordance with the laws of the State
            of California, without giving effect to any conflict of laws rules
            or provisions. You agree that any action of whatever nature arising
            from or relating to these Terms or our Site will be filed only in
            the state or federal courts located in Los Angeles, California. You
            consent and submit to the personal jurisdiction of such courts for
            the purposes of any such action.
          </p>
        </li>
        <li>
          <p>
            <strong>Severability:</strong> If any provision of these Terms is
            found to be invalid or unenforceable, that provision will be deemed
            appropriately modified to give effect to the intent of the provision
            or, if modification is not possible, will be severed from these
            Terms and will not affect the enforceability of any other provision.
          </p>
        </li>
        <li>
          <p>
            <strong>No Waiver or Amendment:</strong> The failure by Streamily to
            enforce any right or provision of these Terms will not prevent
            Streamily from enforcing such right or provision in the future and
            will not be deemed to modify these Terms.
          </p>
        </li>
        <li>
          <p>
            <strong>Assignment:</strong> Streamily may, at any time, assign its
            rights and obligations under these Terms, including to an affiliated
            entity or in connection with a sale of assets, merger, acquisition,
            reorganization, bankruptcy, other transaction, or by operation of
            law.
          </p>
        </li>
        <li>
          <p>
            <strong>Miscellaneous:</strong> The term “including” in these Terms
            will be interpreted broadly and will mean “including, without
            limitation.” Titles are for convenience only and will not be
            considered when interpreting these Terms.
          </p>
        </li>
      </ol>

      <h2 id="section19">19. Changes to these Terms</h2>
      <p>
        We may change these Terms. If we do, we will post the revised Terms on
        our Site and update the “Last Updated” date at the top of these Terms.
        The revised Terms will be effective immediately if you accept them (for
        example, by agreeing when you create an account or login to an existing
        account, or using or continuing to use our Site after the revised Terms
        have been posted); otherwise, they will be effective 30 days after
        posting.
      </p>
    </ContentPage>
  );
}
