import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Link,
  Typography,
} from "@material-ui/core";
import { styled, withStyles } from "@material-ui/core/styles";
import { Close as CloseIcon } from "@material-ui/icons";
import clsx from "clsx";
import PropTypes from "prop-types";
import * as React from "react";
import { Product } from "../../domain";
import { useMatchesXsDown } from "../../hooks";
import { Form, scrollbarStyles } from "../../theme";
import { realisticConfetti, validateEmail } from "../../util";
import ProductModalImage from "../Shop/Products/ProductModal/ProductModalImage";
import ProductWishlistButton from "../WishlistProductButton";

const buttonStyle = { minWidth: "10rem" };

function descriptionFor(product) {
  if (product.vaultedUntil) {
    return (
      <>
        <Typography variant="body1">
          You can <em>Join the Wait List</em> to be the first to know when this
          product is ready to be released from the vault.
        </Typography>
      </>
    );
  }

  return (
    <Typography variant="body1">
      Unfortunately, we&apos;re all out! 😭 However, fear not! You can{" "}
      <em>Join the Wait List</em> to be the first to know when this product is
      back in stock.
    </Typography>
  );
}

function titleFor(product) {
  if (product.vaultedUntil) {
    return (
      <Typography variant="h6" gutterBottom>
        {product.name} is in the vault.
      </Typography>
    );
  }

  return (
    <Typography variant="h6" gutterBottom>
      So you wanted {product.name}...
    </Typography>
  );
}

export default function ProductSoldOutModal({ onClose, open, product }) {
  const matchesXs = useMatchesXsDown();
  const [isOpen, setIsOpen] = React.useState(open);
  const [isLoading, setIsLoading] = React.useState(false);
  const [didSubmit, setDidSubmit] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleClose = React.useCallback(() => {
    setIsOpen(false);
    setEmail("");
  }, []);

  const onSubmit = React.useCallback(
    async (evt) => {
      evt.preventDefault();

      if (didSubmit) {
        return handleClose();
      }

      if (errorMessage) {
        return;
      }

      if (!validateEmail(email)) {
        setErrorMessage("Please enter a valid email address");
        return;
      }

      if (email.endsWith(".con")) {
        setErrorMessage("Oops! Check your email, did you mean .com?");
        return;
      }

      if (!product?.productId) {
        setErrorMessage("Oops! Something went wrong. Please try again later.");
        return;
      }

      setIsLoading(true);

      try {
        const response = await window.fetch("/api/product/waitlist", {
          body: JSON.stringify({
            email: email.trim(),
            productId: product.productId,
            source: "sold_out_modal",
            location: window.location.toString(),
          }),
          headers: { "content-type": "application/json" },
          method: "POST",
        });

        if (!response.ok) {
          throw new Error("Failed to submit email");
        }
      } catch (err) {
        setErrorMessage("Oops! Something went wrong. Please try again later.");
        setIsLoading(false);

        return;
      }

      realisticConfetti();
      setIsLoading(false);
      setDidSubmit(true);
    },
    [didSubmit, errorMessage, email, handleClose, product],
  );

  if (!product) {
    return null;
  }

  return (
    <StyledDialog
      TransitionProps={{
        onExited() {
          if (!isOpen) {
            onClose();
          }
        },
      }}
      onClose={handleClose}
      open={isOpen}
    >
      <StyledIconButton onClick={handleClose} aria-label="Close">
        <CloseIcon />
      </StyledIconButton>

      <form onSubmit={onSubmit}>
        <StyledDialogContent>
          <Box py={matchesXs ? 1 : 2} px={matchesXs ? 0 : 2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm="auto">
                <ProductModalImage product={product} />
              </Grid>

              <Grid item xs>
                <Box
                  maxWidth="52ch"
                  display="flex"
                  flexDirection="column"
                  gridGap="8px"
                >
                  {titleFor(product)}

                  {descriptionFor(product)}

                  {!didSubmit && (
                    <Box pt={3}>
                      <Form.Input
                        autoComplete="email"
                        autoFocus
                        fullWidth
                        label="Your Email"
                        name="email"
                        noAsterisk
                        onChange={(evt) => {
                          setEmail(evt.target.value);
                          setErrorMessage("");
                        }}
                        placeholder="Enter your email address..."
                        required
                        type="email"
                        value={email}
                        onBlur={(evt) => {
                          const { value } = evt.target;

                          if (value.trim() && !validateEmail(value)) {
                            setErrorMessage(
                              "Please enter a valid email address",
                            );
                          }

                          if (value.endsWith(".con")) {
                            setErrorMessage(
                              "Oops! Check your email, did you mean .com?",
                            );
                          }
                        }}
                        error={Boolean(errorMessage)}
                        helperText={errorMessage}
                      />
                    </Box>
                  )}

                  {didSubmit && (
                    <Box pt={3}>
                      <Typography>
                        Thank you for joining! 🎉 We&apos;ll keep you updated.
                      </Typography>
                    </Box>
                  )}

                  {!didSubmit && (
                    <Typography variant="caption">
                      By entering your email address, you agree to our{" "}
                      <Link
                        href="/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy Policy
                      </Link>
                      .
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </StyledDialogContent>

        <StyledDialogActions>
          <Grid
            container
            justifyContent="flex-end"
            spacing={1}
            alignItems="center"
          >
            <Grid item xs={matchesXs ? 12 : undefined}>
              <ProductWishlistButton product={product} />
            </Grid>

            <Grid item xs={matchesXs ? 12 : undefined}>
              <Button
                disabled={
                  isLoading || !validateEmail(email) || Boolean(errorMessage)
                }
                fullWidth={matchesXs}
                size="large"
                variant="contained"
                color={didSubmit ? "default" : "primary"}
                style={buttonStyle}
                type="submit"
                className={clsx(matchesXs && "largerMobile")}
              >
                {didSubmit ? "Close" : "Join Wait List"}
              </Button>
            </Grid>
          </Grid>
        </StyledDialogActions>
      </form>
    </StyledDialog>
  );
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": { paddingBottom: 0 },
  "& .MuiPaper-root": {
    borderRadius: theme.spacing(0.5),
    maxWidth: "64rem",
    minHeight: "18rem",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "unset",
      width: "100vw",
      margin: 0,
    },
  },
  "& .MuiDialog-paperScrollPaper": {
    ...scrollbarStyles(theme),
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  left: theme.spacing(0.5),
  top: theme.spacing(0.5),
  zIndex: 10,
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  [theme.breakpoints.down("xs")]: {
    padding: theme.spacing(2),
  },
}));

const StyledDialogActions = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
    },
  },
}))(DialogActions);

ProductSoldOutModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  product: PropTypes.instanceOf(Product),
};
