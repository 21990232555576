import useSWR from "swr";
import { fetcher } from "../../../hooks";

const defaultShops = [];

export function useHeroShops() {
  const { data, error, isLoading } = useSWR(
    "/api/influencer/heroShops",
    fetcher,
  );

  return {
    shops: data?.shops || defaultShops,
    error,
    isLoading,
    isError: !!error,
  };
}
