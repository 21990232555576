import { Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

const StyledBox = styled("div")(({ theme }) => ({
  flex: 1,
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  padding: `${theme.spacing(4)}px var(--gutter)`,
  ...theme.typography.body1,
  color: theme.palette.text.secondary,
  lineHeight: 1.65,

  "& .container": {
    maxWidth: "64ch",
    margin: "0 auto",
    padding: theme.spacing(4, 0),
  },

  "& h2": {
    ...theme.typography.h5,
    marginTop: -theme.spacing(2),
    paddingTop: theme.spacing(8),
  },

  "& h3": {
    ...theme.typography.h6,
    marginTop: theme.spacing(4),
  },

  "& h4": {
    ...theme.typography.subtitle1,
    marginBottom: theme.spacing(1),
    color: theme.palette.text.primary,
  },
}));

export default function ContentPage({ title, description, children }) {
  return (
    <>
      <Helmet>
        <title>{title} | Streamily</title>
        {description ? <meta name="description" content={description} /> : null}
      </Helmet>

      <StyledBox>
        <div className="container">
          <Typography component="h1" gutterBottom variant="h4">
            {title}
          </Typography>

          {children}
        </div>
      </StyledBox>
    </>
  );
}

ContentPage.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
};
