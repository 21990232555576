import { D, G, S, F } from "@mobily/ts-belt";
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import useSWR from "swr";
import { fetcher } from "../../hooks";

export const defaultFilter = {
  order: "ra",
  type: {},
  character: {},
  franchise: {},
  category: {},
  rank: 0,
  limit: 24,
  name: "",
};

const defaultFilters = {
  order: [
    { id: "ca", name: "Oldest" },
    { id: "cd", name: "Newest" },
    { id: "pa", name: "Price: Low to High" },
    { id: "pd", name: "Price: High to Low" },
    { id: "ra", name: "Most Popular" },
  ],
  type: [],
  character: [],
  franchise: [],
  category: {},
};

const defaultPagination = {
  previousPageId: null,
  currentPageId: null,
  nextPageId: null,
  totalResults: 0,
  currentResults: 0,
  pageSize: 24,
  hasNext: false,
  hasPrevious: false,
};

function isValidOrder(order) {
  return (
    G.isString(order) &&
    S.isNotEmpty(order) &&
    defaultFilters.order.some((filter) => filter.id === order)
  );
}

function isValidNumber(num) {
  return G.isNumber(num) && !Number.isNaN(num) && num >= 0;
}

const keyMap = {
  order: "o",
  type: "ty",
  character: "c",
  franchise: "f",
  category: "g",
  rank: "r",
  limit: "l",
  name: "q",
};

const formatters = {
  g(categories) {
    return Object.entries(categories).map(([categoryId, subcategories]) => {
      const subcategoryIds = Object.keys(subcategories).filter(
        (key) => subcategories[key],
      );
      return `${categoryId}.${subcategoryIds.join(".")}`;
    });
  },
};

function toMap(acc, value) {
  acc[value] = true;
  return acc;
}

const paramMap = {
  o: {
    name: "order",
    parser: (value) => (isValidOrder(value) ? value : "ra"),
  },
  ty: {
    name: "type",
    parser: (value) => value.split(",").reduce(toMap, {}),
  },
  c: {
    name: "character",
    parser: (value) => value.split(",").reduce(toMap, {}),
  },
  f: {
    name: "franchise",
    parser: (value) => value.split(",").reduce(toMap, {}),
  },
  g: {
    name: "category",
    parser: (param) =>
      param.split(",").reduce((result, category) => {
        const [categoryId, ...rest] = category.split(".");

        result[categoryId] = rest.reduce((acc, subcategoryId) => {
          acc[subcategoryId] = true;
          return acc;
        }, {});

        return result;
      }, {}),
  },
  r: {
    name: "rank",
    parser: (value) => (isValidNumber(Number(value)) ? Number(value) : 0),
  },
  l: {
    name: "limit",
    parser: (value) => (isValidNumber(Number(value)) ? Number(value) : 24),
  },
  q: {
    name: "name",
    parser: (value) => value,
  },
};

function parseDefaultsFromParams(search) {
  const params = new URLSearchParams(search);

  if (params.size === 0) {
    return defaultFilter;
  }

  const result = {};

  for (const [key, value] of params.entries()) {
    const mappedKey = paramMap[key];

    if (!mappedKey) {
      continue;
    }

    const { name, parser } = mappedKey;

    const parsedValue = parser(value);

    result[name] = parsedValue;
  }

  return D.merge(defaultFilter, result);
}

export function useFilterParams() {
  const location = useLocation();
  const history = useHistory();

  const [filter, setFilter] = React.useState(() =>
    parseDefaultsFromParams(location.search),
  );

  const updateFilterAndSetParams = React.useCallback(
    (updateFnOrFilter) => {
      let updateFn = updateFnOrFilter;

      if (typeof updateFn !== "function") {
        updateFn = () => updateFnOrFilter;
      }

      setFilter((filter) => {
        const newFilter = updateFn(filter);

        const search = Object.entries(newFilter)
          .reduce((result, [key, value]) => {
            const mappedKey = keyMap[key];

            const formatter = formatters[mappedKey] ?? F.identity;
            const formattedValue = formatter(value);

            if (G.isString(formattedValue)) {
              result.set(mappedKey, formattedValue);
            } else if (G.isNumber(formattedValue)) {
              result.set(mappedKey, String(formattedValue));
            } else if (G.isObject(formattedValue)) {
              const values = Object.keys(formattedValue).filter(
                (key) => formattedValue[key],
              );

              if (values.length > 0) {
                result.set(mappedKey, values.join(","));
              }
            } else if (G.isArray(formattedValue)) {
              if (formattedValue.length > 0) {
                result.set(mappedKey, formattedValue.join(","));
              }
            }

            return result;
          }, new URLSearchParams())
          .toString();

        history.replace({ search });

        return newFilter;
      });
    },
    [history],
  );

  return { filter, setFilter: updateFilterAndSetParams };
}

function hasName(name) {
  return G.isString(name) && S.isNotEmpty(name);
}

function hasRank(rank) {
  return G.isNumber(rank) && rank > 0;
}

function paramsFor({
  character,
  franchise,
  category,
  name,
  order,
  type,
  rank,
}) {
  const params = new URLSearchParams({
    order,
  });

  if (hasName(name)) {
    params.set("name", name);
  }

  if (type) {
    const typeArr = Object.keys(type).filter((key) => type[key]);
    if (typeArr.length > 0) {
      params.set("type", typeArr.join(","));
    }
  }

  if (character) {
    const characterArr = Object.keys(character).filter((key) => character[key]);
    if (characterArr.length > 0) {
      params.set("character", characterArr.join(","));
    }
  }

  if (franchise) {
    const franchiseArr = Object.keys(franchise).filter((key) => franchise[key]);
    if (franchiseArr.length > 0) {
      params.set("franchise", franchiseArr.join(","));
    }
  }

  if (category) {
    const formattedValue = formatters.g(category);
    if (formattedValue.length > 0) {
      params.set("category", formattedValue);
    }
  }

  if (hasRank(rank)) {
    params.set("rank", rank);
  }

  return params;
}

export function useShops(filter = defaultFilter) {
  const { data, error, isValidating, mutate } = useSWR(
    `/api/shops/browse?${paramsFor(filter)}`,
    fetcher,
    { keepPreviousData: true },
  );

  const pagination = React.useMemo(() => {
    return data?.pagination || defaultPagination;
  }, [data]);

  return {
    error,
    isError: Boolean(error),
    isLoading: !data && !error,
    isValidating,
    mutate,
    shops: data?.shops || [],
    pagination,
  };
}

export function useFilters() {
  const { data, error, isValidating, mutate } = useSWR(
    `/api/shops/filters`,
    fetcher,
    { keepPreviousData: true },
  );

  const filters = React.useMemo(() => {
    return data?.filters || defaultFilters;
  }, [data]);

  return {
    error,
    filters,
    isError: Boolean(error),
    isLoading: !data && !error,
    isValidating,
    mutate,
  };
}
