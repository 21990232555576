import { Dialog, IconButton } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { D } from "@mobily/ts-belt";
import PropTypes from "prop-types";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { Cart, Influencer, Product } from "../../../../domain";
import { useCart, useCurrentUser } from "../../../../hooks";
import { useEmailModal } from "../../../../services/EmailModal";
import track from "../../../../services/UserTrackingActions";
import { scrollbarStyles } from "../../../../theme";
import { getParameter, localStorageUtils } from "../../../../util";
import ProductModalActions from "./ProductModalActions";
import ProductModalContent from "./ProductModalContent";
import {
  createInitialState,
  productModalContext,
  productModalReducer,
} from "./state";

const noop = (evt) => {
  evt?.preventDefault?.();
  evt?.stopPropagation?.();
};

const ProductModal = React.memo(function ProductModal({
  product,
  open,
  onClose,
  influencer,
  pageRoute,
  pageName,
}) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const history = useHistory();
  const user = useCurrentUser();
  const { refresh } = useCart();
  const { block, unblock } = useEmailModal();

  const [state, dispatch] = React.useReducer(
    productModalReducer,
    createInitialState(product),
  );

  React.useEffect(() => {
    setErrorMessage("");
  }, [state]);

  const value = React.useMemo(() => [state, dispatch], [state, dispatch]);

  React.useEffect(() => {
    block();

    return () => {
      unblock();
    };
  }, [block, unblock]);

  const wrappedOnClose = React.useCallback(
    (...args) => {
      Cart.clear();
      if (influencer?.route) {
        history.replace(`/${influencer.route}`);
      }
      onClose(...args);
    },
    [onClose, history, influencer?.route],
  );

  const handleCheckout = React.useCallback(
    async (evt) => {
      try {
        evt.preventDefault();

        if (isLoading) {
          return;
        }

        if (!influencer && !pageRoute && !pageName) {
          return;
        }

        setIsLoading(true);

        const { notes, personalizationTo } = state;

        const trackingCode =
          getParameter("stc") || localStorageUtils.getWithExpiry("stc");

        const addonsSet = new Set(
          Object.values(state.addons).map(D.get("name")),
        );

        await Cart.clear();

        const [addResult] = await Promise.all([
          Cart.addProduct({
            productId: product.productId,
            personalization: {
              notes,
              personalizationTo,
              personalizationAddonCharacterName: "",
              personalizationAddonQuote: "",
            },
            addons: Array.from(addonsSet),
          }),
          Cart.update({
            influencerId: influencer?.influencerId ?? product.influencerId,
            trackingCode,
          }),
        ]);

        const { errors } = addResult;

        if (Array.isArray(errors) && errors.length) {
          const [err] = errors;
          setErrorMessage(err.message);
          return await Cart.clear();
        }

        await refresh();

        setErrorMessage("");
        setIsLoading(false);
        onClose(() => {
          history.push("/checkout");
        });
      } catch (err) {
        console.error(err);
        setErrorMessage(err.message);
        setIsLoading(false);
      }
    },
    [
      refresh,
      history,
      influencer,
      isLoading,
      onClose,
      pageName,
      pageRoute,
      product.influencerId,
      product.productId,
      state,
    ],
  );

  React.useEffect(() => {
    track({
      userId: user.userId,
      actionType: "product-view",
      actionMetaData: {
        shopInfluencerId: influencer?.influencerId ?? null,
        shopRoute: influencer?.route ?? pageRoute,
        shopName: influencer?.name ?? pageName,
        productId: product.productId,
        productName: product.name,
        productPrice: product.subtotal.value,
      },
    });
  }, [influencer, pageName, pageRoute, product, user]);

  React.useEffect(() => {
    // display url for product displayed
    if (!influencer || !product) {
      return;
    }

    history.replace(`/${influencer.route}/${product.productId}`);
  }, [influencer, product, history]);

  return (
    <productModalContext.Provider value={value}>
      <StyledDialog open={open} onClose={wrappedOnClose}>
        <StyledIconButton onClick={wrappedOnClose} aria-label="Close">
          <CloseIcon />
        </StyledIconButton>

        <form onSubmit={errorMessage ? noop : handleCheckout}>
          <ProductModalContent influencer={influencer} product={product} />
          <ProductModalActions
            product={product}
            errorMessage={errorMessage}
            isLoading={isLoading}
          />
        </form>
      </StyledDialog>
    </productModalContext.Provider>
  );
});

ProductModal.propTypes = {
  product: PropTypes.instanceOf(Product),
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  influencer: PropTypes.instanceOf(Influencer),
  pageRoute: PropTypes.string,
  pageName: PropTypes.string,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": { paddingBottom: 0 },
  "& .MuiPaper-root": {
    borderRadius: theme.spacing(0.5),
    maxWidth: "64rem",
    minHeight: "18rem",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "unset",
      width: "100vw",
      margin: 0,
    },
  },
  "& .MuiDialog-paperScrollPaper": {
    ...scrollbarStyles(theme),
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  left: theme.spacing(0.5),
  top: theme.spacing(0.5),
  zIndex: 10,
}));

export default ProductModal;
