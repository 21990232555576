import { Link, Typography } from "@material-ui/core";
import ContentPage from "../ContentPage";

export default function Returns() {
  return (
    <ContentPage title="Return Policy">
      <Typography variant="caption" color="textSecondary">
        Last updated on July 24th, 2024
      </Typography>

      <p>
        Thank you for choosing Streamily! We hope that you are happy with your
        purchase. If there’s anything we can do to improve your experience,
        please contact us at{" "}
        <Link href="mailto:support@streamily.com">support@streamily.com</Link>.
      </p>

      <h2>Our Exchange & Return Policy</h2>
      <p>
        Please note that all sales are final in the autograph collectible
        business, and items are sold ‘as is’.
      </p>

      <h2>Refunds and Credits</h2>

      <ul>
        <li>
          <p>
            Orders not fulfilled within 90 days of purchase are eligible for a
            full credit, provided the item has not been signed.
          </p>
        </li>
        <li>
          <p>
            Refunds or credits are not offered for orders within the first 90
            days from the date of purchase.
          </p>
        </li>
      </ul>

      <h2>Product Condition and Authenticity</h2>

      <ul>
        <li>
          <p>
            Items may show minor wear or light dents from handling and may vary
            slightly in size from the generalized listing details.
          </p>
        </li>
        <li>
          <p>
            Streamily guarantees the authenticity of the Talent’s autograph.
            Personalization and placement requests (such as names, character
            names, quotes, inscriptions, pen color, and autograph placement) are
            at the Talent’s discretion.
          </p>
        </li>
      </ul>

      <h2>Reporting Issues</h2>

      <p>
        If you encounter any issues with your order, please contact us within 7
        days of receiving the item at{" "}
        <Link href="mailto:support@streamily.com">support@streamily.com</Link>.
        We will make every effort to address any concerns, but please understand
        that certain matters may be beyond our control.
      </p>

      <h2>Returns</h2>

      <ul>
        <li>
          <p>
            Items must be in 100% original condition (no modifications including
            but not limited to framing or mounting) and include all original
            paperwork.
          </p>
        </li>
        <li>
          <p>
            Damaged or lost items not insured will not be replaced or accepted
            for credit.
          </p>
        </li>
        <li>
          <p>
            Items must be returned safely and securely with the original
            receipt. We highly recommend insuring and tracking the item, as we
            cannot be held responsible for loss or damage.
          </p>
        </li>
        <li>
          <p>
            Insured items (that were damaged or lost in shipping) will require
            up to 30 days to process due to insurance carrier regulations. Front
            and back images of the item(s) received, along with the original
            packaging, are required for insurance claims.
          </p>
        </li>
      </ul>

      <h2>Replacements</h2>
      <p>
        Replacements for Streamily products are at the discretion of Streamily.
        If your item arrives at our facility with a missing signature or does
        not pass quality control, we will reach out to you with a replacement or
        credit option.
      </p>

      <h2>Livestream and Meet & Greet Orders</h2>
      <p>
        Streamily is not liable for your Internet connection. Credits or
        exchanges will not be provided for disconnections from Video Chat
        sessions due to connectivity issues, missing the live stream, or being
        removed from the video chat due to inappropriate behavior.
      </p>
      <p>
        If your schedule changes and you are unable to attend a scheduled meet
        and greet, you may request a credit up to 24 hours before your scheduled
        session.
      </p>
    </ContentPage>
  );
}
