import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Slider,
} from "@material-ui/core";
import { Add, LandscapeRounded, PortraitRounded } from "@material-ui/icons";
import { D, G } from "@mobily/ts-belt";
import clsx from "clsx";
import { useAtom } from "jotai";
import PropTypes from "prop-types";
import * as React from "react";
import invariant from "tiny-invariant";
import * as styles from "../styles";
import { getImageFromDropEvent, getImageFromFileInputEvent } from "../utils";
import * as utils from "./utils";

const Cropper = React.lazy(() => import("react-easy-crop"));

export default function ProductImageInput({
  disabled,
  stateAtom,
  productAtom,
}) {
  const [product, setProduct] = useAtom(productAtom);
  const [state, setState] = useAtom(stateAtom);

  const [cropSettings, setCropSettings] = React.useState({});
  const [isEditing, setIsEditing] = React.useState(false);
  const [imageFile, setImageFile] = React.useState(null);
  const [imageSrc, setImageSrc] = React.useState(null);
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const [mode, setMode] = React.useState("portrait");
  const [isDragOver, setIsDragOver] = React.useState(false);

  const reset = React.useCallback(() => {
    setCrop({ x: 0, y: 0 });
    setZoom(1);
  }, []);

  const onCropComplete = React.useCallback(async (_, croppedAreaPixels) => {
    setCropSettings(croppedAreaPixels);
  }, []);

  const onDrop = React.useCallback(
    async (evt) => {
      evt.preventDefault();

      const file = getImageFromDropEvent(evt);
      const fileUrl = await utils.readFileToUrl(file);

      reset();
      setImageSrc(fileUrl);
      setImageFile(file);
      setIsEditing(true);
      setIsDragOver(false);
      setState(D.merge({ hasUnsavedChanges: true }));
    },
    [setState, reset],
  );

  const onFileInputChange = React.useCallback(
    async (evt) => {
      const file = getImageFromFileInputEvent(evt);
      const fileUrl = await utils.readFileToUrl(file);

      reset();
      setImageSrc(fileUrl);
      setImageFile(file);
      setIsEditing(true);
      setState(D.merge({ hasUnsavedChanges: true }));
    },
    [setState, reset],
  );

  const onSave = async () => {
    try {
      setState(D.merge({ isLoading: true }));

      invariant(G.isNumber(cropSettings.x), "Missing cropSettings.x");
      invariant(G.isNumber(cropSettings.y), "Missing cropSettings.y");
      invariant(G.isNumber(cropSettings.width), "Missing cropSettings.width");
      invariant(G.isNumber(cropSettings.height), "Missing cropSettings.height");

      await utils.uploadImage({
        cropSettings,
        imageFile,
        product,
        setProduct,
        setState,
      });

      setState(D.merge({ isLoading: false, hasUnsavedChanges: false }));
      reset();
      setIsEditing(false);
    } catch (err) {
      setState(D.merge({ isLoading: false }));
      console.error(err);
    }
  };

  const hasPicture = Boolean(product.imageUrl);
  const shouldDisable = Boolean(disabled) || !imageSrc || state.isLoading;

  return (
    <Box display="flex" flexDirection="column" gridGap="8px">
      <styles.Label>Image</styles.Label>

      {!isEditing && product.imageUrl && (
        <img
          height={265}
          src={product.imageUrl}
          style={{
            maxWidth: "100%",
            objectFit: "contain",
            border: isDragOver ? "2px dashed #1096E7" : "2px solid transparent",
          }}
          onDragLeave={() => setIsDragOver(false)}
          onDragOver={(evt) => {
            evt.preventDefault();
            setIsDragOver(true);
          }}
          onDrop={onDrop}
        />
      )}

      {isEditing && (
        <React.Suspense
          fallback={
            <img
              height={265}
              src={product.imageUrl}
              style={{ maxWidth: "100%", objectFit: "contain" }}
            />
          }
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <styles.FileLabel
              className={clsx(isDragOver && "drag-over")}
              onDragLeave={() => setIsDragOver(false)}
              onDragOver={(evt) => {
                evt.preventDefault();
                setIsDragOver(true);
              }}
              onDrop={onDrop}
            >
              <Cropper
                aspect={utils.aspectRatioFor(product)[mode] ?? 1}
                crop={crop}
                image={imageSrc}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                zoom={zoom}
                maxZoom={3}
              />
            </styles.FileLabel>
            <Box
              display="flex"
              gridGap="8px"
              maxWidth="33vh"
              minWidth={300}
              mt={1}
              width="100%"
            >
              <Slider
                color="secondary"
                value={zoom}
                onChange={(_, zoom) => {
                  if (!Number.isFinite(zoom)) return;
                  setZoom(zoom);
                }}
                max={3}
                min={1}
                step={0.1}
                disabled={shouldDisable}
              />

              <IconButton
                disabled={shouldDisable}
                size="small"
                onClick={() => {
                  setMode(mode === "portrait" ? "landscape" : "portrait");
                }}
              >
                {mode === "portrait" ? (
                  <PortraitRounded fontSize="small" />
                ) : (
                  <LandscapeRounded fontSize="small" />
                )}
              </IconButton>
            </Box>
          </Box>
        </React.Suspense>
      )}

      <Box
        alignItems="center"
        display="flex"
        gridGap="8px"
        justifyContent="center"
        width="100%"
      >
        <Button
          className="product-image-upload-text"
          component="label"
          htmlFor="product-image-upload-input"
          size="small"
        >
          <Add fontSize="small" />
          <span>
            {hasPicture
              ? "Change product image"
              : "Add an image of your product"}
          </span>

          <input
            accept="image/png, image/jpg, image/jpeg"
            disabled={Boolean(disabled)}
            id="product-image-upload-input"
            onChange={onFileInputChange}
            style={{ display: "none" }}
            type="file"
          />
        </Button>

        {isEditing && (
          <Button
            disabled={state.isLoading}
            size="small"
            onClick={() => {
              setImageSrc(null);
              setIsEditing(false);
            }}
          >
            Cancel
          </Button>
        )}

        {isEditing && (
          <Button
            disabled={state.isLoading}
            color="secondary"
            onClick={onSave}
            size="small"
            variant="contained"
          >
            {!state.isLoading && "Save"}
            {state.isLoading && <CircularProgress size="1rem" />}
          </Button>
        )}
      </Box>
    </Box>
  );
}

ProductImageInput.propTypes = {
  disabled: PropTypes.bool,
  productAtom: PropTypes.object.isRequired,
  stateAtom: PropTypes.object.isRequired,
};
