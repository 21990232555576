import { CircularProgress } from "@material-ui/core";
import { D } from "@mobily/ts-belt";
import PropTypes from "prop-types";
import * as React from "react";
import { Addon } from "../../../../domain";
import { VipAddon } from "../../../../domain/addon";
import { useCurrentUser } from "../../../../hooks";
import { StreamilyButton } from "../../../../theme";
import { updateAddons, useProductModal } from "./state";

const ProductModalAddonButton = React.memo(function ProductModalAddonButton({
  addon,
}) {
  const [state, dispatch] = useProductModal();
  const currentUser = useCurrentUser();

  const has = Boolean(state?.addons[addon.name]);

  const onClick = React.useCallback(() => {
    if (has) {
      return dispatch(updateAddons(D.deleteKey(state.addons, addon.name)));
    }

    dispatch(updateAddons(D.set(state.addons, addon.name, addon)));
  }, [has, dispatch, state.addons, addon]);

  const disabled =
    addon.name === VipAddon.TYPE && currentUser.isStreamilyFamilyMember;

  React.useEffect(() => {
    if (
      addon.name === VipAddon.TYPE &&
      currentUser.isStreamilyFamilyMember &&
      !state.addons[addon.name]
    ) {
      dispatch(updateAddons(D.set(state.addons, addon.name, addon)));
    }
  }, [addon, currentUser, dispatch, state.addons]);

  return (
    <StreamilyButton
      size="small"
      variant="contained"
      color="primary"
      className={has ? "offline" : ""}
      onClick={onClick}
      disabled={disabled}
      style={{ minHeight: "2.5rem" }}
    >
      {contentFor({
        disabled,
        disabledText: "Included with VIP",
        isAdded: has,
      })}
    </StreamilyButton>
  );
});

ProductModalAddonButton.propTypes = {
  addon: PropTypes.instanceOf(Addon).isRequired,
};

function contentFor({ disabled, disabledText, isLoading, isAdded }) {
  if (isLoading) {
    return <CircularProgress size="1.5rem" />;
  }

  if (disabled) {
    return disabledText;
  }

  if (isAdded) {
    return "Remove";
  }

  return "Add";
}

export default ProductModalAddonButton;
