import useSWR from "swr";
import fetcher from "./fetcher";

const defaultCategories = {};

export default function useCategories() {
  const { data, isLoading, error, mutate } = useSWR(
    "/api/influencer/categories",
    fetcher,
  );

  return {
    categories: data?.categories ?? defaultCategories,
    isLoading,
    isError: Boolean(error),
    error,
    mutate,
  };
}
