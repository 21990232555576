import { Box, Collapse, List } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DashboardRounded } from "@material-ui/icons";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import AssignmentIndOutlinedIcon from "@material-ui/icons/AssignmentIndOutlined";
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import CalendarIcon from "@material-ui/icons/CalendarToday";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FindInPageOutlinedIcon from "@material-ui/icons/FindInPageOutlined";
import FormatListNumberedRtlIcon from "@material-ui/icons/FormatListNumberedRtl";
import LinkOutlinedIcon from "@material-ui/icons/LinkOutlined";
import ListOutlinedIcon from "@material-ui/icons/ListOutlined";
import LiveHelpOutlinedIcon from "@material-ui/icons/LiveHelpOutlined";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import LockOpenOutlinedIcon from "@material-ui/icons/LockOpenOutlined";
import MoreOutlinedIcon from "@material-ui/icons/MoreOutlined";
import PublishIcon from "@material-ui/icons/Publish";
import QueueIcon from "@material-ui/icons/Queue";
import ReceiptIcon from "@material-ui/icons/Receipt";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import ShopOutlinedIcon from "@material-ui/icons/ShopOutlined";
import StarsIcon from "@material-ui/icons/Stars";
import StyleIcon from "@material-ui/icons/Style";
import CategoryIcon from "@material-ui/icons/Category";
import ViewComfyIcon from "@material-ui/icons/ViewComfy";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import styled from "styled-components";
import CheckmarkIcon from "../../assets/checkmark-icon.png";
import { useUser } from "../../globalState";
import useLocalStorage from "../../hooks/useLocalStorage";
import { StreamilyListLink } from "../../theme";
import { featureFlags } from "../../util";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "#333",
    width: 230,
  },
  listItem: {
    padding: "0.5rem 1rem",
  },
  smallText: {
    fontSize: "0.9rem",
    color: "#333",
  },
  nested: {
    marginLeft: theme.spacing(1),
  },
}));

export default function AccountActions({ py = 0, pt = 0, px = 0, onClick }) {
  const [open, setOpen] = useLocalStorage("ACCOUNT_ADMIN_OPEN", false);
  const user = useUser();

  const handleClick = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    setOpen(!open);
  };

  const styles = useStyles();

  return (
    <Box pt={pt} px={px} py={py} className={styles.root} onClick={onClick}>
      <List style={{ width: "100%" }}>
        {user.isAffiliate && (
          <StreamilyListLink
            exact
            to="/account-dashboard/affiliate"
            label="Affiliate Portal"
            startIcon={<DashboardRounded fontSize="small" />}
          />
        )}

        <StreamilyListLink
          exact
          to="/account-dashboard/orders"
          label="My Orders"
          startIcon={<ListOutlinedIcon fontSize="small" />}
        />

        <StreamilyListLink
          exact
          to="/account-dashboard/wishlist"
          label="Wishlist"
          startIcon={<StarsIcon fontSize="small" />}
        />

        {(user.isAdmin || user.isSuperadmin) && (
          <>
            <StreamilyListLink
              className={open ? "active" : ""}
              onClick={handleClick}
              label="Admin Tools"
              startIcon={
                open ? (
                  <ExpandLess fontSize="small" />
                ) : (
                  <ExpandMore fontSize="small" />
                )
              }
            />
            <Collapse
              in={open}
              timeout="auto"
              unmountOnExit
              className={styles.nested}
            >
              <StreamilyListLink
                to="/account-dashboard/accessLinks"
                label="Access Links"
                startIcon={<LinkOutlinedIcon fontSize="small" />}
              />

              <StreamilyListLink
                to="/account-dashboard/accountApproval"
                label="Account Approval"
                startIcon={<AccountBoxIcon fontSize="small" />}
              />

              <StreamilyListLink
                to="/account-dashboard/admin-query"
                label="Admin Query"
                startIcon={<ViewComfyIcon fontSize="small" />}
              />

              {user.isSuperadmin && (
                <StreamilyListLink
                  to="/account-dashboard/autopay-list"
                  label="Autopay Shops"
                  startIcon={<ShopOutlinedIcon fontSize="small" />}
                />
              )}

              <StreamilyListLink
                to="/account-dashboard/categories"
                label="Categories"
                startIcon={<CategoryIcon fontSize="small" />}
              />

              <StreamilyListLink
                to="/account-dashboard/email-lists"
                label="Email Lists"
                startIcon={<AllInboxIcon fontSize="small" />}
              />

              <StreamilyListLink
                to={`/account-dashboard/event-calendar/month/${moment(
                  new Date(),
                )
                  .tz("UTC")
                  .format("YYYY-MM-DD")}`}
                label="Event Calendar"
                startIcon={<CalendarIcon fontSize="small" />}
              />

              {user.isSuperadmin && (
                <StreamilyListLink
                  to="/account-dashboard/payouts"
                  label="Payouts"
                  startIcon={
                    <AccountBalanceWalletOutlinedIcon fontSize="small" />
                  }
                />
              )}

              <StreamilyListLink
                to="/account-dashboard/print-boxes"
                label="Print Boxes"
                startIcon={<LocalShippingIcon />}
              />

              <StreamilyListLink
                to="/account-dashboard/productApproval"
                label="Product Approval"
                startIcon={<AssignmentTurnedInOutlinedIcon fontSize="small" />}
              />

              <StreamilyListLink
                to="/account-dashboard/products"
                label="Product Search"
                startIcon={<FindInPageOutlinedIcon fontSize="small" />}
              />

              <StreamilyListLink
                to="/account-dashboard/productTypes"
                label="Product Type Assignment"
                startIcon={<AssignmentTurnedInOutlinedIcon fontSize="small" />}
              />

              <StreamilyListLink
                to="/account-dashboard/promoCodeManager"
                label="Promo Code Manager"
                startIcon={<ReceiptIcon fontSize="small" />}
              />

              {featureFlags.REPORTS_ENABLED && (
                <StreamilyListLink
                  to="/account-dashboard/reports"
                  label="Reports"
                  startIcon={<ListOutlinedIcon fontSize="small" />}
                />
              )}

              <StreamilyListLink
                to="/account-dashboard/admin"
                label="Search Orders"
                startIcon={<SearchOutlinedIcon fontSize="small" />}
              />

              <StreamilyListLink
                to="/account-dashboard/shippingAssistant"
                label="Shipping Assistant"
                startIcon={<CustomIcon src={CheckmarkIcon} />}
              />

              <StreamilyListLink
                to="/account-dashboard/tag-approval"
                label="Tag Approval"
                startIcon={<MoreOutlinedIcon fontSize="small" />}
              />

              {user.isSuperadmin && (
                <StreamilyListLink
                  to="/account-dashboard/tag-manager"
                  label="Tag Manager"
                  startIcon={<MoreOutlinedIcon fontSize="small" />}
                />
              )}

              <StreamilyListLink
                to="/account-dashboard/talent-coordinators"
                label="Talent Coordinators"
                startIcon={<AssignmentIndOutlinedIcon fontSize="small" />}
              />

              <StreamilyListLink
                to="/account-dashboard/userActions"
                label="User Actions"
                startIcon={<FormatListNumberedRtlIcon fontSize="small" />}
              />

              <StreamilyListLink
                to="/account-dashboard/users"
                label="Users Search"
                startIcon={<EmojiPeopleIcon fontSize="small" />}
              />

              <StreamilyListLink
                to="/account-dashboard/waitlists"
                label="Waitlists"
                startIcon={<QueueIcon fontSize="small" />}
              />

              <StreamilyListLink
                to="/account-dashboard/wishlisted-products"
                label="Wishlisted Products"
                startIcon={<StarsIcon fontSize="small" />}
              />

              {user.isSuperadmin && (
                <StreamilyListLink
                  to="/account-dashboard/verticals"
                  label="Verticals"
                  startIcon={<StyleIcon fontSize="small" />}
                />
              )}

              {user.isSuperadmin && (
                <StreamilyListLink
                  to="/account-dashboard/upload-orders"
                  label="Upload Orders"
                  startIcon={<PublishIcon fontSize="small" />}
                />
              )}
            </Collapse>
          </>
        )}

        <StreamilyListLink
          to="/account-dashboard/my-favorites"
          label="My Favorites"
          startIcon={<FavoriteBorderIcon fontSize="small" />}
        />

        {user?.shops?.length > 0 && (
          <StreamilyListLink
            to="/account-dashboard/notifications"
            label="Notifications"
            startIcon={<NotificationsIcon fontSize="small" />}
          />
        )}

        <StreamilyListLink
          to="/account-dashboard/change-password"
          label="Change Password"
          startIcon={<LockOpenOutlinedIcon fontSize="small" />}
        />

        <StreamilyListLink
          href="/FAQ"
          label="FAQ"
          startIcon={<LiveHelpOutlinedIcon fontSize="small" />}
        />

        <StreamilyListLink
          to="/logout"
          label="Log Out"
          startIcon={<ExitToAppOutlinedIcon fontSize="small" />}
        />
      </List>
    </Box>
  );
}

AccountActions.propTypes = {
  p: PropTypes.number,
  onClick: PropTypes.func,
};

const CustomIcon = styled.img`
  width: 20px;
  height: 20px;
`;
