import {
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { A, pipe } from "@mobily/ts-belt";
import PropTypes from "prop-types";
import * as React from "react";
import useSWR from "swr";
import { fetcher } from "../../hooks";
import { StyledTextButton } from "../../theme";
import CategoryTree from "./CategoryTree";
import { Button as StyledButton, StyledDialog } from "./styles";

export default function ShopCategoryModal({ influencerId }) {
  const [open, setOpen] = React.useState(false);

  const { data: associatedData, mutate } = useSWR(
    open ? `/api/categories/influencer/${influencerId}` : null,
    fetcher,
  );
  const { data: allCategories } = useSWR(
    open ? `/api/categories?all=true` : null,
    fetcher,
  );

  const associatedIds = React.useMemo(
    () =>
      pipe(
        associatedData?.categories ?? [],
        A.map((c) => c.category_id),
      ),
    [associatedData?.categories],
  );

  const handleToggle = React.useCallback(
    async (categoryIds) => {
      try {
        const res = await fetch(`/api/categories/influencer/${influencerId}`, {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ categoryIds }),
        });

        if (!res.ok) {
          throw new Error("Failed to update categories");
        }

        mutate();
      } catch (err) {
        console.error(err);
      }
    },
    [influencerId, mutate],
  );

  return (
    <>
      <StyledTextButton
        color="secondary"
        onClick={() => {
          setOpen(true);
        }}
      >
        Edit Categories
      </StyledTextButton>

      <StyledDialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        scroll="paper"
      >
        <DialogTitle>Manage Shop Categories</DialogTitle>

        <DialogContent>
          <Typography variant="body2">
            Set the categories for the shop. You can select multiple categories.
            This helps users to discover talent on our platform through the
            search and browse shops features.
          </Typography>

          <br />

          {!allCategories || !associatedData ? (
            <Box display="flex" justifyContent="center" my={3}>
              <CircularProgress />
            </Box>
          ) : (
            <CategoryTree
              nodes={allCategories.categories}
              onToggle={handleToggle}
              selected={associatedIds}
              mutate={mutate}
            />
          )}

          <br />
        </DialogContent>

        <DialogActions>
          <StyledButton
            onClick={() => {
              setOpen(false);
            }}
          >
            Close
          </StyledButton>
        </DialogActions>
      </StyledDialog>
    </>
  );
}

ShopCategoryModal.propTypes = {
  influencerId: PropTypes.number.isRequired,
};
