import { Button, Hidden, IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { NavLink, useHistory, withRouter } from "react-router-dom";
import * as CONFIG from "../../config";
import getLoginRoute from "../../domain/loginRoute";
import { useStateValue } from "../../globalState";
import { useShrinkBuffer } from "../../hooks";
import AccountDashboardMenu from "../AccountDashboardMenu";
import AuthModal from "../AuthModal";
import Search from "../Browse/Search";
import MobileSidebarModal from "../MobileSidebarModal";
import SiteNotice from "../SiteNotice";

const Navbar = React.memo(function Navbar() {
  const history = useHistory();
  const [{ user, triggerAuthModal }] = useStateValue();

  const [showAuthModal, setShowAuthModal] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isScrolled, setIsScrolled] = React.useState(false);

  // mobile sidebar vars
  const [showMobileSidebar, setShowMobileSidebar] = useState(false);
  const { shrinkBuffer, shouldShrink } = useShrinkBuffer();

  const CloseAuthModal = (user) => {
    setShowAuthModal(false);
    setShowMobileSidebar(false);

    if (!user || !Array.isArray(user?.roles)) {
      return;
    }

    const route = getLoginRoute(user);
    if (route) history.push(route);
    else window.location.reload(); // is this necessary anymore? TODO
  };

  const Login = () => {
    setShowAuthModal(true);
    setIsLoggingIn(true);
  };

  useEffect(() => {
    if (triggerAuthModal) {
      if (triggerAuthModal.type.toLowerCase() === "signup") {
        setIsLoggingIn(false);
      } else {
        setIsLoggingIn(true);
      }
      setShowAuthModal(true);
    }
  }, [triggerAuthModal]);

  useEffect(() => {
    const scrollHandler = (() => {
      let [currentScroll, lastScroll, isScrollingUp] = [0, 0, false];

      return () => {
        if (window.innerHeight < 800) {
          return;
        }

        if (currentScroll > lastScroll) {
          isScrollingUp = false;
        } else {
          isScrollingUp = true;
        }

        lastScroll = currentScroll;
        currentScroll = window.scrollY;

        window.requestAnimationFrame(() => {
          const navbar = document.getElementById("navbar");
          if (isScrollingUp) {
            navbar.classList.remove("hidden");
            setIsScrolled(false);
          } else if (window.scrollY > 800) {
            navbar.classList.add("hidden");
            setIsScrolled(true);
          } else {
            navbar.classList.remove("hidden");
            setIsScrolled(false);
          }
        });
      };
    })();

    scrollHandler();
    document.addEventListener("scroll", scrollHandler);
    return () => {
      document.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  return (
    <>
      <div
        id="navbar"
        className={clsx("navbar__container", shouldShrink && "shrink")}
      >
        <NavLink to="/" className="navbar__left">
          <img
            className="navbar__logo"
            width="108"
            height="40"
            alt="logo"
            src={CONFIG.SITE_LOGO}
          />
        </NavLink>

        <Hidden xsDown>
          <Search isScrolled={isScrolled} />
        </Hidden>

        <div className="navbar__right">
          {!shrinkBuffer &&
            !user.is_approval_pending &&
            user.show_dashboard_link && (
              <Button
                component={NavLink}
                title="Dashboard"
                to="/Dashboard/MyDashboard"
              >
                Dashboard
              </Button>
            )}

          {!shrinkBuffer && !user.is_approval_pending && user.user_id ? (
            <AccountDashboardMenu user={user} />
          ) : shrinkBuffer && !user.is_approval_pending && user.user_id ? (
            <IconButton
              onClick={() => setShowMobileSidebar(!showMobileSidebar)}
            >
              <MenuIcon />
            </IconButton>
          ) : user.is_approval_pending ? (
            <>
              <Button
                component={NavLink}
                title="My Pending Dashboard"
                to="/talent/pending"
              >
                Dashboard
              </Button>
              <AccountDashboardMenu user={user} />
            </>
          ) : (
            <Button className="login-button" onClick={Login}>
              Login
            </Button>
          )}
        </div>

        {shouldShrink ? null : (
          <Hidden smUp>
            <Search isScrolled={isScrolled} className="span-2" />
          </Hidden>
        )}
      </div>

      {showAuthModal && (
        <AuthModal onClose={CloseAuthModal} isLoggingIn={isLoggingIn} />
      )}

      {showMobileSidebar && <MobileSidebarModal onClose={CloseAuthModal} />}

      <SiteNotice />
    </>
  );
});

export default withRouter(Navbar);
