export default function sendSessionEvent({ type, payload }) {
  fetch("/api/user/se", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({ type, payload }),
  })
    .then((res) => {
      if (!res.ok) throw new Error("Failed to send session event");
    })
    .catch(console.error);
}
