import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import * as React from "react";
import * as constants from "../../components/Home/constants";
import ShopsSlider from "../../components/Home/Section/ShopsSlider";
import { useMatchesSmDown, useRecomendedShops } from "../../hooks";
import { useYouMightLike } from "../../hooks/useInfluencer";

export default function Recommendations({ route }) {
  const { influencers: recommendedShops } = useRecomendedShops({ route });
  const { influencers: youMightLike } = useYouMightLike({ route });
  const isMobile = useMatchesSmDown();
  const boxEl = React.useRef(null);
  const [boxWidth, setBoxWidth] = React.useState(0);

  const updateWidth = React.useCallback(() => {
    if (!boxEl.current) {
      return;
    }

    setBoxWidth(() => {
      const { width } = boxEl.current.getBoundingClientRect();
      return width;
    });
  }, []);

  return (
    <Box
      mt={4}
      mb={8}
      ref={boxEl}
      display="flex"
      flexDirection="column"
      gridGap="24px"
    >
      {youMightLike?.length > 0 && (
        <>
          <Typography variant="h5">You might like...</Typography>
          <ShopsSlider
            influencers={youMightLike}
            isMobile={isMobile}
            isDark={false}
            numberPerRow={6}
            updateWidth={updateWidth}
            look={constants.LOOK_WIDE}
            count={true}
          />
        </>
      )}

      {recommendedShops?.length > 0 && (
        <>
          <Typography variant="h5">Recommended Shops</Typography>
          <ShopsSlider
            influencers={recommendedShops}
            isMobile={isMobile}
            isDark={false}
            numberPerRow={6}
            updateWidth={updateWidth}
            count={true}
            recommendedShops={true}
          />
        </>
      )}
    </Box>
  );
}

Recommendations.propTypes = {
  route: PropTypes.string.isRequired,
};
