import PropTypes from "prop-types";
import HeroShops from "../../HeroShops";
import PromoTabs from "../Main/PromoTabs";

export default function Hero() {
  return (
    <>
      <HeroShops />
      <PromoTabs />
    </>
  );
}

Hero.propTypes = {
  isMobile: PropTypes.bool,
};
