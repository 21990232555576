import { Box, Grid, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import PropTypes from "prop-types";
import * as React from "react";
import { Influencer } from "../../../domain";
import { useCurrentUser, useInfluencer } from "../../../hooks";
import { Form, SoftMediumCard, StyledTextButton } from "../../../theme";
import { capitalizeWord } from "../../../util";
import ShopCategories from "../../ShopCategories";
import ImageAvatar from "../../ImageAvatar";
import SocialHandles from "../../SocialHandles";
import UpcomingSignings, { loadingUI } from "../../UpcomingSignings";
import VideoAvatarEditor from "../../VideoAvatarEditor";
import SocialHandleModal from "../SocialHandleModal";
import AddEventButton from "./AddEventButton";
import Description from "./Description";
import ShopStatus from "./ShopStatus";

const options = ["hidden", "fast", "moderate", "slow"];

const ShopBuilderHeader = React.memo(function ShopBuilderHeader({
  route,
  setEditingAvatar,
  setEditingSettings,
}) {
  const currentUser = useCurrentUser();
  const [open, setOpen] = React.useState(false);

  const onClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const { influencer, isLoading, isError, refresh } = useInfluencer({
    route,
    forShopBuilder: true,
  });

  const [fulfillmentSpeed, setFulfillmentSpeed] = React.useState(
    influencer?.fulfillmentSpeed,
  );

  const handleChange = async (evt) => {
    const newValue = evt.target.value;
    setFulfillmentSpeed(newValue);

    const updates = { fulfillment_speed: newValue };

    Influencer.update({ influencer, updates }).then(() => refresh());
  };

  React.useEffect(() => {
    setFulfillmentSpeed(influencer?.fulfillmentSpeed);
  }, [influencer?.fulfillmentSpeed]);

  return (
    <SoftMediumCard>
      <Grid container spacing={3}>
        <Grid item xs={6} sm="auto">
          <ImageAvatar
            disableShadow
            influencer={influencer}
            height={192}
            width={144}
            onClick={() => setEditingAvatar(true)}
          />

          <Box pt={1}>
            <StyledTextButton
              size="small"
              color="secondary"
              onClick={() => setEditingAvatar(true)}
            >
              Edit Account Photo
            </StyledTextButton>
          </Box>
        </Grid>

        <Grid item xs={6} sm="auto">
          {!influencer && <Skeleton width={143} height={192} variant="rect" />}

          {Boolean(influencer) && (
            <VideoAvatarEditor influencerId={influencer.influencerId} />
          )}
        </Grid>

        <Grid item container xs={12} sm direction="column" spacing={1}>
          <Description
            influencer={influencer}
            isLoading={isLoading}
            isError={isError}
            refresh={refresh}
          />

          {Boolean(influencer?.productTagString) && (
            <Grid item>
              <Typography variant="h6">Shop Hashtag</Typography>
              <Typography gutterBottom variant="subtitle1" component="p">
                #{influencer.productTagString}
              </Typography>
            </Grid>
          )}

          <Grid item xs container spacing={2} alignItems="center">
            <Grid item>
              <SocialHandles handles={influencer?.handles?.list ?? []} />
            </Grid>

            <Grid item>
              <Box display="flex" gridGap="16px" alignItems="center">
                <SocialHandleModal route={route} />

                {influencer?.influencerId && !influencer?.isGroupShop && (
                  <ShopCategories
                    open={open}
                    onClose={onClose}
                    influencerId={influencer?.influencerId}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container direction="column" xs={12} md={4}>
          <Grid item container justifyContent="flex-end">
            <Box pb={3}>
              <ShopStatus route={route} />
            </Box>
          </Grid>

          <Grid item>
            {!influencer && loadingUI}

            {Boolean(influencer) && (
              <UpcomingSignings
                influencerId={influencer.influencerId}
                alignItems="flex-end"
                allowEdit={!influencer.isGroupShop}
              />
            )}
          </Grid>

          <Grid item container justifyContent="flex-end">
            {Boolean(influencer) && (
              <Box pt={0.5} pb={2}>
                <AddEventButton influencer={influencer} refresh={refresh} />
              </Box>
            )}
          </Grid>

          {currentUser.isAdmin && (
            <Grid
              item
              container
              justifyContent="flex-end"
              alignItems="flex-end"
              direction="column"
            >
              <Typography variant="caption" color="textSecondary">
                Fulfillment Speed
              </Typography>

              <Form.FancySelect
                aria-label="Set Fulfillment Speed"
                id="fulfillmentSpeed"
                size="small"
                variant="outlined"
                value={fulfillmentSpeed}
                onChange={handleChange}
                options={options}
                getOptionLabel={capitalizeWord}
              />
            </Grid>
          )}

          <Grid
            item
            container
            alignItems="flex-end"
            justifyContent="flex-end"
            style={{ flexGrow: 1 }}
          >
            <Box mt={1}>
              <StyledTextButton
                size="small"
                onClick={() => setEditingSettings(true)}
              >
                Advanced Settings
              </StyledTextButton>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </SoftMediumCard>
  );
});

ShopBuilderHeader.propTypes = {
  influencer: PropTypes.instanceOf(Influencer),
  route: PropTypes.string,
  setEditingAvatar: PropTypes.func,
  setEditingSettings: PropTypes.func,
};

export default ShopBuilderHeader;
