import { Box, Slide, Typography } from "@material-ui/core";
import { D } from "@mobily/ts-belt";
import { atom, useAtom } from "jotai";
import PropTypes from "prop-types";
import * as React from "react";
import { SignType } from "../../domain";
import { useActiveInfluencer } from "../../hooks";
import { DialogTitle } from "../../theme";
import ShopBuilderProductModalActions from "./Actions";
import LivestreamWarning from "./LivestreamWarning";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import { StyledDialog } from "./styles";
import {
  agentsFromArray,
  createInitialState,
  createWorkingProduct,
  createWorkingSigners,
  getDefaultProductTypes,
  STEPS,
} from "./utils";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Modal({
  hasLivestreamWarning,
  onClose,
  product,
  productTypes,
  signedTypes,
}) {
  const activeInfluencer = useActiveInfluencer();

  const {
    defaultProductType,
    defaultShoutout,
    defaultDomestic,
    defaultInternational,
    defaultAudioShoutout,
  } = React.useMemo(
    () => getDefaultProductTypes({ productTypes, activeInfluencer }),
    [productTypes, activeInfluencer],
  );

  const {
    productAtom,
    stateAtom,
    productSignersAtom,
    productTagsAtom,
    productCharactersAtom,
    productAgentsAtom,
  } = React.useMemo(() => {
    return {
      productAtom: atom(
        createWorkingProduct({
          product,
          defaultProductType,
          activeInfluencer,
        }),
      ),
      stateAtom: atom(createInitialState(product)),
      productSignersAtom: atom(
        createWorkingSigners({ product, activeInfluencer }),
      ),
      productTagsAtom: atom(product?.tags ?? []),
      productCharactersAtom: atom(product?.characters ?? {}),
      productAgentsAtom: atom(agentsFromArray(product?.agents)),
    };
  }, [product, defaultProductType, activeInfluencer]);

  const [state, setState] = useAtom(stateAtom);

  const { Component, title } = React.useMemo(() => {
    switch (state.currentStep) {
      case STEPS.CREATE_PRODUCT:
        return Step1;

      case STEPS.PRODUCT_DETAILS:
        return Step2;

      case STEPS.SPLITS:
        return Step3;

      case STEPS.INCREASE_EXPOSURE:
        return Step4;

      default: // STEPS.PRODUCT_SUMMARY
        return Step5;
    }
  }, [state.currentStep]);

  const onCloseRef = React.useRef(null);

  const handleClose = React.useCallback(
    (props) => {
      onCloseRef.current = props;
      setState(D.merge({ isOpen: false }));
    },
    [setState],
  );

  const transitionProps = React.useMemo(() => {
    return {
      onExited: () => {
        if (onCloseRef.current) {
          onClose(onCloseRef.current);
        }
      },
    };
  }, [onClose]);

  const showLivestreamWarning =
    hasLivestreamWarning &&
    state.isEditing &&
    product.signedType === SignType.livestream;

  return (
    <StyledDialog
      open={state.isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
      transitionDuration={300}
      TransitionProps={transitionProps}
    >
      <DialogTitle
        TypographyProps={{ style: { fontWeight: 800 } }}
        align="left"
        onClose={handleClose}
      >
        {title}
      </DialogTitle>

      {state.hasError && (
        <Box px={3} mb={1} mt={-2}>
          <Typography
            variant="subtitle2"
            color="error"
            role="alert"
            onClick={() => {
              setState(D.merge({ hasError: false, errorMessage: "" }));
            }}
          >
            {state.errorMessage}
          </Typography>
        </Box>
      )}

      {showLivestreamWarning && <LivestreamWarning />}

      <Box pl={3} pr={3}>
        <Component
          defaultAudioShoutout={defaultAudioShoutout}
          defaultProductType={defaultProductType}
          defaultShoutout={defaultShoutout}
          onClose={handleClose}
          productAgentsAtom={productAgentsAtom}
          productAtom={productAtom}
          productCharactersAtom={productCharactersAtom}
          productSignersAtom={productSignersAtom}
          productTagsAtom={productTagsAtom}
          productTypes={productTypes}
          signedTypes={signedTypes}
          stateAtom={stateAtom}
        />
      </Box>

      <Box p={3}>
        <ShopBuilderProductModalActions
          defaultDomesticProductType={defaultDomestic}
          defaultInternationalProductType={defaultInternational}
          onClose={handleClose}
          productAgentsAtom={productAgentsAtom}
          productAtom={productAtom}
          productCharactersAtom={productCharactersAtom}
          productSignersAtom={productSignersAtom}
          productTagsAtom={productTagsAtom}
          stateAtom={stateAtom}
        />
      </Box>
    </StyledDialog>
  );
}

Modal.propTypes = {
  hasLivestreamWarning: PropTypes.bool,
  onClose: PropTypes.func,
  product: PropTypes.shape({
    active: PropTypes.bool,
    allowNotes: PropTypes.bool,
    allowPersonalization: PropTypes.bool,
    disableVip: PropTypes.bool,
    characterTagString: PropTypes.string,
    convention: PropTypes.bool,
    fixedBump: PropTypes.bool,
    franchiseTagString: PropTypes.string,
    imageUrl: PropTypes.string,
    influencerId: PropTypes.number,
    name: PropTypes.string,
    originalImageUrl: PropTypes.string,
    productBump: PropTypes.number,
    productId: PropTypes.number,
    productTypeId: PropTypes.number,
    productType: PropTypes.string,
    signedType: PropTypes.string,
    stock: PropTypes.number,
    subtext: PropTypes.string,
    characters: PropTypes.object,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        tagString: PropTypes.string,
      }),
    ),
    agents: PropTypes.object,
  }),
  productTypes: PropTypes.arrayOf(
    PropTypes.shape({
      productTypeId: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  signedTypes: PropTypes.arrayOf(PropTypes.string),
};

export default React.memo(Modal);
