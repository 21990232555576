import clsx from "clsx";
import PropTypes from "prop-types";
import * as React from "react";
import { Influencer } from "../../../domain";
import { PreloadLink } from "../../../theme";
import * as constants from "../constants";
import LikeButton from "./LikeButton";
import UpcomingEvent from "./UpcomingEvent";

export default function ShopCard({
  className,
  data: influencer,
  isDark = true,
  isExpanded,
  isMobile,
  look = constants.LOOK_TALL,
  position = "absolute",
  recommendedShops,
  style,
  updateFavouriteStatus,
}) {
  const [isFavourite, setIsFavourite] = React.useState(false);
  const [showFavourite, setShowFavourite] = React.useState(isMobile);
  const hasFavourite = !isMobile && typeof updateFavouriteStatus === "function";

  React.useEffect(() => {
    if (influencer.isFavourite) {
      setIsFavourite(true);
    } else {
      setIsFavourite(false);
    }
  }, [influencer.isFavourite]);

  const onClick = React.useCallback(
    (evt) => {
      evt.stopPropagation();
      evt.preventDefault();

      updateFavouriteStatus?.(
        influencer.mutate("is_favourite", !isFavourite),
        (newStatus) => {
          setIsFavourite(newStatus);
        },
      );
    },
    [isFavourite, influencer, updateFavouriteStatus],
  );

  return (
    <div
      style={style}
      onMouseOver={hasFavourite ? () => setShowFavourite(true) : undefined}
      onMouseLeave={hasFavourite ? () => setShowFavourite(false) : undefined}
    >
      <PreloadLink
        className={clsx(
          "preload-link",
          position,
          isDark && "dark",
          isExpanded && "expanded",
          className,
        )}
        to={`/${influencer.route}${
          recommendedShops ? "?source=recommended" : ""
        }`}
        endpoints={
          influencer.endpoints && [
            influencer?.endpoints?.influencerByRoute,
            influencer?.endpoints?.influencerEvents,
          ]
        }
      >
        {influencer?.isLive && (
          <span className="shop-card__live-indicator">LIVE</span>
        )}

        {!influencer?.isLive && <UpcomingEvent influencer={influencer} />}

        {hasFavourite && (isFavourite || showFavourite) && (
          <LikeButton
            isMobile={isMobile}
            className={className}
            onClick={onClick}
            isFavourite={isFavourite}
          />
        )}

        <div className="shop-card__image-container">
          <img
            height={constants.imageHeightFor({ look, isMobile })}
            width={constants.imageWidthFor({ look, isMobile })}
            alt={influencer.name}
            className="shop-card__image"
            src={influencer.avatarUrl || influencer.avatar_url}
            loading="lazy"
          />

          <p className={clsx("shop-card__name", isDark && "dark")}>
            {influencer.name}
          </p>
        </div>

        {constants.isTall(look) && (
          <p className={clsx("shop-card__text", isDark && "dark")}>
            {textFor(influencer)}
          </p>
        )}
      </PreloadLink>
    </div>
  );
}

function textFor({ famousFor, famousCharacter }) {
  if (famousFor && famousCharacter) {
    return `${famousCharacter} / ${famousFor}`;
  }

  if (famousFor) {
    return famousFor;
  }

  if (famousCharacter) {
    return famousCharacter;
  }

  return "";
}

ShopCard.propTypes = {
  className: PropTypes.string,
  data: PropTypes.oneOfType([
    PropTypes.instanceOf(Influencer),
    PropTypes.object,
  ]),
  isDark: PropTypes.bool,
  isExpanded: PropTypes.bool,
  isMobile: PropTypes.bool,
  look: PropTypes.oneOf(["tall", "wide"]),
  position: PropTypes.oneOf(["absolute", "normal"]),
  style: PropTypes.object,
  updateFavouriteStatus: PropTypes.func,
  count: PropTypes.bool,
  recommendedShops: PropTypes.bool,
};
