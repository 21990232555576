import { Box, Button, Divider, Grid, Hidden } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import FacebookIcon from "../assets/facebook.svg";
import InstagramIcon from "../assets/instagram.svg";
import TiktokIcon from "../assets/tik_tok.svg";
import TwitchIcon from "../assets/twitchFooter.svg";
import TwitterIcon from "../assets/twitter.svg";
import YoutubeIcon from "../assets/youtube.svg";
import NewsletterEmailEntry from "../components/Home/NewsletterEmailEntry";
import { useMatchesSmDown } from "../hooks";

const SocialLink = styled("a")(({ theme }) => ({
  height: 32,
  width: 32,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  "&.dark": {
    boxShadow: `0 0 0 1px ${theme.palette.text.primary}`,
  },
}));

const socialIcons = [
  {
    key: "instagram",
    href: "https://instagram.com/streamily.live",
    img: InstagramIcon,
    alt: "Instagram",
  },
  {
    key: "twitter",
    href: "https://twitter.com/StreamilyLive",
    img: TwitterIcon,
    alt: "X",
    width: 16,
    height: 16,
    style: { background: "#111" },
  },
  {
    key: "youtube",
    href: "https://youtube.com/streamily",
    img: YoutubeIcon,
    alt: "YouTube",
  },
  {
    key: "facebook",
    href: "https://www.facebook.com/Streamily",
    img: FacebookIcon,
    alt: "Facebook",
  },
  {
    key: "tiktok",
    href: "https://www.tiktok.com/@streamilylive",
    img: TiktokIcon,
    alt: "TikTok",
  },
  {
    key: "twitch",
    href: "https://www.twitch.tv/streamilylive",
    img: TwitchIcon,
    alt: "Twitch",
  },
];

const pagesWithoutFooter = [
  "/dashboard",
  "/signing-assistant",
  "/talent-dash/get-paid",
  "/account-dashboard",
  "/pendingdashboard",
  "/quickship",
];

const shouldHide = ({ pathname }) =>
  pagesWithoutFooter.some((page) => pathname.toLowerCase().includes(page));

/* eslint-disable complexity */
export default function Footer({ isHomePage }) {
  const location = useLocation();
  const isMobile = useMatchesSmDown();

  if (shouldHide(location)) {
    return null;
  }

  const className = isHomePage ? "dark" : "light";

  return (
    <FooterContainer>
      <Hidden smDown>
        <Divider
          style={{
            margin: "2rem 0 2rem 0",
            background: isHomePage ? "#575D63" : "#dadada",
          }}
        />
      </Hidden>

      <Container className={className}>
        <Hidden mdUp>
          <NewsletterEmailEntry isHomePage={isHomePage} />
        </Hidden>

        <Grid container spacing={6}>
          <Grid item xs={6} sm={4} md={4} lg={3}>
            <Box display="grid" gridTemplateColumns="1fr 1fr" gridGap="16px">
              <div>
                <ColumnTitle className={className}>Support</ColumnTitle>

                <Box
                  mt={1}
                  mb={2}
                  gridGap={12}
                  display="flex"
                  flexDirection="column"
                >
                  <AnchorLink className={className} href="/faq">
                    FAQ
                  </AnchorLink>

                  <ReactLink className={className} to="/return-policy">
                    Return Policy
                  </ReactLink>

                  <ReactLink className={className} to="/Contact">
                    Contact Us
                  </ReactLink>
                </Box>
              </div>

              <div>
                <ColumnTitle className={className}>Company</ColumnTitle>

                <Box
                  mt={1}
                  mb={2}
                  gridGap={12}
                  display="flex"
                  flexDirection="column"
                >
                  <AnchorLink
                    className={className}
                    href="https://blog.streamily.com"
                  >
                    Blog
                  </AnchorLink>

                  <AnchorLink className={className} href="/sitemap.html">
                    Sitemap
                  </AnchorLink>

                  <AnchorLink
                    className={className}
                    href="https://www.indeed.com/cmp/Smashnet/jobs"
                  >
                    We Are Hiring
                  </AnchorLink>
                </Box>
              </div>
            </Box>
          </Grid>

          <Hidden smDown>
            <Grid item sm={12} md={5} lg={6}>
              <Box maxWidth="42rem" margin="0 auto">
                <NewsletterEmailEntry isHomePage={isHomePage} />

                <Box
                  mt={4}
                  display="flex"
                  gridGap={24}
                  justifyContent="space-evenly"
                >
                  {socialIcons.map((icon) => (
                    <SocialLink
                      className={className}
                      key={icon.key}
                      href={icon.href}
                      style={icon.style}
                    >
                      <img
                        width={icon.width ?? 32}
                        height={icon.height ?? 32}
                        src={icon.img}
                        alt={icon.alt}
                      />
                    </SocialLink>
                  ))}
                </Box>
              </Box>
            </Grid>
          </Hidden>

          <Grid item xs={12} sm={4} md={3} lg={3}>
            <Box
              ml={isMobile ? 0 : "auto"}
              maxWidth={isMobile ? undefined : "16rem"}
              display="flex"
              flexDirection="column"
              gridGap="16px"
              mb={isMobile ? 4 : 0}
            >
              <Button
                component={Link}
                variant="contained"
                color="secondary"
                to="/talent/signup"
              >
                Join as Talent
              </Button>

              <Button
                component="a"
                variant="contained"
                color="primary"
                href="https://landing.streamily.com/affiliate"
              >
                <strong>Become an Affiliate</strong>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Hidden mdUp>
        <Box mb={4} display="flex" gridGap={16} justifyContent="space-evenly">
          {socialIcons.map((icon) => (
            <SocialLink
              style={icon.style}
              className={className}
              key={icon.key}
              href={icon.href}
            >
              <img
                width={icon.width ?? 32}
                height={icon.height ?? 32}
                src={icon.img}
                alt={icon.alt}
              />
            </SocialLink>
          ))}
        </Box>
      </Hidden>

      <Divider
        style={{
          margin: "2rem 0 2rem 0",
          background: isHomePage ? "#575D63" : "#dadada",
        }}
      />

      <FooterDiv>
        <Copyright className={className}>
          Copyright © {new Date().getFullYear()} SmashNet
        </Copyright>

        <PolicyLink className={className} to="/terms-and-conditions">
          Terms and Conditions
        </PolicyLink>

        <PolicyLink className={className} to="/privacy-policy">
          Privacy Policy
        </PolicyLink>
      </FooterDiv>
    </FooterContainer>
  );
}

Footer.propTypes = {
  isHomePage: PropTypes.bool,
};

const StyledButton = styled("button")(({ theme }) => ({
  background: "none",
  font: "inherit",
  border: "none",
  width: "100%",
  color: theme.palette.text.primary,
  padding: 0,
  margin: 0,
  cursor: "pointer",
  "&.dark": {
    color: theme.palette.common.white,
    "&:focus": {
      border: "none",
    },
  },
}));

function ToggleButton({ isOpen, onClick, isHomePage, label, ...props }) {
  return (
    <StyledButton
      {...props}
      onClick={onClick}
      className={clsx(isHomePage && "dark")}
    >
      <Box
        display="flex"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
      >
        {label}

        {isOpen ? <RemoveIcon /> : <AddIcon />}
      </Box>
    </StyledButton>
  );
}

ToggleButton.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  isHomePage: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

const Container = styled("div")(({ theme }) => ({
  padding: `${theme.spacing(2)}px 0`,
  display: "flex",
  flexDirection: "column",
  maxWidth: theme.breakpoints.values.xl,
  margin: "0 auto",
  gap: theme.spacing(4),
}));

const FooterContainer = styled("div")(({ theme }) => ({
  padding: `${theme.spacing(2)}px var(--gutter) ${theme.spacing(
    4,
  )}px var(--gutter)`,
}));

const ColumnTitle = styled("h4")(({ theme }) => ({
  ...theme.typography.h6,
  fontSize: theme.typography.pxToRem(20),
  margin: 0,
  "&.dark": {
    color: "white",
  },
}));

const AnchorLink = styled("a")(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: "none",
  "&.dark": {
    color: "white",
  },
  "&:hover": {
    textDecoration: "underline",
  },
}));

const ReactLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: "none",
  "&.dark": {
    color: "white",
  },
  "&:hover": {
    textDecoration: "underline",
  },
}));

const FooterDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  gap: theme.spacing(0.5, 2),
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
    flexWrap: "wrap",
  },
}));

const PolicyLink = styled(Link)(({ theme }) => ({
  ...theme.typography.caption,
  textDecoration: "none",
  "&.dark": {
    color: theme.palette.common.white,
  },
}));

const Copyright = styled("span")(({ theme }) => ({
  ...theme.typography.caption,
  "&.dark": {
    color: theme.palette.common.white,
  },
  flex: 1,
  [theme.breakpoints.down("sm")]: {
    flex: "unset",
  },
}));
