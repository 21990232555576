import PropTypes from "prop-types";
import { Box, Chip } from "@material-ui/core";
import { D } from "@mobily/ts-belt";
import { useFilters } from "./utils";

export default function SelectedCategories({ filter, setFilter }) {
  const { filters } = useFilters();

  return (
    <Box display="flex" flexWrap="wrap" gridGap="8px">
      {filters.category &&
        Object.entries(filters.category).reduce(
          (result, [categoryId, category]) => {
            const found = filter.category[categoryId];

            if (!found) {
              return result;
            }

            result.push(
              <Chip
                key={categoryId}
                label={category.name}
                onDelete={() => {
                  setFilter(
                    D.merge(filter, {
                      category: D.deleteKey(filter.category, categoryId),
                    }),
                  );
                }}
              />,
            );

            return result;
          },
          [],
        )}
    </Box>
  );
}

SelectedCategories.propTypes = {
  filter: PropTypes.shape({
    category: PropTypes.object,
  }),
  setFilter: PropTypes.func.isRequired,
};
