import {
  Box,
  Button,
  CircularProgress,
  InputLabel,
  Link,
  Slider,
  TextField,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import { atom, useAtom } from "jotai";
import PropTypes from "prop-types";
import { F } from "@mobily/ts-belt";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import styled from "styled-components";
import ShopSalespersons from "../../../components/ShopSalespersons";
import ShopAgents from "../../../components/ShopAgents";
import { actionCreators, useStateValue } from "../../../globalState";
import { useGtag } from "../../../hooks";
import getCroppedImage from "../../../util/croppedImage";
import LoadingIndicator from "../../LoadingIndicator";
import UserInvite from "../../UserInvite";
import AffiliateLinks from "./AffiliateLinks";
import GroupShopSettings from "./GroupShopSettings";
import PlatformFeeBump from "./PlatformFeeBump";
import ServiceBump from "./ServiceBump";
import ShopSplit from "./ShopSplit";
import ToggleAttribute from "./ToggleAttribute";
import UserInfluencers from "./UserInfluencer/UserInfluencers";

const Cropper = React.lazy(() => import("react-easy-crop"));
const userInviteModalAtom = atom(false);
const userInfluencerAddStateAtom = atom(false);

// eslint-disable-next-line complexity
function InfluencerModal({ influencer, onClose, edittingSettings }) {
  const [{ user }, dispatch] = useStateValue();
  const history = useHistory();
  const gtag = useGtag();

  const localScrollRef = useRef(null);
  const ImageInputRef = useRef(null);
  const unmounted = useRef(false);
  const ErrorTextRef = useRef(null);
  const SubmitButtonRef = useRef(null);
  const [name, setName] = useState(influencer ? influencer.name : "");
  const [route, setRoute] = useState(influencer ? influencer.route : "");
  const [shopHashTag, setShopHashTag] = useState(
    influencer && influencer.product_tag_string
      ? influencer.product_tag_string
      : "",
  );

  const [unsaved, setUnsaved] = useState(false);
  const [isEditingServiceFeeBump, setIsEditingServiceFeeBump] = useState(null);

  const [imageSrc, setImageSrc] = useState(
    influencer ? influencer.avatar_url : null,
  );
  const [errorText, setErrorText] = useState("");
  const [nameError, setNameError] = useState(false);
  const [routeError, setRouteError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  // crop
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation] = useState(0);
  const [croppedImage, setCroppedImage] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  //user influencers
  const [showUserInfluencersOptions, setShowUserInfluencersOptions] =
    useState(false);

  const [, setUserInviteModal] = useAtom(userInviteModalAtom);
  const [, setUserInfluencerAddState] = useAtom(userInfluencerAddStateAtom);

  const openInviteModal = React.useCallback(() => {
    setUserInfluencerAddState(false);
    setUserInviteModal(true);
  }, [setUserInviteModal, setUserInfluencerAddState]);

  const onCropComplete = useCallback(async (_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const FileInputOnChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => setImageSrc(reader.result);
    }
  };

  const ChangeImageButtonOnClick = () => {
    setImageSrc(null);
    setCroppedImage(null);
    setZoom(1);
    requestAnimationFrame(() => {
      ImageInputRef.current.click();
    });
  };

  const InputOnInput = () => {
    setNameError(false);
    setRouteError(false);
    setErrorText("");
    setUnsaved(true);
  };

  const HandleEnter = async (e) => {
    if (e.keyCode !== 13) return;
    e.preventDefault();
    const inputs = document.getElementsByTagName("input");
    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i] === e.target) {
        try {
          inputs[i + 1].focus();
        } catch (error) {
          SubmitButtonRef.current.focus();
        }
      }
    }
  };

  const TriggerAlert = (alert) => {
    dispatch(actionCreators.createTriggerAlert(alert));
  };

  // eslint-disable-next-line complexity
  const Submit = async () => {
    if (isLoading) return;
    if (isEditingServiceFeeBump) {
      setErrorText(
        'Please save service fee bump before clicking "Save Shop". Changes are still unsaved.',
      );
      return;
    }
    setErrorText("");
    try {
      if (!influencer) {
        if (!imageSrc) {
          return setErrorText("Please Provide an Avatar Image");
        }
        if (!name) {
          setNameError(true);
        }
        if (!route) {
          setRouteError(true);
        }
        if (!name || !route) {
          return setErrorText("Please fill out all required information");
        }
      } else {
        if (!edittingSettings && !imageSrc) {
          return setErrorText("Please Provide an Avatar Image");
        }
      }
      if (edittingSettings) {
        if (!name) {
          setNameError(true);
        }
        if (!route) {
          setRouteError(true);
        }
        if (!name || !route) {
          return setErrorText("Cannot remove shop name or shop link");
        }
      }

      setIsLoading(true);
      const method = influencer ? "put" : "post";
      if (method === "post") {
        const createInfluencerResp = await axios[method](`/api/influencer`, {
          avatar: influencer ? undefined : croppedImage,
          name: influencer ? undefined : name.trim(),
          route: influencer ? undefined : route.trim(),
        });
        if (process.env.REACT_APP_ENV === "production") {
          gtag(
            "event",
            "influencer_created",
            createInfluencerResp.data.data.created_influencer,
          );
        }
      }
      let putResp;
      if (method === "put") {
        if (edittingSettings) {
          const product_tag_string =
            Boolean(user.admin) && Boolean(influencer.group_shop)
              ? !shopHashTag
                ? null
                : shopHashTag
              : undefined;

          putResp = await axios.put(
            `/api/influencer/${influencer.influencer_id}`,
            {
              name: !name ? undefined : name.trim(),
              route: !route ? undefined : route.trim(),
              product_tag_string,
            },
          );
        } else {
          const currentCroppedImage = await getCroppedImage(
            imageSrc,
            croppedAreaPixels,
            rotation,
          );
          setCroppedImage(currentCroppedImage);
          await axios.put(`/api/influencer/${influencer.influencer_id}`, {
            avatar: croppedImage,
          });
        }
      }

      if (unmounted.current) return;
      TriggerAlert({ message: "Saved" });

      if (method === "post") {
        onClose(() => history.push(`/Dashboard/s/${route.trim()}`), {
          shouldRefresh: true,
        });
      } else if (putResp && putResp.data && putResp.data.new_route) {
        onClose(() => history.push(`/Dashboard/s/${putResp.data.new_route}`), {
          shouldRefresh: false,
        });
      } else {
        onClose(F.identity, { shouldRefresh: true });
      }
    } catch (error) {
      if (unmounted.current) return;
      console.error(error);
      if (error.response && error.response.data) {
        setErrorText(error.response.data);
      } else {
        setErrorText("Something went wrong. Please try again.");
      }
      setIsLoading(false);
    }
  };

  const ShowCroppedImage = useCallback(async () => {
    try {
      const currentCroppedImage = await getCroppedImage(
        imageSrc,
        croppedAreaPixels,
        rotation,
      );
      if (unmounted.current) return;

      setCroppedImage(currentCroppedImage);
    } catch (e) {
      if (unmounted.current) return;
      console.error(e);
    }
  }, [imageSrc, croppedAreaPixels, rotation]);

  useEffect(() => {
    if (imageSrc !== null && croppedAreaPixels !== null) {
      ShowCroppedImage();
    }
  }, [imageSrc, croppedAreaPixels]);

  useEffect(() => {
    if (imageSrc !== null) {
      setImageSrc(null);
    }
    return () => {
      unmounted.current = true;
    };
  }, []);

  const modalTitle = influencer
    ? edittingSettings
      ? "Shop Settings"
      : "Edit Avatar"
    : "New Shop";
  const submitButtonText = influencer
    ? edittingSettings
      ? "Save Shop"
      : "Save Avatar"
    : "Create Shop";

  const handleEditServiceFeeBump = React.useCallback(
    (isEditing) => {
      setIsEditingServiceFeeBump(isEditing);
      if (!isEditing) setErrorText("");
    },
    [setIsEditingServiceFeeBump, setErrorText],
  );

  return (
    <BackgroundOverlay
      onMouseDown={() => onClose(F.identity, { shouldRefresh: false })}
    >
      <Container onMouseDown={(e) => e.stopPropagation()}>
        <TitleContainer>
          <Title>{modalTitle}</Title>
          <ExitButton
            onClick={() => onClose(F.identity, { shouldRefresh: false })}
          >
            X
          </ExitButton>
        </TitleContainer>
        <ScrollContainer ref={localScrollRef}>
          <InnerContainer>
            {!edittingSettings && (
              <React.Fragment>
                {imageSrc ? (
                  <TopHeader>
                    <CropContainer>
                      <React.Suspense fallback={<CircularProgress />}>
                        <Cropper
                          image={imageSrc}
                          crop={crop}
                          cropShape="round"
                          zoom={zoom}
                          aspect={4 / 4}
                          onCropChange={setCrop}
                          onCropComplete={onCropComplete}
                          onZoomChange={setZoom}
                        />
                      </React.Suspense>
                    </CropContainer>
                    <CropControlContainer>
                      <ChangeImageButton onClick={ChangeImageButtonOnClick}>
                        Change Image
                      </ChangeImageButton>
                      <InputLabel
                        htmlFor="new-influencer-avatar-zoom-slider"
                        style={{ alignSelf: "flex-start", marginTop: "20px" }}
                      >
                        Avatar Zoom
                      </InputLabel>
                      <Slider
                        value={zoom}
                        min={1}
                        max={3}
                        step={0.1}
                        aria-labelledby="Zoom"
                        id="new-influencer-avatar-zoom-slider"
                        onChange={(_, zoom) => setZoom(zoom)}
                      />
                    </CropControlContainer>
                  </TopHeader>
                ) : (
                  <AddImageOuterContainer>
                    <ImageInput
                      type="file"
                      ref={ImageInputRef}
                      onChange={FileInputOnChange}
                      onInput={InputOnInput}
                    />
                    <AddImageContainer
                      onClick={() => ImageInputRef.current.click()}
                    >
                      <PlusButton className="plusButton">+</PlusButton>
                      <AddImageText>Add Avatar Image</AddImageText>
                    </AddImageContainer>
                  </AddImageOuterContainer>
                )}
              </React.Fragment>
            )}
            <Inputs>
              {(!influencer || edittingSettings) && (
                <React.Fragment>
                  <InputLabel
                    htmlFor="new-influencer-name"
                    style={{ alignSelf: "flex-start", marginTop: "20px" }}
                  >
                    Shop Name *
                  </InputLabel>
                  <TextField
                    style={styles.Input}
                    enterindex="1"
                    error={nameError}
                    id="new-influencer-name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onInput={InputOnInput}
                    onKeyDown={HandleEnter}
                    InputProps={{ maxLength: "40" }}
                  />
                  <InputLabel
                    htmlFor="new-influencer-route"
                    style={{ alignSelf: "flex-start", marginTop: "20px" }}
                  >
                    Shop Link Username *
                  </InputLabel>
                  <TextField
                    style={styles.Input}
                    enterindex="2"
                    error={routeError}
                    id="new-influencer-route"
                    value={route}
                    onChange={(e) => setRoute(e.target.value)}
                    onInput={InputOnInput}
                    onKeyDown={HandleEnter}
                    InputProps={{ maxLength: "20" }}
                    helperText={`Shop link will be streamily.com/${
                      route ? route : "<username>"
                    }`}
                  />

                  <Box py={2}>
                    <Typography style={{ fontWeight: 700 }} variant="caption">
                      We’ve moved the Social Media section to{" "}
                      <Link
                        component={RouterLink}
                        to="/Dashboard/Account-Settings"
                      >
                        Account Settings
                      </Link>
                      , feel free to add them there!
                    </Typography>
                  </Box>
                </React.Fragment>
              )}
              {edittingSettings && (
                <React.Fragment>
                  {!!user.admin && (
                    <GroupShopSettings
                      influencerId={influencer.influencer_id}
                      isGroupShop={Boolean(influencer.group_shop)}
                      shopHashTag={shopHashTag}
                      setShopHashTag={setShopHashTag}
                      onInput={InputOnInput}
                      onKeyDown={HandleEnter}
                    />
                  )}
                  {!!user.admin && (
                    <ServiceBump
                      setIsEditingServiceFeeBump={handleEditServiceFeeBump}
                      influencer={influencer}
                    />
                  )}
                  {!!user.admin && (
                    <PlatformFeeBump
                      setIsEditing={handleEditServiceFeeBump}
                      influencer={influencer}
                    />
                  )}
                  {!!user.admin && (
                    <ShopSplit
                      influencerId={influencer.influencer_id}
                      userInviteModalAtom={userInviteModalAtom}
                    />
                  )}
                  {!!user.admin && (
                    <PayCutButton
                      className="user-influencer"
                      onClick={() =>
                        setShowUserInfluencersOptions((prevState) => !prevState)
                      }
                    >
                      {showUserInfluencersOptions ? "Hide" : "View"} Access List
                    </PayCutButton>
                  )}
                  {!!user.admin && !!showUserInfluencersOptions && (
                    <UserInfluencers
                      influencerId={influencer.influencer_id}
                      openInviteModal={openInviteModal}
                      parentScrollRef={localScrollRef}
                      setErrorText={setErrorText}
                      TriggerAlert={TriggerAlert}
                      userInfluencerAddStateAtom={userInfluencerAddStateAtom}
                      userInviteModalAtom={userInviteModalAtom}
                    />
                  )}
                  {!!user.admin && (
                    <Box
                      width="100%"
                      mt={2}
                      mb={1}
                      display="grid"
                      gridGap="8px"
                      gridTemplateColumns="1fr 1fr"
                    >
                      {!!user.admin && (
                        <UserInvite
                          influencerId={influencer.influencer_id}
                          stateAtom={userInviteModalAtom}
                        />
                      )}

                      {!!user.admin && !influencer.group_shop && (
                        <AffiliateLinks
                          influencerId={influencer.influencer_id}
                          influencerRoute={influencer.route}
                        />
                      )}
                    </Box>
                  )}
                  {!!user.admin && (
                    <Box
                      display="grid"
                      gridGap="8px"
                      gridTemplateColumns="1fr 1fr"
                      mb={2}
                      width="100%"
                    >
                      {!!user.admin && !influencer.group_shop && (
                        <ShopSalespersons
                          influencerId={influencer.influencer_id}
                        />
                      )}
                      {!!user.admin && !influencer.group_shop && (
                        <ShopAgents influencerId={influencer.influencer_id} />
                      )}
                    </Box>
                  )}
                  <ToggleAttribute
                    attributeKey="show_in_person_products"
                    attributeValue={Boolean(influencer.show_in_person_products)}
                    influencerId={influencer.influencer_id}
                  />
                  {!!user.admin && (
                    <ToggleAttribute
                      attributeKey="allow_video_message_addon"
                      attributeValue={Boolean(
                        influencer.allow_video_message_addon,
                      )}
                      influencerId={influencer.influencer_id}
                    />
                  )}
                  {!!user.admin && (
                    <ToggleAttribute
                      attributeKey="allow_video_recording_addon"
                      attributeValue={Boolean(
                        influencer.allow_video_recording_addon,
                      )}
                      influencerId={influencer.influencer_id}
                    />
                  )}
                  {!!user.admin && (
                    <ToggleAttribute
                      attributeKey="show_events"
                      attributeValue={Boolean(influencer.show_events)}
                      influencerId={influencer.influencer_id}
                    />
                  )}
                  {!!user.admin && (
                    <ToggleAttribute
                      attributeKey="on_homepage"
                      attributeValue={Boolean(influencer.on_homepage)}
                      influencerId={influencer.influencer_id}
                    />
                  )}
                  {!!user.admin && (
                    <ToggleAttribute
                      attributeKey="featured"
                      attributeValue={Boolean(influencer.featured)}
                      influencerId={influencer.influencer_id}
                    />
                  )}
                  {!!user.admin && (
                    <ToggleAttribute
                      displayName="Autopay"
                      attributeKey="is_stripe_payout"
                      attributeValue={Boolean(influencer.is_stripe_payout)}
                      influencerId={influencer.influencer_id}
                    />
                  )}
                  {!!user.admin && (
                    <ToggleAttribute
                      attributeKey="is_agent"
                      attributeValue={Boolean(influencer.is_agent)}
                      influencerId={influencer.influencer_id}
                    />
                  )}
                  {!!user.admin && (
                    <ToggleAttribute
                      attributeKey="is_talent_coordinator"
                      attributeValue={Boolean(influencer.is_talent_coordinator)}
                      influencerId={influencer.influencer_id}
                    />
                  )}
                  {!!user.admin && (
                    <ToggleAttribute
                      attributeKey="is_nmt"
                      attributeValue={Boolean(influencer.is_nmt)}
                      influencerId={influencer.influencer_id}
                    />
                  )}
                  {!!user.admin && (
                    <ToggleAttribute
                      attributeKey="is_manual_shop_status"
                      attributeValue={Boolean(influencer.is_manual_shop_status)}
                      influencerId={influencer.influencer_id}
                      displayName="Manual Shop Open/Close Status"
                    />
                  )}
                  {!!user.admin && Boolean(influencer.group_shop) && (
                    <ToggleAttribute
                      attributeKey="show_artists"
                      attributeValue={Boolean(influencer.show_artists)}
                      influencerId={influencer.influencer_id}
                      displayName="Show Artists"
                    />
                  )}
                  {!!user.admin && !influencer.group_shop && (
                    <ToggleAttribute
                      attributeKey="show_price_disclaimer"
                      attributeValue={Boolean(influencer.show_price_disclaimer)}
                      influencerId={influencer.influencer_id}
                      displayName="Show Price Disclaimer"
                    />
                  )}
                </React.Fragment>
              )}
            </Inputs>
          </InnerContainer>
        </ScrollContainer>
        <div style={{ height: "10px" }}></div>
        {errorText && <ErrorText ref={ErrorTextRef}>{errorText}</ErrorText>}
        {edittingSettings && unsaved && <UnsavedTag>UNSAVED</UnsavedTag>}
        <Button
          disabled={isLoading}
          onClick={Submit}
          color="secondary"
          variant="contained"
          style={{
            marginTop: "10px",
            height: "40px",
            flexShrink: 0,
            width: "95%",
            maxWidth: "300px",
            alignSelf: "center",
            marginBottom: "20px",
          }}
          ref={SubmitButtonRef}
        >
          {isLoading ? (
            <LoadingIndicator style={{ margin: "5px" }} />
          ) : (
            <React.Fragment>{submitButtonText}</React.Fragment>
          )}
        </Button>
      </Container>
    </BackgroundOverlay>
  );
}

InfluencerModal.propTypes = {
  influencer: PropTypes.shape({
    name: PropTypes.string,
    route: PropTypes.string,
    famous_for: PropTypes.string,
    famous_character: PropTypes.string,
    product_tag_string: PropTypes.string,
    on_homepage: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    influencer_id: PropTypes.number,
    featured: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    is_stripe_payout: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    show_artists: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    show_price_disclaimer: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.bool,
    ]),
    allow_video_recording_addon: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.bool,
    ]),
    allow_video_message_addon: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.bool,
    ]),
    avatar_url: PropTypes.string,
    group_shop: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    created: PropTypes.string,
    is_nmt: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    is_agent: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    is_talent_coordinator: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.bool,
    ]),
    is_manual_shop_status: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.bool,
    ]),
    show_in_person_products: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.bool,
    ]),
    show_events: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  }),
  onClose: PropTypes.func.isRequired,
  edittingSettings: PropTypes.bool,
};

export default InfluencerModal;

const styles = {
  Input: { flexShrink: 0 },
};

const BackgroundOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const Container = styled.div`
  position: relative;
  background: white;
  border-radius: 5px;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-width: 440px;
  max-width: 95%;
  box-sizing: border-box;
  max-height: 90%;
  overflow: hidden;
  flex-shrink: 0;
`;

const TitleContainer = styled.div`
  position: relative;
  width: 100%;
  background: rgb(100, 140, 190);
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.2);
`;

const Title = styled.div`
  font-size: 30px;
  line-height: 40px;
  font-weight: bold;
  text-align: center;
  color: white;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
`;

const InnerContainer = styled.div`
  padding: 10px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AddImageOuterContainer = styled.div`
  position: relative;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  border: 2px solid white;
  background: rgb(245, 245, 245);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 10px 0;
  width: 200px;
  height: 150px;
  flex-shrink: 0;
`;

const ImageInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
`;

const CropContainer = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  max-height: 400px;
  background: #333;
  align-items: center;
`;

const TopHeader = styled.div`
  position: relative;
  width: 100%;
`;

const AddImageContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  flex-shrink: 0;
  &:hover .plusButton {
    background: rgb(255, 255, 255);
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.05);
  }
  &:active .plusButton {
    background: rgb(240, 240, 240);
  }
`;

const PlusButton = styled.div`
  border: 1px solid rgb(200, 200, 200);
  border-radius: 50%;
  height: 80px;
  width: 80px;
  line-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  color: rgb(150, 150, 150);
  transition: background 0.25s;
  padding-bottom: 6px;
  box-sizing: border-box;
  flex-shrink: 0;
`;

const AddImageText = styled.div`
  color: rgb(100, 100, 100);
  padding: 10px;
  cursor: pointer;
  user-select: none;
  text-align: center;
`;
const ScrollContainer = styled.div`
  overflow-y: auto;
`;

const CropControlContainer = styled.div`
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
`;

const ChangeImageButton = styled.button`
  padding: 5px;
  cursor: pointer;
  border: 1px solid rgb(200, 200, 200);
  border-top: none;
  font-weight: bold;
  border-radius: 0 0 5px 5px;
  color: rgb(120, 120, 120);
  background: rgb(255, 255, 255);
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.05);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &:hover {
    background: rgb(245, 245, 245);
  }
  &:active {
    background: rgb(230, 230, 230);
  }
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  flex-shrink: 0;
`;

const ExitButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 10px 20px;
  font-size: 20px;
  color: rgb(255, 255, 255);
  font-weight: bold;
  user-select: none;
  cursor: pointer;
  line-height: 1;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  &:active {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const ErrorText = styled.div`
  text-align: center;
  color: rgb(255, 100, 100);
  font-size: 16px;
  margin: 10px;
`;

const UnsavedTag = styled.div`
  background: rgb(219, 43, 102);
  padding: 2px 8px;
  color: white;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  align-self: center;
  font-size: 12px;
  font-weight: bold;
  pointer-events: none;
  margin-top: 5px;
`;

const PayCutButton = styled.button`
  width: 53%;
  align-self: center;
  border: 1px solid rgb(200, 200, 200);
  border-radius: 20px;
  font-size: 15px;
  margin-bottom: 0.5rem;
  padding: 2px 20px;
  padding-bottom: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
  font-weight: normal;
  background-color: rgba(100, 140, 190);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &:hover {
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1),
      inset 0 0 20px 20px rgba(255, 255, 255, 0.2);
  }
  &:active {
    background-color: rgb(240, 240, 240);
  }
  &.new-split {
    width: 70%;
    margin-top: 1rem;
  }
  &.user-influencer {
    width: 54%;
  }
`;
