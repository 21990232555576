import { Avatar, Badge, Box, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import * as React from "react";
import { Influencer, InfluencerEvent } from "../../../domain";
import SearchResultsWrapper from "./SearchResultWrapper";

const getEvent = (item) => {
  if (!item.next_event_time) {
    return null;
  }

  return InfluencerEvent.from({
    date_type: item.date_type,
    event_end_date: item.event_end_date,
    event_start_date: item.event_start_date,
    event_type: item.event_type,
    next_event_time: item.next_event_time,
    time_zone_abbr: item.time_zone_abbr,
    time_zone: item.time_zone,
    time_zone_offset: item.time_zone_offset,
  });
};

function SearchResult({ item, onClick }) {
  const influencer = Influencer.from(item);
  const event = getEvent(item);

  return (
    <SearchResultsWrapper influencer={influencer} onClick={onClick}>
      <Badge
        variant="dot"
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        color={influencer.isLive ? "error" : undefined}
      >
        <Avatar alt="" src={influencer.avatarUrl} />
      </Badge>

      <Box ml={2} width="100%">
        <Box display="flex" justifyContent="space-between">
          <Typography variant="subtitle2">{influencer.name}</Typography>

          {event && (
            <Typography variant="caption" color="textSecondary">
              <time dateTime={event.value}>{event.searchFormattedValue}</time>
            </Typography>
          )}
        </Box>

        <Typography variant="caption" component="p" style={{ marginTop: -4 }}>
          {influencer.famousFor}
          {influencer.famousFor && influencer.famousCharacter ? " | " : ""}
          {influencer.famousCharacter}
        </Typography>
      </Box>
    </SearchResultsWrapper>
  );
}

SearchResult.propTypes = {
  item: PropTypes.shape({
    next_event_time: PropTypes.string,
    avatar_url: PropTypes.string,
  }),
  onClick: PropTypes.func,
};

export default React.memo(SearchResult);
