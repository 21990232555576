import useSWR from "swr";
import fetcher from "./fetcher";

const defaultShops = [];

export default function useRecentShops() {
  const params = new URLSearchParams({ type: "recent-shops" });
  const { data, isLoading, error, mutate } = useSWR(
    `/api/user/se?${params}`,
    fetcher,
  );

  return {
    recentShops: data?.recentShops ?? defaultShops,
    isLoading,
    isError: Boolean(error),
    error,
    mutate,
  };
}
