import { Link, Typography } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import ContentPage from "../ContentPage";

export default function Delivery() {
  return (
    <ContentPage title="Delivery">
      <Typography>
        Autographs shipped globally to eligible countries by USPS, UPS, or DHL.
        Merchandise shipped within 72 hours of receipt of autographed item from
        signer.
      </Typography>

      <ul>
        <li>
          <Typography>
            <Link component={RouterLink} to="/terms-and-conditions">
              Terms and Conditions
            </Link>
          </Typography>
        </li>
        <li>
          <Typography>
            <Link component={RouterLink} to="/privacy-policy">
              Privacy Policy
            </Link>
          </Typography>
        </li>
        <li>
          <Typography>
            <Link component={RouterLink} to="/return-policy">
              Return Policy
            </Link>
          </Typography>
        </li>
      </ul>
    </ContentPage>
  );
}
