import { Box, Button, Container, Typography } from "@material-ui/core";
import { B } from "@mobily/ts-belt";
import * as React from "react";
import * as components from "../components/BrowseShops";
import { useFilterParams } from "../components/BrowseShops/utils";
import { useMatchesSmDown } from "../hooks";

const styleForBox = (isSmDown) => {
  if (isSmDown) {
    return { display: "flex", flexDirection: "column", gridGap: "16px" };
  }

  return { display: "grid", gridTemplateColumns: "16rem 1fr", gridGap: "48px" };
};

export default function BrowseShops() {
  const matchesSmDown = useMatchesSmDown();
  const padding = matchesSmDown ? 2 : 4;
  const [showFilters, setShowFilters] = React.useState(!matchesSmDown);
  const { filter, setFilter } = useFilterParams();

  React.useEffect(() => {
    setShowFilters(!matchesSmDown);
  }, [matchesSmDown]);

  return (
    <Container maxWidth="lg">
      <Box p={padding}>
        <Typography variant="h4">Browse Shops</Typography>
      </Box>

      <Box
        alignItems="flex-start"
        display="flex"
        flexDirection={matchesSmDown ? "column" : "row"}
        gridGap={matchesSmDown ? "8px" : "48px"}
        justifyContent="space-between"
        px={padding}
      >
        <components.Search
          filter={filter}
          setFilter={setFilter}
          matchesSmDown={matchesSmDown}
        />

        <Box
          display="flex"
          flex="1"
          flexWrap="wrap"
          gridGap="4px"
          justifyContent="flex-start"
        >
          <components.SelectedCategories
            filter={filter}
            setFilter={setFilter}
          />
        </Box>

        <Box
          display="flex"
          gridGap="8px"
          alignItems="center"
          justifyContent="space-between"
          minWidth="12rem"
        >
          <components.Order
            filter={filter}
            setFilter={setFilter}
            matchesSmDown={matchesSmDown}
          />

          {matchesSmDown && (
            <Box minWidth="8rem">
              <Button
                fullWidth
                onClick={() => {
                  setShowFilters(B.not);
                }}
              >
                {showFilters ? "Hide Filters" : "Show Filters"}
              </Button>
            </Box>
          )}
        </Box>
      </Box>

      <Box p={padding} {...styleForBox(matchesSmDown)}>
        <components.Filters
          filter={filter}
          setFilter={setFilter}
          matchesSmDown={matchesSmDown}
          showFilters={showFilters}
        />

        <components.Shops
          filter={filter}
          setFilter={setFilter}
          matchesSmDown={matchesSmDown}
        />
      </Box>
    </Container>
  );
}
