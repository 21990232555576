import {
  Button,
  CircularProgress,
  DialogActions,
  Grid,
  Box,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import * as React from "react";
import { useMatchesXsDown } from "../../../../hooks";
import { StatusMessage } from "../../../../theme";
import ProductWishlistButton from "../../../WishlistProductButton";

const checkoutButtonStyle = { minWidth: "10rem" };

const ProductModalActions = React.memo(function ProductModalActions({
  product,
  errorMessage,
  isLoading,
}) {
  const matchesXs = useMatchesXsDown();

  return (
    <StyledDialogActions>
      <Grid container justifyContent="flex-end" spacing={1} alignItems="center">
        {Boolean(errorMessage) && (
          <Grid item>
            <StatusMessage type="error" message={errorMessage} />
          </Grid>
        )}

        <Grid item xs={matchesXs ? 12 : undefined}>
          <ProductWishlistButton size="large" product={product} />
        </Grid>

        <Grid item xs={matchesXs ? 12 : undefined}>
          <Button
            fullWidth={matchesXs}
            size="large"
            variant="contained"
            color="primary"
            style={checkoutButtonStyle}
            type="submit"
            className={clsx(matchesXs && "largerMobile")}
            disabled={isLoading || Boolean(errorMessage)}
          >
            <Box
              height={24}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {isLoading ? <CircularProgress size={22} /> : "Checkout"}
            </Box>
          </Button>
        </Grid>
      </Grid>
    </StyledDialogActions>
  );
});

ProductModalActions.propTypes = {
  product: PropTypes.object,
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool,
};

const StyledDialogActions = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
    },
  },
}))(DialogActions);

export default ProductModalActions;
