import { Box, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import * as React from "react";
import { Form } from "../../../theme";

export default function PlatformFeeBump({ influencer }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [platformFeeBump, setPlatformFeeBump] = React.useState(
    String(influencer.platform_fee_bump / 100),
  );

  const onBlur = async (evt) => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      evt.preventDefault();

      const response = await window.fetch(
        `/api/influencer/${influencer.influencer_id}`,
        {
          method: "PUT",
          headers: { "content-type": "application/json" },
          body: JSON.stringify({
            influencer_id: influencer.influencer_id,
            platform_fee_bump:
              Number(platformFeeBump.trim().replaceAll(/[^\d.]+/gi, "")) * 100,
          }),
        },
      );

      if (!response.ok) {
        throw new Error("Failed to save platform fee bump");
      }

      setIsLoading(false);
      setPlatformFeeBump(platformFeeBump);
    } catch (err) {
      console.error(err);
      setPlatformFeeBump(String(influencer.platform_fee_bump / 100));
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <Typography
        component="label"
        htmlFor="platformFeeBumpInput"
        variant="body2"
      >
        Platform Fee Bump
      </Typography>

      <Box display="flex" gridGap="8px">
        <Form.Input
          disabled={isLoading}
          onBlur={onBlur}
          InputProps={{ startAdornment: "$" }}
          size="small"
          name="platform_fee_bump"
          variant="outlined"
          inputProps={{ inputMode: "numeric", size: 3 }}
          value={platformFeeBump}
          onChange={(evt) => {
            setPlatformFeeBump(evt.target.value);
          }}
          id="platformFeeBumpInput"
        />
      </Box>
    </Box>
  );
}

PlatformFeeBump.propTypes = {
  influencer: PropTypes.shape({
    influencer_id: PropTypes.number.isRequired,
    platform_fee_bump: PropTypes.number.isRequired,
  }).isRequired,
  setIsEditing: PropTypes.func.isRequired,
};
