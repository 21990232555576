import * as React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Box } from "@material-ui/core";
import InfluencerModal from "../../components/Dashboard/InfluencerModal";
import ProductModal from "../../components/ShopBuilderProductModal";
import LoadingIndicator from "../../components/LoadingIndicator";
import { StreamilyPageContainer } from "../../theme";
import { Influencer, InfluencerEvent, SignType } from "../../domain";
import {
  ShopBuilderTitle,
  ShopBuilderHeader,
  ShopBuilderProducts,
} from "../../components/ShopBuilder";
import { useInfluencer, useInfluencerEvents } from "../../hooks";

const dupeProductIdContext = React.createContext(null);
export function useDupeProductIdContext() {
  return React.useContext(dupeProductIdContext);
}
const livestreamWarningContext = React.createContext(null);
export function useLivestreamWarningContext() {
  return React.useContext(livestreamWarningContext);
}

const ShopEditor = ({
  activeInfluencer,
  salesCountMap,
  pullDataAgain,
  influencerIsLoading,
}) => {
  const history = useHistory();
  const unmounted = React.useRef(false);
  const activeInfluencerIdRef = React.useRef(null);
  const { influencerEvents } = useInfluencerEvents(
    activeInfluencer?.influencerId,
  );

  const [editingAvatar, setEditingAvatar] = React.useState(false);
  const [editingSettings, setEditingSettings] = React.useState(false);
  const [addingProduct, setAddingProduct] = React.useState(false);
  const [dupeProductId, setDupeProductId] = React.useState(null);

  const livestreamWarning = React.useMemo(() => {
    if (!activeInfluencer) return false;
    if (!activeInfluencer.products) return false;

    const hasLivestream = activeInfluencer.products.some(
      (product) =>
        product.signedType === SignType.livestream && product.isActive,
    );

    return (
      hasLivestream &&
      (!influencerEvents?.length ||
        influencerEvents.every(InfluencerEvent.isNonLiveSigning))
    );
  }, [activeInfluencer, influencerEvents]);

  React.useEffect(() => {
    if (activeInfluencer.hasNoShopEditor) {
      history.push(`/Dashboard/s/${activeInfluencer.route}/Sales`);
      return;
    }
    document.title = `Streamily | Dashboard | ${activeInfluencer.name} | Shop Editor`;
    activeInfluencerIdRef.current = activeInfluencer.influencerId;
  }, [history, activeInfluencer]);

  React.useEffect(() => {
    pullDataAgain();

    return () => {
      unmounted.current = true;
    };
  }, [pullDataAgain]);

  const { refresh, isLoading } = useInfluencer({
    route: activeInfluencer.route,
    forShopBuilder: true,
  });

  const onClose = React.useCallback(
    (props) => {
      const { shouldRefreshInfluencer, shouldRefreshActiveInfluencer } =
        props || {};

      if (shouldRefreshInfluencer) {
        refresh();
      }

      if (shouldRefreshActiveInfluencer) {
        pullDataAgain();
      }
    },
    [pullDataAgain, refresh],
  );

  if (influencerIsLoading || isLoading) {
    return (
      <LoadingIndicatorContainer>
        <LoadingIndicator />
      </LoadingIndicatorContainer>
    );
  }

  if (activeInfluencer.hasNoShopEditor) return null;

  return (
    <livestreamWarningContext.Provider value={livestreamWarning}>
      <dupeProductIdContext.Provider
        value={{ dupeProductId, setDupeProductId }}
      >
        <StreamilyPageContainer spacing={1}>
          <ShopBuilderTitle route={activeInfluencer.route} />

          <ShopBuilderHeader
            route={activeInfluencer.route}
            setEditingAvatar={setEditingAvatar}
            setEditingSettings={setEditingSettings}
          />

          <Box pt={0.5}></Box>

          <ShopBuilderProducts
            route={activeInfluencer.route}
            setAddingProduct={setAddingProduct}
            salesCountMap={salesCountMap}
            onClose={onClose}
          />

          {activeInfluencer && addingProduct && (
            <ProductModal
              livestreamWarning={livestreamWarning}
              onClose={(props) => {
                setAddingProduct(false);
                onClose(props);
              }}
            />
          )}

          {editingAvatar && (
            <InfluencerModal
              influencer={activeInfluencer.raw()}
              onClose={(done, opts) => {
                done();
                setEditingAvatar(false);
                onClose({
                  shouldRefreshInfluencer: opts.shouldRefresh,
                  shouldRefreshActiveInfluencer: opts.shouldRefresh,
                });
              }}
            />
          )}

          {editingSettings && (
            <InfluencerModal
              influencer={activeInfluencer.raw()}
              edittingSettings={true}
              onClose={(done, opts) => {
                done();
                setEditingSettings(false);
                onClose({
                  shouldRefreshInfluencer: opts.shouldRefresh,
                  shouldRefreshActiveInfluencer: opts.shouldRefresh,
                });
              }}
            />
          )}
        </StreamilyPageContainer>
      </dupeProductIdContext.Provider>
    </livestreamWarningContext.Provider>
  );
};

ShopEditor.propTypes = {
  activeInfluencer: PropTypes.instanceOf(Influencer),
  salesCountMap: PropTypes.shape({}),
  pullDataAgain: PropTypes.func,
  influencerIsLoading: PropTypes.bool,
};

export default ShopEditor;

const LoadingIndicatorContainer = styled.div`
  width: 50px;
  height: 50px;
  position: relative;
  margin-top: 50px;
`;
