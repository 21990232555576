import { Box, Link, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { actionCreators, useStateValue } from "../../globalState";
import { useEmailModal } from "../../services/EmailModal";
import { DialogTitle, Form, Hint, ProgressButton } from "../../theme";
import { StyledDialog } from "./styles";
import { handleRefreshToken, handleTwoFactorAuth } from "./utils";

const AuthModal = React.memo(function AuthModal({ isLoggingIn, onClose }) {
  const emailModal = useEmailModal();
  const [, dispatch] = useStateValue();
  const unmounted = useRef(false);
  const onCloseProps = useRef();

  const [isOpen, setIsOpen] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLogin, setIsLogin] = useState(!!isLoggingIn);
  const [errorText, setErrorText] = useState("");
  const [hintText, setHintText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    emailModal?.block();
    return () => emailModal?.unblock();
  }, [emailModal]);

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  const handleAuthResponse = async (response) => {
    const { status } = response;

    if (status === 202) {
      setHintText("Please check your email for the verification link.");
      const json = await response.json();
      const result = await handleTwoFactorAuth(json.publicToken);

      if (!result?.ut) {
        throw new Error("Something went wrong. Please try again.");
      }

      const refreshedUser = await handleRefreshToken(result);

      if (!refreshedUser) {
        throw new Error("Something went wrong. Please try again.");
      }

      setHintText("");
      return refreshedUser;
    }

    if (status === 200) {
      const json = await response.json();
      return json.data;
    }

    throw new Error("Something went wrong. Please try again.");
  };

  const closeModal = () => onClose?.(onCloseProps.current);

  const resetModal = () => {
    setEmail("");
    setPassword("");
    setErrorText("");
  };

  const submitAuth = async (evt) => {
    evt.stopPropagation();
    evt.preventDefault();

    if (isLoading) {
      return;
    }

    if (!email || !password) {
      return setErrorText("Please enter email and password");
    }

    setIsLoading(true);

    try {
      const url = isLogin ? `/api/auth/login` : `/api/auth/signup`;
      const response = await window.fetch(url, {
        headers: { "content-type": "application/json" },
        method: "POST",
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        throw new Error("Failed to authenticate");
      }

      if (unmounted.current) return;

      const user = await handleAuthResponse(response);
      dispatch(actionCreators.createSetUser(user));
      setIsLoading(false);
      setIsOpen(false);
      onCloseProps.current = user;
    } catch (err) {
      if (unmounted.current) return;

      console.error(err);

      setPassword("");
      setIsLoading(false);

      if (err?.response?.data) {
        setErrorText(err.response.data);
      } else {
        setErrorText("Something went wrong. Please try again.");
      }
    }
  };

  useEffect(() => {
    resetModal();
  }, [isLogin]);

  const authMethodText = isLogin ? "Log In" : "Sign Up";

  return (
    <StyledDialog
      onClose={() => !isLoading && setIsOpen(false)}
      open={isOpen}
      TransitionProps={{ onExited: closeModal }}
    >
      <DialogTitle onClose={() => !isLoading && setIsOpen(false)}>
        <Typography component="span" variant="h5">
          {authMethodText}
        </Typography>
      </DialogTitle>

      <Box
        component="form"
        display="flex"
        flexDirection="column"
        gridGap="8px"
        onSubmit={submitAuth}
        mb={1}
        pl={3}
        pr={3}
      >
        <Form.Input
          autoFocus
          maxLength={80}
          onChange={(e) => setEmail(e.target.value)}
          onInput={() => setErrorText("")}
          placeholder="Email"
          required
          value={email}
          disabled={isLoading}
        />

        <Form.Input
          maxLength={40}
          onChange={(e) => setPassword(e.target.value)}
          onInput={() => setErrorText("")}
          placeholder="Password"
          required
          type="password"
          value={password}
          disabled={isLoading}
        />

        {errorText && (
          <Typography variant="body2" color="error">
            {errorText}
          </Typography>
        )}

        {hintText && <Hint>{hintText}</Hint>}

        <ProgressButton
          color="primary"
          disabled={isLoading}
          isLoading={isLoading}
          size="large"
          type="submit"
          variant="contained"
        >
          {authMethodText}
        </ProgressButton>
      </Box>

      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        pb={3}
        pl={3}
        pr={3}
        pt={1}
      >
        <Typography variant="caption" color="textSecondary" align="center">
          By {isLogin ? "logging in" : "signing up"} you agree to our{" "}
          <Link href="/Policy" target="_blank">
            policies
          </Link>
          .
        </Typography>

        <Box display="flex" gridGap="16px">
          <Typography variant="caption">
            <Link href="/ResetPassword"> I Forgot My Password</Link>
          </Typography>

          {isLogin ? (
            <Typography variant="caption" color="textSecondary" align="center">
              <span>Don&apos;t have an account? </span>

              <Link
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setIsLogin(false);
                }}
              >
                Signup
              </Link>
            </Typography>
          ) : (
            <Typography variant="caption" color="textSecondary" align="center">
              <span>Already have an account? </span>

              <Link
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setIsLogin(true);
                }}
              >
                Login
              </Link>
            </Typography>
          )}
        </Box>
      </Box>
    </StyledDialog>
  );
});

AuthModal.propTypes = {
  isLoggingIn: PropTypes.bool,
  onClose: PropTypes.func,
};

export default AuthModal;
