import addWordBreakHints from "./addWordBreakHints";
import apiCall from "./apiCall";
import * as autographPurchase from "./autographPurchase";
import {
  annoyingFireworks,
  realisticConfetti,
  simpleConfetti,
} from "./confetti";
import copyToClipboard from "./copyToClipboard";
import deleteCookie from "./deleteCookie";
import downloadFile, { downloadCSVFromText } from "./downloadFile";
import * as featureFlags from "./featureFlags";
import currencyFormatter from "./formatCurrency";
import formatDate, {
  dateFormatter,
  dateTimeFormatter,
  dateTimeFormatterUS,
  matchesDateTime,
} from "./formatDate";
import { formatDateRangeReverseTZ, formatDateRangeTZ } from "./formatDateRange";
import numberFormatter from "./formatNumber";
import { formatPhoneNumber } from "./formatPhoneNumber";
import {
  formatDateMomentYear,
  formatDateReverseTZ,
  formatDateStrTimeReverseTZ,
  formatDateTimeStr,
  formatDateToDateTimeReverseTZ,
  formatDateToDateTimeUTCStr,
  formatDateTZ,
  formatDateYearReverseStr,
  formatDateYearStr,
  formatDateYearUTCStr,
} from "./formatSingleDate";
import formatWith, {
  formatWithBool,
  formatWithCurrency,
  formatWithDate,
  formatWithLast4,
  formatWithTrim,
} from "./formatWith";
import {
  add,
  and,
  binary,
  coalesce,
  compose,
  createSorter,
  curry,
  debounce,
  deepTrim,
  demethodize,
  diffLengths,
  diffObject,
  entries,
  every,
  filter,
  firstTruthy,
  head,
  identity,
  ifEmpty,
  increment,
  isEmpty,
  last,
  map,
  mapObject,
  mapObjectAll,
  noop,
  not,
  omit,
  or,
  pick,
  pipe,
  reduce,
  reduceIfNotIn,
  renameKeys,
  some,
  sum,
  toBoolean,
  urnary,
  values,
  zipMap,
} from "./fp";
import generateId from "./generateId";
import getCookie from "./getCookie";
import { getParameter } from "./getParameter";
import inputValidation from "./inputValidation";
import invariant, { assertNotEmpty } from "./invariant";
import localStorageUtils, {
  getWithExpiry,
  setWithExpiry,
} from "./localStorage";
import logger from "./logger";
import {
  hasPurchasedCharacterName,
  hasPurchasedQuote,
  isPeterWeller,
  isPeterWellerOrder,
} from "./peterWeller";
import {
  capitalizeWord,
  joinEnglish,
  joinEnglishPick,
  joinEnglishReducer,
  limitLength,
  titleCase,
  validateEmail,
} from "./text";
import {
  isValidValue,
  jsDateStringToSQLDateString,
  looksLikeJSDateString,
  looksLikeURL,
  toSQLDateString,
} from "./utils";
import sendSessionEvent from "./sessionEvent";

const isProduction = process.env.REACT_APP_ENV === "production";
const isDev = process.env.REACT_APP_ENV === "dev";
const isStaging = process.env.REACT_APP_ENV === "staging";

export {
  add,
  addWordBreakHints,
  and,
  annoyingFireworks,
  apiCall,
  assertNotEmpty,
  autographPurchase,
  binary,
  capitalizeWord,
  coalesce,
  compose,
  copyToClipboard,
  createSorter,
  currencyFormatter,
  curry,
  dateFormatter,
  dateTimeFormatter,
  dateTimeFormatterUS,
  debounce,
  deepTrim,
  deleteCookie,
  demethodize,
  diffLengths,
  diffObject,
  downloadCSVFromText,
  downloadFile,
  entries,
  every,
  featureFlags,
  filter,
  firstTruthy,
  formatDate,
  formatDateMomentYear,
  formatDateRangeReverseTZ,
  formatDateRangeTZ,
  formatDateReverseTZ,
  formatDateStrTimeReverseTZ,
  formatDateTimeStr,
  formatDateToDateTimeReverseTZ,
  formatDateToDateTimeUTCStr,
  formatDateTZ,
  formatDateYearReverseStr,
  formatDateYearStr,
  formatDateYearUTCStr,
  formatPhoneNumber,
  formatWith,
  formatWithBool,
  formatWithCurrency,
  formatWithDate,
  formatWithLast4,
  formatWithTrim,
  generateId,
  getCookie,
  getParameter,
  getWithExpiry,
  hasPurchasedCharacterName,
  hasPurchasedQuote,
  head,
  identity,
  ifEmpty,
  increment,
  inputValidation,
  invariant,
  isDev,
  isEmpty,
  isPeterWeller,
  isPeterWellerOrder,
  isProduction,
  isStaging,
  isValidValue,
  joinEnglish,
  joinEnglishPick,
  joinEnglishReducer,
  jsDateStringToSQLDateString,
  last,
  limitLength,
  localStorageUtils,
  logger,
  looksLikeJSDateString,
  looksLikeURL,
  map,
  mapObject,
  mapObjectAll,
  matchesDateTime,
  noop,
  not,
  numberFormatter,
  omit,
  or,
  pick,
  pipe,
  realisticConfetti,
  reduce,
  reduceIfNotIn,
  renameKeys,
  sendSessionEvent,
  setWithExpiry,
  simpleConfetti,
  some,
  sum,
  titleCase,
  toBoolean,
  toSQLDateString,
  urnary,
  validateEmail,
  values,
  zipMap,
};
