import * as React from "react";
import useSWR, { mutate } from "swr";
import fetcher from "./fetcher";

const baseEndpoint = "/api/influencer/search";

function createQuery(query) {
  if (!query) {
    return "";
  }

  const params = new URLSearchParams();
  params.set("q", query);
  return params;
}

export default function useShopsSearch(query) {
  const params = createQuery(query);
  const { data, error, mutate } = useSWR(
    params ? `${baseEndpoint}?${params}` : null,
    fetcher,
    { keepPreviousData: true },
  );

  const shops = React.useMemo(() => {
    if (!data) {
      return [];
    }

    const { shops } = data;

    if (!shops || !Array.isArray(shops)) {
      return [];
    }

    return shops;
  }, [data]);

  return {
    shops,
    isLoading: !data && !error,
    isError: Boolean(error),
    error,
    refresh: mutate,
  };
}

export function prefetchInfluencers() {
  mutate(baseEndpoint, fetcher(baseEndpoint));
}
